import { Box, Divider, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CancelIcon from "@mui/icons-material/Cancel";
import { EventStatus, ISession } from "../../Interfaces";
import { computeSessionTimeDisplayWithTimeZone } from "../ListSessions/SessionText";

export type SessionUpdatesProps = {
  notifications?: ISession[];
};

export const SessionUpdates = ({ notifications }: SessionUpdatesProps) => {
  return (
    <Box padding="0em 1em" marginTop="1em" width="100%">
      <Typography variant="h6">Session Updates</Typography>
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        alignItems="flex-start"
        flexWrap="wrap"
      >
        {notifications && notifications?.length > 0 && (
          <>
            {notifications.map((notification, index) => (
              <div
                key={index}
                style={{
                  marginRight: "1em",
                  marginBottom: "1em",
                }}
              >
                <Typography variant="body1">
                  <Box display="flex" alignItems="center">
                    {notification?.Status === EventStatus.Delayed ? (
                      <>
                        <AccessTimeIcon
                          color="primary"
                          sx={{ marginRight: { xs: 1, md: 1 } }}
                        />
                        <span>
                          {notification?.Name} -
                          {notification?.ParentEvent?.Name} is delayed to{" "}
                          {computeSessionTimeDisplayWithTimeZone(
                            notification,
                            notification?.ParentEvent?.Site
                              ?.TimeZoneOffset as string,
                          )}
                          .
                        </span>
                      </>
                    ) : (
                      <>
                        <CancelIcon
                          color="error"
                          sx={{ marginRight: { xs: 1, md: 1 } }}
                        />
                        <span>
                          {notification?.Name} -
                          {notification?.ParentEvent?.Name} is Canceled.
                        </span>
                      </>
                    )}
                  </Box>
                </Typography>
              </div>
            ))}
          </>
        )}
      </Box>
      <Divider />
    </Box>
  );
};
