import { IEvent } from "../../Interfaces";
import { ISponsor } from "../../Interfaces/ISponsor";
import { StyledGrid, StyledLogo } from "./EventListStyles";

interface EventSponsorsProps {
  selectedEvent: IEvent;
  sponsors: ISponsor[] | undefined;
}

export const EventSponsors = ({
  selectedEvent,
  sponsors,
}: EventSponsorsProps) => {
  return (
    <StyledGrid item xs={4} alignItems="center" justifyContent="center">
      {selectedEvent?.SponsorIds?.split(",")?.map((sponsorId: string) => {
        const sponsor = sponsors?.find((s) => s.SponsorId === sponsorId);

        return (
          <a
            key={sponsorId}
            href={sponsor?.URL}
            target="_blank"
            rel="noreferrer"
          >
            <StyledLogo
              style={{
                height: "100%",
                width: "100%",
              }}
              className="sponsor"
              src={sponsor?.LogoBase64Encoded}
              alt={sponsor?.Name}
            />
          </a>
        );
      })}
    </StyledGrid>
  );
};
