import { BaseEntity } from "./BaseEntity";

export interface ISeries extends BaseEntity {
  SeriesId: string;
  Name: string;
  SortOrder: number;
  SeriesCode: string;
}

export enum Imsa_WecSeries {
  Wec = "F595D16A-D16C-419F-9634-0BDC1BC19208",
  Imsa = "21236978-2276-49A0-A9F3-DA1C27322BC9",
}
