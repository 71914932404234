import { useSessionTypes } from "../../Services/LookupService";
import { ValueSelect } from "./ValueSelect";

export type SessionTypeSelectProps = {
  selectedSessionType: string | null;
  onSessionTypeChange: (value: string) => void;
  onFocus?: () => void;
  showLabel?: boolean;
  defaultText?: string;
};

export const SessionTypeSelect = ({
  selectedSessionType,
  onSessionTypeChange,
  onFocus = undefined,
  showLabel = false,
  defaultText = "",
}: SessionTypeSelectProps) => {
  const { data: sessionTypes } = useSessionTypes();

  return (
    <>
      {sessionTypes && (
        <ValueSelect
          labelText="Session Type"
          showLabel={showLabel}
          defaultText={defaultText}
          onValueChange={onSessionTypeChange}
          onFocus={onFocus}
          data={sessionTypes}
          namePropName="Name"
          valuePropName="Name"
          selectedValue={selectedSessionType ? selectedSessionType : ""}
        />
      )}
    </>
  );
};
