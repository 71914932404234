import { IconButton, SxProps, Theme, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export type EditIconButtonProps = {
  entityId?: string;
  onEdit: (event: React.MouseEvent, entityId?: string) => void;
  sx?: SxProps<Theme>;
};

export const EditIconButton = ({
  onEdit,
  entityId,
  sx,
}: EditIconButtonProps) => {
  return (
    <Tooltip title="Edit">
      <IconButton
        aria-label="Edit"
        sx={sx}
        onClick={(event) => onEdit(event, entityId)}
        color="primary"
      >
        <EditIcon tabIndex={0} fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
