import { IEventSession, SessionStatus, statusColors } from "../../Interfaces";
import { StyledStatusDot } from "./SessionListStyles";

export const StatusDot = (session: IEventSession) => {
  return (
    <>
      {session.Status !== "Ok" ? (
        <StyledStatusDot
          style={{
            backgroundColor: statusColors[session.Status as SessionStatus],
          }}
          title={session.Status}
        />
      ) : (
        ""
      )}
    </>
  );
};
