import { SetStateAction, useMemo, useState } from "react";
import moment from "moment-timezone";
import { Typography } from "@mui/material";
import {
  LocalizationProvider,
  DateTimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export type DateTimePickerContainerProps = {
  label: string;
  disablePast: boolean;
  dateTimeToDisplay: any;
  onDateTimeChange: any;
  displayClockRenderer: boolean;
  valueTimeZone: string;
  disabled?: boolean;
};

export const DateTimePickerContainer = ({
  label,
  disablePast,
  dateTimeToDisplay,
  onDateTimeChange,
  displayClockRenderer,
  disabled = false,
  valueTimeZone,
}: DateTimePickerContainerProps) => {
  const [error, setError] = useState<string | null>(null);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const errorMessage = useMemo(() => {
    switch (error) {
      case "invalidDate": {
        return "Your date is not valid.";
      }
      case "disablePast": {
        return "The date/time you have selected is in the past.";
      }
      default: {
        return "";
      }
    }
  }, [error]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      <Typography variant="caption">{label}</Typography>
      <div style={{ marginTop: 0 }}>
        <DateTimePicker
          timezone={valueTimeZone}
          viewRenderers={{
            hours: displayClockRenderer ? renderTimeViewClock : null,
            minutes: displayClockRenderer ? renderTimeViewClock : null,
          }}
          disablePast={disablePast}
          value={
            dateTimeToDisplay.isValid() && valueTimeZone
              ? moment.tz(dateTimeToDisplay, valueTimeZone)
              : null
          }
          onChange={(newValue: SetStateAction<moment.Moment | null>) => {
            if (newValue) {
              onDateTimeChange(newValue);
            }
          }}
          disabled={disabled}
          sx={{
            "& > div": {
              height: "1.875rem",
              overflow: "hidden",
            },
          }}
          onError={(newError) => setError(newError)}
          slotProps={{
            textField: {
              helperText: error
                ? errorMessage
                : dateTimeToDisplay.isValid()
                  ? `Local: ${dateTimeToDisplay
                      .tz(userTimeZone)
                      .format("ddd, MMM D h:mma z")}`
                  : "",
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
};
