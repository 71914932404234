import {
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  Skeleton,
  SelectChangeEvent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRaceTeams } from "../../Services/LookupService";
import { StyledSelect } from "../ListEvents/EventListStyles";
import { MenuProps, getStyles } from "./MultiSelectStyles";
import React, { useEffect } from "react";

export type RaceTeamSelectProps = {
  seriesId: string;
  eventRaceTeamIds: string[];
  onRaceTeamChange: (raceTeamIds: string[]) => void;
  onFocus?: () => void;
};

export const RaceTeamSelect = ({
  seriesId,
  eventRaceTeamIds,
  onRaceTeamChange,
  onFocus,
}: RaceTeamSelectProps) => {
  const { data: raceTeams } = useRaceTeams(seriesId);
  const theme = useTheme();
  const [raceTeamNames, setRaceTeamNames] = React.useState<string[]>();

  useEffect(() => {
    if (!raceTeams) return;
    const selectedRaceTeams = raceTeams
      .filter((raceTeam) =>
        eventRaceTeamIds?.some(
          (eventRaceTeamId) => eventRaceTeamId === raceTeam.RaceTeamId,
        ),
      )
      .map((rt) => rt.DisplayName);
    setRaceTeamNames(selectedRaceTeams);
  }, [eventRaceTeamIds, raceTeams]);

  const handleChange = (event: SelectChangeEvent<typeof raceTeamNames>) => {
    const {
      target: { value },
    } = event;

    const teamNames =
      typeof value === "string" ? value.split(",") : value ?? [];
    setRaceTeamNames(
      // On autofill we get a stringified value.
      teamNames,
    );

    const raceTeamIds: string[] = [];
    teamNames.forEach((raceTeam) => {
      const selectedRt = raceTeams?.find((rt) => rt.DisplayName === raceTeam);
      if (selectedRt) {
        raceTeamIds.push(selectedRt.RaceTeamId);
      }
    });
    onRaceTeamChange(raceTeamIds);
  };

  return (
    <>
      {!raceTeams && (
        <>
          <label>Organizations</label>
          <Skeleton
            variant="rounded"
            aria-label="Race Teams not available until Series selection"
            placeholder="Race Teams"
          />
        </>
      )}
      {raceTeams && raceTeamNames && (
        <StyledSelect>
          <InputLabel id="raceteams-label">Organizations</InputLabel>
          <Select
            labelId="raceteams-label"
            id="raceteams-multiple-chip"
            multiple
            fullWidth
            notched={false}
            value={raceTeamNames}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected?.map((value) => <Chip key={value} label={value} />)}
              </Box>
            )}
            MenuProps={MenuProps}
            onFocus={onFocus}
          >
            {raceTeams.map((raceTeam) => (
              <MenuItem
                key={raceTeam.RaceTeamId}
                value={raceTeam.DisplayName}
                style={getStyles(raceTeam.DisplayName, raceTeamNames, theme)}
              >
                {raceTeam.DisplayName}
              </MenuItem>
            ))}
          </Select>
        </StyledSelect>
      )}
    </>
  );
};
