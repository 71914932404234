import { ValueSelect } from "./ValueSelect";
import { Skeleton } from "@mui/material";
import { useTimingScorings } from "../../Services/LookupService";

export type TimingAndScoringSelectProps = {
  selectedSeries: string;
  selectedTnS: string | null;
  onTnSChange: (value: string) => void;
  onFocus?: () => void;
  showLabel?: boolean;
  defaultText?: string;
};

export const TimingAndScoringSelect = ({
  selectedSeries,
  selectedTnS,
  onTnSChange,
  onFocus = undefined,
  showLabel = false,
  defaultText = "",
}: TimingAndScoringSelectProps) => {
  const { data: timingScoring } = useTimingScorings(selectedSeries);

  return (
    <>
      {!selectedSeries && (
        <>
          <label>Timing and Scoring</label>
          <Skeleton
            variant="rounded"
            aria-label="Timing and Scoring not available until Series selection"
            placeholder="Timing and Scoring"
          />
        </>
      )}
      {selectedSeries && (
        <ValueSelect
          labelText="Timing and Scoring"
          showLabel={showLabel}
          defaultText={defaultText}
          onValueChange={onTnSChange}
          onFocus={onFocus}
          data={timingScoring}
          namePropName="TimingScoringName"
          valuePropName="TimingScoringId"
          selectedValue={selectedTnS}
        />
      )}
    </>
  );
};
