import styled from "styled-components";
import { Box, Grid, FormControl } from "@mui/material";

export const StyledEventFilters = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

export const StyledDatePicker = styled(Box)`
  flex: 1;

  .MuiTextField-root > .MuiInputBase-root {
    border-radius: 0;
    height: 3rem;
  }
`;

export const StyledAutocompleteWrapper = styled.div`
  flex: 1;

  @media (max-width: 767px) {
    width: 100%;
  }

  .MuiAutocomplete-root {
    .MuiFormControl-root {
      padding: 0rem;
      .MuiInput-underline::after,
      .MuiInput-underline::before,
      .MuiAutocomplete-inputRoot {
        border: none;
      }

      .MuiInputBase-root {
        padding: 0.188rem 0rem !important;
        font-size: 0.875rem;
        border-radius: 0rem;

        input.MuiInputBase-input {
          @media (max-width: 768px) {
            max-width: 80%;
          }
        }

        input::placeholder {
        }
      }
      .MuiInputLabel-root {
        top: -0.438rem;
      }
    }
  }
`;

export const StyledSelect = styled(FormControl)`
  .MuiInputBase-root {
    border-radius: 0rem;
  }

  .MuiInputLabel-formControl {
    top: -0.25rem;
  }

  .MuiSelect-multiple {
    padding: 0.5rem 0.875rem;
  }
`;

export const StyledGrid = styled(Grid)`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(4, minmax(1px, 100%));
  padding-left: 0;
  list-style: none;
`;

export const StyledLogo = styled.img`
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 4rem;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const StyledSelectLogo = styled.img`
  max-width: 4.5rem;
  max-height: 3.5rem;
  vertical-align: middle;
  margin: 0rem 0.75rem 0rem 0.313rem;
`;

export const StyledSelectMobileLogo = styled.img`
  max-width: 3.5rem;
  max-height: 3.5rem;
  vertical-align: middle;
  margin: 0rem 0.5rem 0rem 0.188rem;
`;
