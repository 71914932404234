import { useState, useEffect } from "react";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import moment, { Moment } from "moment-timezone";
import { Backdrop, CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import HeaderAppBar from "../HeaderAppBar/HeaderAppBar";
import { MainContent } from "../MainContent/MainContent";
import { Filters } from "../Filters/Filters";
import { useSeries } from "../../Services/LookupService";
import { FilterPreferences } from "../../Interfaces/FilterPreferences";
import { ISeries } from "../../Interfaces/ISeries";
import { getInitialMode } from "../../utils";
import { AppInsightsService } from "../../Services/AppInsightsService";
import { LoggerService } from "../../Services/LoggerService";
import { Footer } from "../Shared/Footer";
import { darkTheme, lightTheme } from "./Theme";
import { SeriesName } from "../../Interfaces/SeriesName";

export default function App() {
  const [darkMode, setDarkMode] = useState(getInitialMode());
  const [appInsights, setAppInsights] = useState<any>();
  const [width, setWidth] = useState(window.innerWidth);
  const mobileBreakPoint = 900;
  const tabletBreakPoint = 1080;

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [filterValues, setFilterValues] = useState<
    FilterPreferences | undefined
  >();

  const { data: series } = useSeries();

  useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
      setShowBackdrop(false);
      setIsFilterExpanded(false);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    const handleThemeChange = (e: {
      matches: boolean | ((prevState: boolean) => boolean);
    }) => {
      setDarkMode(e.matches);
    };
    mediaQuery.addEventListener("change", handleThemeChange);
    return () => {
      mediaQuery.removeEventListener("change", handleThemeChange);
    };
  }, []);

  useEffect(() => {
    const savedFilterPreferences = sessionStorage.getItem("filterPreferences");

    if (savedFilterPreferences) {
      const { selectedSeries, selectedStartDate, selectedEndDate } = JSON.parse(
        savedFilterPreferences,
      );

      setFilterValues({
        selectedStartDate: selectedStartDate ? selectedStartDate : null,
        selectedEndDate: selectedEndDate ? selectedEndDate : null,
        selectedSeries: selectedSeries,
      });
    } else {
      setFilterValues({
        selectedStartDate: null,
        selectedEndDate: null,
        selectedSeries: series,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSeriesChange = (seriesId: string) => {
    let selected: ISeries[] = [];

    if (seriesId === "ShowAll") {
      if (filterValues) {
        setFilterValues({ ...filterValues, selectedSeries: undefined });
        sessionStorage.setItem("filterPreferences", JSON.stringify(series));
      }
      return;
    }

    if (series) {
      if (seriesId === SeriesName.Nascar) {
        selected = series.filter((s) =>
          s.Name.toUpperCase().includes(SeriesName.Nascar),
        );
      } else if (seriesId === SeriesName.IMSA_WEC) {
        selected = series.filter(
          (s) =>
            s.Name.toUpperCase().includes(SeriesName.IMSA) ||
            s.Name.toUpperCase().includes(SeriesName.WEC),
        );
      } else if (seriesId === SeriesName.Formula1) {
        selected = series.filter((s) =>
          s.Name.toUpperCase().includes(SeriesName.Formula1),
        );
      } else if (seriesId === SeriesName.NTTIndyCar) {
        selected = series.filter((s) =>
          s.Name.toUpperCase().includes(SeriesName.NTTIndyCar),
        );
      } else {
        selected = series.filter(
          (s) => s.SeriesId.toUpperCase() === seriesId.toUpperCase(),
        );
      }
    }

    const filterVals = {
      ...filterValues,
      selectedSeries: selected,
    } as FilterPreferences;

    setFilterValues(filterVals);
    sessionStorage.setItem("filterPreferences", JSON.stringify(filterVals));
  };

  const handleDateChange = (filterDates: Array<Moment | undefined> | null) => {
    let currentStartDate: Date | null = null;
    let currentEndDate: Date | null = null;

    if (!filterDates) {
      currentStartDate = null;
      currentEndDate = null;
    } else {
      currentStartDate = filterDates[0] ? filterDates[0].toDate() : null;
      currentEndDate = filterDates[1] ? filterDates[1].toDate() : null;
    }

    const filterVals = {
      ...filterValues,
      selectedStartDate: currentStartDate,
      selectedEndDate: currentEndDate,
    } as FilterPreferences;

    setFilterValues(filterVals);
    sessionStorage.setItem("filterPreferences", JSON.stringify(filterVals));
  };

  useEffect(() => {
    const getAppInsights = async () => {
      const appInsights = await AppInsightsService.getApplicationInsights({
        config: {
          connectionString:
            "InstrumentationKey=cf33bbef-4a09-4e60-a9da-e0957e7512b9;IngestionEndpoint=https://eastus2-3.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus2.livediagnostics.monitor.azure.com/",
          enableAutoRouteTracking: true,
          enableUnhandledPromiseRejectionTracking: true,
        },
      });
      setAppInsights(appInsights);
    };

    if (!appInsights) {
      getAppInsights();
    }
  }, [appInsights]);

  const ErrorFallback = () => {
    return (
      <div role="alert">
        <p>Something went wrong!</p>
        <p>
          We have logged the error that has occured and will address it asap.
        </p>
      </div>
    );
  };

  const logError = (error: Error, info: { componentStack: string }) => {
    LoggerService.logError(
      "App.tsx",
      `Uncaught error hit ErrorBoundary. Error: ${JSON.stringify(error)}`,
      info.componentStack,
    );
  };

  const renderGutterToolbar = () => {
    const toolbarHeight =
      document && document?.getElementById("header-bar")?.offsetHeight;

    return (
      <Toolbar
        sx={{
          marginTop:
            width < mobileBreakPoint && width > 550
              ? toolbarHeight && toolbarHeight - 30 + "px"
              : toolbarHeight && toolbarHeight - 22 + "px",
        }}
      />
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <SnackbarProvider
          maxSnack={4}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <CssBaseline />
          <Box
            height="100vh"
            flexGrow="1"
            bgcolor={darkMode ? "#3a3a3a" : "white"}
          >
            <AppBar
              position={width < mobileBreakPoint ? "fixed" : "static"}
              sx={{ boxShadow: "unset" }}
            >
              <Toolbar id="header-bar">
                <HeaderAppBar />
              </Toolbar>
              <Filters
                series={series}
                selectedSeries={filterValues?.selectedSeries}
                onSeriesChange={handleSeriesChange}
                onDateChange={handleDateChange}
                onExpanded={(isExpanded: boolean) =>
                  setIsFilterExpanded(isExpanded)
                }
                onOpen={(isOpen: boolean) => setShowBackdrop(isOpen)}
                selectedDates={
                  filterValues?.selectedStartDate !== null ||
                  filterValues?.selectedEndDate !== null
                    ? [
                        filterValues?.selectedStartDate
                          ? moment(filterValues?.selectedStartDate)!
                          : null,
                        filterValues?.selectedEndDate
                          ? moment(filterValues?.selectedEndDate)!
                          : null,
                      ]
                    : null
                }
                isExpanded={isFilterExpanded}
                isMobile={width < mobileBreakPoint}
                screenWidth={width}
              />
            </AppBar>
            {width < mobileBreakPoint && renderGutterToolbar()}
            <MainContent
              width={width}
              mobileBreakPoint={mobileBreakPoint}
              tabletBreakPoint={tabletBreakPoint}
              filterValues={filterValues}
            />
            <Footer darkMode={darkMode} />
            <Backdrop
              sx={{
                zIndex: 1,
              }}
              open={showBackdrop}
            />
          </Box>
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
