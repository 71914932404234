import { ISession } from "../../Interfaces";
import { SessionCard } from "./SessionCard";
import { SessionOperations } from "./SessionItemProps";

type SessionListProps = {
  Sessions: Array<ISession>;
  isMobile: boolean;
  isTablet: boolean;
  isAdmin: boolean;
  sessionChangeHandler: (
    sessionData: ISession,
    operation: SessionOperations,
    isDelayedOrCanceled: boolean,
  ) => void;
  scrollRef: (node?: Element | null | undefined) => void;
};

export const SessionsList = ({
  Sessions,
  isMobile,
  isTablet,
  isAdmin,
  sessionChangeHandler,
  scrollRef,
}: SessionListProps) => {
  return (
    <>
      {Sessions?.map((session: ISession, index: any) => {
        return (
          <SessionCard
            key={`${session.SessionId}-${index}`}
            isMobile={isMobile}
            isTablet={isTablet}
            isSessionHardDelete={true}
            index={index}
            session={session}
            isAdmin={isAdmin ?? false}
            showSessionType={false}
            onSessionChange={(sessionData, operation, isDelayedOrCanceled) => {
              sessionChangeHandler(sessionData, operation, isDelayedOrCanceled);
            }}
            showDelayMenu={true}
            scrollRef={scrollRef}
            useEventUpdate={false}
            shouldHaveScrollRef={
              Sessions.length >= 50 && Sessions.length - 10 === index + 1
            }
          />
        );
      })}
    </>
  );
};
