import { BaseEntity } from "./BaseEntity";
import { IEvent } from "./IEvent";

export type Nullable<T> = T | null | undefined;
export interface IEventSession extends BaseEntity {
  SessionId: string;
  Name: string;
  EventId: string;
  StartTime: string;
  EndTime?: Nullable<string>;
  Status: SessionStatus;
  SessionType: string;
}

export type ISession = {
  ParentEvent: IEvent;
} & IEventSession;

export const SessionType_Practice = "Practice";

export type ISessionType = {
  Name: string;
  IsDisplayed: boolean;
  IsPublished: boolean;
  IsStored: boolean;
};

export type SessionStatus = "Ok" | "Delayed" | "Canceled";

export const statusColors: Record<SessionStatus, string> = {
  Ok: "#4caf50",
  Delayed: "#ff9800",
  Canceled: "#f44336",
};
