import { useQuery } from "react-query";
import { LoggerService } from "./LoggerService";

const ADMIN_ROLE_NAME = "admin";

export type ClaimObject = {
  typ: string;
  val: string;
};

export type ClientPrincipal = {
  userId: string;
  userDetails: string;
  userRoles: Array<string>;
  claims: Array<ClaimObject>;
  isAdmin: boolean;
};

enum UserInfoServiceUrl {
  getUserInfo = "/.auth/me",
}

const getUserInfo = async (): Promise<ClientPrincipal | null> => {
  const response = await fetch(UserInfoServiceUrl.getUserInfo);
  if (!response.ok) {
    const error = `${
      response.status
    } Unable to read user information: ${await response.text()}`;

    LoggerService.logError("getUserInfo()", error, {
      url: UserInfoServiceUrl.getUserInfo,
    });
    throw new Error(error);
  }

  try {
    const payload = await response.json();
    if (!payload?.clientPrincipal) return null;
    const clientPrincipal = payload.clientPrincipal as ClientPrincipal;

    clientPrincipal.isAdmin = userIsAdmin(clientPrincipal);

    return clientPrincipal;
  } catch (error) {
    LoggerService.logError(
      "getUserInfo()",
      `Unable to fetch user information: ${error}`,
      { url: UserInfoServiceUrl.getUserInfo },
    );
    return null;
  }
};

export const useUserInfo = () => {
  return useQuery<ClientPrincipal | null>("userinfo", getUserInfo);
};

const userIsAdmin = (clientPrincipal: ClientPrincipal): boolean => {
  const hasAdminRole = hasRole(clientPrincipal.userRoles, ADMIN_ROLE_NAME);
  const hasAdminClaimRole = hasClaim(clientPrincipal.claims, ADMIN_ROLE_NAME);

  return hasAdminClaimRole || hasAdminRole;
};

const hasRole = (userRoles: Array<string>, roleName: string): boolean => {
  return userRoles.filter((role) => role === roleName).length > 0;
};

const hasClaim = (claims: Array<ClaimObject>, roleName: string): boolean => {
  return (
    claims.filter(
      (claim) =>
        claim.typ.indexOf("role") >= 0 && claim.val.indexOf(roleName) >= 0,
    ).length > 0
  );
};
