import { applicationInsights, trackTrace } from "./AppInsightsService";

const logWarning = (method: string, message: string, ...metaData: any) => {
  if (applicationInsights) {
    trackTrace(
      { message: `${method}: ${message}`, severityLevel: 2 },
      metaData,
    );
  }

  sendConsoleMessage("warn", method, message, metaData);
};

const logError = (method: string, message: string, ...metaData: any) => {
  if (applicationInsights) {
    trackTrace(
      { message: `${method}: ${message}`, severityLevel: 3 },
      metaData,
    );
  }

  sendConsoleMessage("error", method, message, metaData);
};

const logInfo = (method: string, message: string, ...metaData: any) => {
  if (applicationInsights) {
    trackTrace(
      { message: `${method}: ${message}`, severityLevel: 0 },
      metaData,
    );
  }

  sendConsoleMessage("info", method, message, metaData);
};

export const logSuccess = (
  method: string,
  message: string,
  ...metaData: any[]
) => {
  if (applicationInsights) {
    trackTrace({ message: message, severityLevel: 1 }, metaData);
  }

  sendConsoleMessage("success", method, message, metaData);
};

export const sendConsoleMessage = (
  type: "success" | "warn" | "error" | "info",
  method: string,
  msg: string,
  metaData: any,
) => {
  if (typeof metaData == "object") {
    metaData = JSON.stringify(metaData);
  }

  if (type === "success") {
    if (metaData.length > 0) {
      console.log(
        `%c[success] ${method}: ${msg}, ${metaData}`,
        "background: lightgreen; color:#444; border-radius: 5px; padding: 3px",
      );
    } else {
      console.log(
        `%c[success] ${method}: ${msg}`,
        "background: lightgreen; color:#444; border-radius: 5px; padding: 3px",
      );
    }
  } else if (type === "warn") {
    if (metaData.length > 0) {
      console.warn(
        `%c[warn] ${method}: ${msg}, ${metaData}`,
        "background: lightyellow; color:#444; border-radius: 5px; padding: 3px",
      );
    } else {
      console.warn(
        `%c[warn] ${method}: ${msg}`,
        "background: lightyellow; color:#444; border-radius: 5px; padding: 3px",
      );
    }
  } else if (type === "error") {
    if (metaData.length > 0) {
      console.error(
        `%c[error] ${method}: ${msg}, ${metaData}`,
        "background: lightred; color:#444; border-radius: 5px; padding: 3px",
      );
    } else {
      console.error(
        `%c[error] ${method}: ${msg}`,
        "background: lightred; color:#444; border-radius: 5px; padding: 3px",
      );
    }
  } else if (type === "info") {
    if (metaData.length > 0) {
      console.info(
        `%c[info] ${method}: ${msg}, ${metaData}`,
        "background: lightgrey; color:#444; border-radius: 5px; padding: 3px",
      );
    } else {
      console.info(
        `%c[info] ${method}: ${msg}`,
        "background: lightgrey; color:#444; border-radius: 5px; padding: 3px",
      );
    }
  }
};

export const LoggerService = {
  logInfo,
  logWarning,
  logError,
  logSuccess,
};
