import { Button, Grid, Link, Tab, Tabs, Typography } from "@mui/material";
import moment from "moment-timezone";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { EventStatus, IEvent, ISession } from "../../Interfaces";
import { FilterPreferences } from "../../Interfaces/FilterPreferences";
import { ListViewProps } from "../../Interfaces/IEventList";
import { SnackbarMessage } from "../../Interfaces/SnackbarMessages";
import {
  sortEventsAscending,
  sortEventsDescending,
  useCreateEvent,
  useDeleteEventById,
  useInfiniteEvents,
  useUpdateEvent,
} from "../../Services/EventService";
import { LoggerService } from "../../Services/LoggerService";
import { useEventTypes, useSponsors } from "../../Services/LookupService";
import {
  sortSessionsDescending,
  useDeleteSessionById,
  useInfiniteSessions,
  useUpdateSession,
} from "../../Services/SessionService";
import { LoadingSkeleton } from "../Common/LoadingSkeleton";
import { EventDialog } from "../EventDialog/EventFormDialog";
import { EventList } from "../ListEvents/EventList";
import { SessionOperations } from "../ListSessions/SessionItemProps";
import { SessionsList } from "../ListSessions/SessionsList";
import { SessionUpdates } from "../SessionUpdates/SessionUpdates";
import { ConfirmationDialog } from "../Shared/DialogConfirm";
import {
  filterEvents,
  filterSessions,
  processSessions,
} from "./FilteringUtils";

const currentYear: FilterPreferences = {
  selectedSeries: undefined,
  selectedStartDate: null,
  selectedEndDate: null,
};

export const enum ViewOptions {
  Events = "Events",
  Sessions = "Sessions",
  History = "History",
}

export const ListView: React.FC<ListViewProps> = ({
  isMobile,
  isTablet,
  series,
  sites,
  filterValues,
  userInfo,
}: ListViewProps) => {
  const { ref: eventRef, inView: lastEventInView } = useInView();
  const { ref: sessionRef, inView: lastSessionInView } = useInView();
  const { ref: previousEventRef, inView: lastPreviousEventInView } =
    useInView();
  const { ref: previousSessionRef, inView: lastPreviousSessionsInView } =
    useInView();
  const { enqueueSnackbar } = useSnackbar();

  const [listView, setListView] = useState<ViewOptions[]>(() => [
    ViewOptions.Events,
  ]);
  const [tabsValue, setTabsValue] = useState<string>(ViewOptions.Events);

  const [isAddEventFormOpen, setIsAddEventFormOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSessionUpdate, setIsSessionUpdate] = useState(false);
  const [isSessionDelete, setIsSessionDelete] = useState(false);
  const [isDelayedOrCanceled, setIsDelayedOrCanceled] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<IEvent | null>(null);
  const [filteredEvents, setFilteredEvents] = useState<IEvent[]>();
  const [filteredPreviousEvents, setFilteredPreviousEvents] =
    useState<IEvent[]>();
  const [filteredSessions, setFilteredSessions] = useState<ISession[]>();
  const [filteredPreviousSessions, setFilteredPreviousSessions] =
    useState<ISession[]>();
  const [lastRefresh, setLastRefresh] = useState<string>("");
  const [isEventViewEnabled, setIsEventViewEnabled] = useState(false);
  const [isSessionViewEnabled, setIsSessionViewEnabled] = useState(false);
  const [sortedNotifications, setSortedNotifications] = useState<
    ISession[] | undefined
  >([]);

  const { data: sponsors, isLoading: isLoadingSponsors } = useSponsors();
  const { data: eventTypes } = useEventTypes();

  // Event Queries

  const {
    data: eventsPage,
    fetchNextPage: fetchNextEventPage,
    hasNextPage: hasNextEventPage,
    isFetching: isLoadingEvents,
    isFetchingNextPage: isFetchingNextEventPage,
    refetch: refetchEvents,
  } = useInfiniteEvents(
    userInfo?.isAdmin,
    !userInfo?.isAdmin,
    isEventViewEnabled && !listView.includes(ViewOptions.History),
    false,
    filterValues?.selectedStartDate === null
      ? { ...filterValues, selectedStartDate: currentYear.selectedStartDate }
      : filterValues,
  );

  useEffect(() => {
    if (lastEventInView && hasNextEventPage && !isLoadingEvents) {
      fetchNextEventPage();
    }
  }, [lastEventInView, hasNextEventPage, fetchNextEventPage, isLoadingEvents]);

  const {
    data: previousEventsPage,
    fetchNextPage: fetchNextPreviousEventPage,
    hasNextPage: hasNextPreviousEventPage,
    isFetching: isLoadingPreviousEvents,
    refetch: refetchPreviousEvents,
    isFetchingNextPage: isFetchingNextPreviousEventPage,
  } = useInfiniteEvents(
    userInfo?.isAdmin,
    !userInfo?.isAdmin,
    isEventViewEnabled && listView.includes(ViewOptions.History),
    true,
    filterValues,
    "DESC",
  );

  useEffect(() => {
    if (
      lastPreviousEventInView &&
      hasNextPreviousEventPage &&
      !isLoadingPreviousEvents
    ) {
      fetchNextPreviousEventPage();
    }
  }, [
    lastPreviousEventInView,
    hasNextPreviousEventPage,
    fetchNextPreviousEventPage,
    isLoadingPreviousEvents,
  ]);

  const {
    isLoading: createEventLoading,
    isSuccess: createEventSuccess,
    error: createEventError,
    mutate: createEvent,
  } = useCreateEvent();
  const {
    isLoading: updateEventLoading,
    isSuccess: updateEventSuccess,
    error: updateEventError,
    mutate: updateEvent,
  } = useUpdateEvent();
  const {
    isLoading: deleteEventLoading,
    isSuccess: deleteEventSuccess,
    error: deleteEventError,
    mutate: deleteEvent,
  } = useDeleteEventById();

  // Session Queries

  const {
    data: sessionsPage,
    fetchNextPage: fetchNextSessionPage,
    hasNextPage: hasNextSessionPage,
    isFetching: isLoadingSessions,
    isFetchingNextPage: isFetchingNextSessionPage,
    refetch: refetchSessions,
  } = useInfiniteSessions(
    userInfo?.isAdmin,
    !userInfo?.isAdmin,
    isSessionViewEnabled && !listView.includes(ViewOptions.History),
    false,
    filterValues?.selectedStartDate === null
      ? { ...filterValues, selectedStartDate: currentYear.selectedStartDate }
      : filterValues,
  );

  const {
    data: previousSessionsPage,
    fetchNextPage: fetchNextPreviousSessionsPage,
    hasNextPage: hasNextPreviousSessionsPage,
    isFetching: isLoadingPreviousSessions,
    refetch: refetchPreviousSessions,
    isFetchingNextPage: isFetchingNextPreviousSessionsPage,
  } = useInfiniteSessions(
    userInfo?.isAdmin,
    !userInfo?.isAdmin,
    isSessionViewEnabled && listView.includes(ViewOptions.History),
    true,
    filterValues,
    "DESC",
  );

  useEffect(() => {
    if (lastSessionInView && hasNextSessionPage && !isLoadingSessions) {
      fetchNextSessionPage();
    }
  }, [
    lastSessionInView,
    hasNextSessionPage,
    fetchNextSessionPage,
    isLoadingSessions,
  ]);

  useEffect(() => {
    if (
      lastPreviousSessionsInView &&
      hasNextPreviousSessionsPage &&
      !isLoadingPreviousSessions
    ) {
      fetchNextPreviousSessionsPage();
    }
  }, [
    lastPreviousSessionsInView,
    hasNextPreviousSessionsPage,
    fetchNextPreviousSessionsPage,
    isLoadingPreviousSessions,
  ]);

  const {
    isLoading: updateSessionLoading,
    isSuccess: updateSessionSuccess,
    error: updateSessionError,
    mutate: updateSession,
  } = useUpdateSession();
  const {
    isLoading: deleteSessionLoading,
    isSuccess: deleteSessionSuccess,
    error: deleteSessionError,
    mutate: deleteSession,
  } = useDeleteSessionById();

  // Create event
  useEffect(() => {
    if (createEventLoading) {
      enqueueSnackbar(SnackbarMessage.CreateEventLoading, {
        variant: "info",
      });
    }
    if (createEventSuccess) {
      enqueueSnackbar(SnackbarMessage.CreateEventSuccess, {
        variant: "success",
      });
    }
    if (createEventError) {
      enqueueSnackbar(SnackbarMessage.CreateEventError, {
        variant: "error",
      });

      LoggerService.logError(
        "Create event error occurred:",
        createEventError?.message,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createEventError, createEventLoading, createEventSuccess]);

  // Update event
  useEffect(() => {
    if (updateEventLoading && !isDelayedOrCanceled) {
      enqueueSnackbar(
        !isSessionUpdate
          ? SnackbarMessage.UpdateEventLoading
          : isSessionDelete
            ? SnackbarMessage.DeleteSessionLoading
            : SnackbarMessage.UpdateSessionLoading,
        {
          variant: "info",
        },
      );
    }
    if (updateEventSuccess && !isDelayedOrCanceled) {
      enqueueSnackbar(
        !isSessionUpdate
          ? SnackbarMessage.UpdateEventSuccess
          : isSessionDelete
            ? SnackbarMessage.DeleteSessionSuccess
            : SnackbarMessage.UpdateSessionSuccess,
        {
          variant: "success",
        },
      );
    }
    if (updateEventError && !isDelayedOrCanceled) {
      enqueueSnackbar(
        !isSessionUpdate
          ? SnackbarMessage.UpdateEventError
          : isSessionDelete
            ? SnackbarMessage.DeleteSessionError
            : SnackbarMessage.UpdateSessionError,
        {
          variant: "error",
        },
      );

      LoggerService.logError(
        "Update event error occurred:",
        updateEventError?.message,
      );
    }

    if (isSessionUpdate) {
      setTimeout(() => setIsSessionUpdate(false), 1000);
    }

    if (isDelayedOrCanceled) {
      setTimeout(() => setIsDelayedOrCanceled(false), 1000);
    }

    if (isSessionDelete) {
      setTimeout(() => setIsSessionDelete(false), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateEventError, updateEventLoading, updateEventSuccess]);

  // Delete event
  useEffect(() => {
    if (deleteEventLoading) {
      enqueueSnackbar(SnackbarMessage.DeleteEventLoading, {
        variant: "info",
      });
    }
    if (deleteEventSuccess) {
      enqueueSnackbar(SnackbarMessage.DeleteEventSuccess, {
        variant: "success",
      });
    }
    if (deleteEventError) {
      enqueueSnackbar(SnackbarMessage.DeleteEventError, {
        variant: "error",
      });

      LoggerService.logError(
        "Delete event error occurred:",
        deleteEventError?.message,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEventError, deleteEventLoading, deleteEventSuccess]);

  // Update session
  useEffect(() => {
    if (updateSessionLoading && !isDelayedOrCanceled) {
      enqueueSnackbar(SnackbarMessage.UpdateSessionLoading, {
        variant: "info",
      });
    }
    if (updateSessionSuccess && !isDelayedOrCanceled) {
      enqueueSnackbar(SnackbarMessage.UpdateSessionSuccess, {
        variant: "success",
      });
    }
    if (updateSessionError && !isDelayedOrCanceled) {
      enqueueSnackbar(SnackbarMessage.UpdateSessionError, {
        variant: "error",
      });

      LoggerService.logError(
        "Update session error occurred:",
        updateSessionError?.message,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSessionError, updateSessionLoading, updateSessionSuccess]);

  // Delete session
  useEffect(() => {
    if (deleteSessionLoading) {
      enqueueSnackbar(SnackbarMessage.DeleteSessionLoading, {
        variant: "info",
      });
    }
    if (deleteSessionSuccess) {
      enqueueSnackbar(SnackbarMessage.DeleteSessionSuccess, {
        variant: "success",
      });
    }
    if (deleteSessionError) {
      if (deleteSessionError.message.includes("404")) {
        enqueueSnackbar(SnackbarMessage.DeleteSessionSuccess, {
          variant: "success",
        });
        handleEventRefresh();
      } else {
        enqueueSnackbar(SnackbarMessage.DeleteSessionError, {
          variant: "error",
        });

        LoggerService.logError(
          "Delete session error occurred:",
          deleteSessionError?.message,
        );
      }
    }
    if (deleteSessionSuccess) {
      handleEventRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSessionError, deleteSessionLoading, deleteSessionSuccess]);

  useEffect(() => {
    if (
      createEventSuccess ||
      updateEventSuccess ||
      deleteEventSuccess ||
      updateSessionSuccess ||
      deleteSessionSuccess
    ) {
      handleEventRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createEventSuccess,
    deleteEventSuccess,
    deleteSessionSuccess,
    updateEventSuccess,
    updateSessionSuccess,
  ]);

  useEffect(() => {
    const currentTime = moment().format("MMMM D, YYYY h:mm A").toString();
    setLastRefresh(currentTime);
  }, [
    isLoadingEvents,
    isLoadingSessions,
    isLoadingPreviousEvents,
    isLoadingPreviousSessions,
  ]);

  useEffect(() => {
    if (eventsPage && series && eventTypes) {
      if (userInfo === undefined) return;

      const events = eventsPage.pages?.flatMap((page) => {
        const pageEvents = [...page.Data];
        return pageEvents?.map((e) => {
          e.Series = series?.find(
            (s) => s.SeriesId?.toLowerCase() === e.SeriesId?.toLowerCase(),
          );
          return e;
        });
      });
      let combinedFilteredEvents = filterEvents(
        events,
        filterValues,
        userInfo,
        false,
        eventTypes,
      ).sort(sortEventsAscending);
      setFilteredEvents(combinedFilteredEvents);
      const combinedSessions = combinedFilteredEvents.flatMap((event) =>
        event?.Sessions?.filter(
          (session) =>
            session.Status === EventStatus.Delayed ||
            session.Status === EventStatus.Canceled,
        ).map((session) => ({
          ...session,
          ParentEvent: event,
        })),
      );
      setSortedNotifications((sortedNotifications) =>
        processSessions(
          sortedNotifications as ISession[],
          combinedSessions,
          series.length === filterValues?.selectedSeries?.length,
          filterValues,
        ),
      );
    }
  }, [eventsPage, filterValues, userInfo, series, eventTypes]);

  useEffect(() => {
    if (previousEventsPage && series && eventTypes) {
      if (userInfo === undefined) return;
      const previousEvents = previousEventsPage.pages.flatMap((page) => {
        const pageEvents = [...page.Data];
        return pageEvents.map((e) => {
          e.Series = series.find(
            (s) => s.SeriesId?.toLowerCase() === e.SeriesId?.toLowerCase(),
          );
          return e;
        });
      });
      let combinedFilteredEvents = filterEvents(
        previousEvents,
        filterValues,
        userInfo,
        true,
        eventTypes,
      ).sort(sortEventsDescending);
      setFilteredPreviousEvents(combinedFilteredEvents);
    }
  }, [previousEventsPage, filterValues, userInfo, series, eventTypes]);

  useEffect(() => {
    if (sessionsPage && series && eventTypes) {
      if (userInfo === undefined) return;
      const sessions = sessionsPage.pages.flatMap((page) => {
        const pageSessions = [...page.Data];
        return pageSessions.map((ps) => {
          ps.ParentEvent.Series = series.find(
            (s) =>
              s.SeriesId.toLowerCase() ===
              ps.ParentEvent.SeriesId.toLowerCase(),
          );
          return ps;
        });
      });
      let combinedFilteredSessions = filterSessions(
        sessions,
        filterValues,
        userInfo,
        false,
        eventTypes,
      );
      setFilteredSessions(combinedFilteredSessions);
      const sortedSessions = combinedFilteredSessions.flatMap((session) =>
        session?.Status === EventStatus.Delayed ||
        session?.Status === EventStatus.Canceled
          ? { ...session, ParentEvent: session.ParentEvent }
          : [],
      );

      setSortedNotifications((sortedNotifications) =>
        processSessions(
          sortedNotifications as ISession[],
          sortedSessions,
          series.length === filterValues?.selectedSeries?.length,
          filterValues,
        ),
      );
    }
  }, [sessionsPage, filterValues, userInfo, series, eventTypes]);

  useEffect(() => {
    if (previousSessionsPage && series && eventTypes) {
      if (userInfo === undefined) return;
      const previousSessions = previousSessionsPage.pages.flatMap((page) => {
        const pageSessions = [...page.Data];
        return pageSessions.map((ps) => {
          ps.ParentEvent.Series = series.find(
            (s) =>
              s.SeriesId?.toLowerCase() ===
              ps.ParentEvent.SeriesId?.toLowerCase(),
          );
          return ps;
        });
      });
      let combinedFilteredSessions = filterSessions(
        previousSessions,
        filterValues,
        userInfo,
        true,
        eventTypes,
      ).sort(sortSessionsDescending);
      setFilteredPreviousSessions(combinedFilteredSessions);
    }
  }, [previousSessionsPage, filterValues, userInfo, series, eventTypes]);

  useEffect(() => {
    const savedViewPreference = localStorage.getItem("viewPreference");

    if (savedViewPreference) {
      let selectedView: ViewOptions[] = JSON.parse(savedViewPreference);

      if (typeof selectedView === "object" && !Array.isArray(selectedView)) {
        selectedView = [ViewOptions.Events];
        localStorage.setItem("viewPreference", JSON.stringify(selectedView));
      }

      if (
        selectedView &&
        selectedView.some(
          (viewOption: ViewOptions) => viewOption === ViewOptions.History,
        )
      ) {
        setListView(selectedView);
        setTabsValue(ViewOptions.History);
        handleListViewQueries(selectedView[0]);
      } else {
        setListView(selectedView);
        setTabsValue(selectedView[0]);
        handleListViewQueries(selectedView[0]);
      }
    } else {
      handleListViewQueries(ViewOptions.Events);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddEventClick = () => {
    setIsEdit(false);
    setSelectedEvent(null);
    setIsAddEventFormOpen(true);
  };

  const handleEditEvent = (event: IEvent) => {
    setIsEdit(true);
    setSelectedEvent(event);
    setIsAddEventFormOpen(true);
  };

  const handleClose = () => {
    setIsEdit(false);
    setSelectedEvent(null);
    setIsAddEventFormOpen(false);
  };

  const handleCancel = () => {
    setIsEdit(false);
    setSelectedEvent(null);
    setIsAddEventFormOpen(false);
  };

  const handleAddEvent = (event: IEvent) => {
    createEvent(event);
  };

  const handleCancelConfirm = () => {
    setIsDeleteConfirmationOpen(false);
  };

  const handleConfirmDelete = (event: IEvent) => {
    setSelectedEvent(event);
    setIsDeleteConfirmationOpen(true);
  };

  const handleDeleteEvent = (event: IEvent) => {
    deleteEvent(event);
    setSelectedEvent(null);
    setIsDeleteConfirmationOpen(false);
    setIsAddEventFormOpen(false);
    setIsEdit(false);
  };

  const handleEventUpdateBecauseOfSessionUpdate = (
    eventData: IEvent,
    isSessionUpdate: boolean,
    isDelayedOrCanceled: boolean,
    isDelete: boolean,
  ) => {
    setIsSessionUpdate(isSessionUpdate);
    setIsDelayedOrCanceled(isDelayedOrCanceled);
    setIsSessionDelete(isDelete);
    updateEvent(eventData);
  };

  const handleEventRefresh = () => {
    listView.includes(ViewOptions.History)
      ? refetchPreviousEvents()
      : refetchEvents();
    listView.includes(ViewOptions.History)
      ? refetchPreviousSessions()
      : refetchSessions();
  };

  const handleListViewChange = (viewOption: ViewOptions) => {
    if (viewOption !== null) {
      if (
        viewOption === ViewOptions.Events ||
        viewOption === ViewOptions.Sessions
      ) {
        setListView([viewOption]);
        handleListViewQueries(
          viewOption === ViewOptions.Events
            ? ViewOptions.Events
            : ViewOptions.Sessions,
        );
        localStorage.setItem("viewPreference", JSON.stringify([viewOption]));
      } else {
        setListView([...listView, ViewOptions.History]);
        localStorage.setItem(
          "viewPreference",
          JSON.stringify([...listView, ViewOptions.History]),
        );
      }
    }
  };

  const sessionChangeHandler = (
    sessionData: ISession,
    operation: SessionOperations,
    isSessionDelayedOrCanceled: boolean,
  ) => {
    handleSessionChange(sessionData, operation, isSessionDelayedOrCanceled);
  };

  const handleSessionChange = (
    updatedSession: ISession,
    operation: SessionOperations,
    isDelayedOrCanceled: boolean,
  ): void => {
    setIsDelayedOrCanceled(isDelayedOrCanceled);
    switch (operation) {
      case "Edit":
        updateSession(updatedSession);
        break;
      case "Delete":
        deleteSession(updatedSession.SessionId);
        break;
    }
    handleEventRefresh();
  };

  const handleListViewQueries = (newView: string) => {
    setIsEventViewEnabled(newView === ViewOptions.Events);
    setIsSessionViewEnabled(newView === ViewOptions.Sessions);
  };

  const getTargetRef = () => {
    if (listView.includes(ViewOptions.History)) {
      return listView.includes(ViewOptions.Events)
        ? previousEventRef
        : previousSessionRef;
    }
    return listView.includes(ViewOptions.Events) ? eventRef : sessionRef;
  };

  const getTabName = () => {
    let tabName = "";

    if (listView.includes(ViewOptions.History)) {
      tabName = "Past ";
    }

    if (listView.includes(ViewOptions.Events)) {
      tabName = `${tabName}${ViewOptions.Events}`;
    } else {
      tabName = `${tabName}${ViewOptions.Sessions}`;
    }

    return tabName;
  };

  return (
    <>
      {
        <>
          <Grid container width="100%" flexGrow="1">
            {!listView.includes(ViewOptions.History) &&
              sortedNotifications &&
              sortedNotifications?.length > 0 && (
                <SessionUpdates notifications={sortedNotifications} />
              )}
            <Grid container marginTop="0.5rem" justifyContent={"center"}>
              {!isMobile && (
                <Grid item xs={2} md={4}>
                  <Typography variant="h5" padding="0.5rem 1.2rem">
                    {getTabName()}
                  </Typography>
                </Grid>
              )}
              {userInfo?.isAdmin && isMobile && (
                <Grid
                  item
                  xs={12}
                  p="0rem 2rem 1rem 2rem"
                  display="flex"
                  justifyContent={"end"}
                >
                  <Link
                    onClick={handleAddEventClick}
                    underline="none"
                    sx={{ cursor: "pointer", fontWeight: 500 }}
                  >
                    + Add Event
                  </Link>
                </Grid>
              )}
              <Grid item xs={12} md={4} display="flex" justifyContent="center">
                <Tabs
                  role="tabpanel"
                  value={tabsValue}
                  onChange={(_event, value) => {
                    setTabsValue(value);
                    handleListViewChange(value);
                  }}
                  aria-label="List view selection"
                  centered
                >
                  <Tab
                    aria-label="Events"
                    label="Events"
                    value={ViewOptions.Events}
                  />
                  <Tab
                    aria-label="Sessions"
                    label="Sessions"
                    value={ViewOptions.Sessions}
                  />
                  <Tab
                    aria-label={
                      listView.includes(ViewOptions.Events)
                        ? "Past Events"
                        : "Past Sessions"
                    }
                    hidden={isMobile || isTablet}
                    label={
                      listView.includes(ViewOptions.Events)
                        ? "Past Events"
                        : "Past Sessions"
                    }
                    value={ViewOptions.History}
                    wrapped={isMobile}
                  />
                </Tabs>
              </Grid>
              {!isMobile && (
                <Grid
                  item
                  xs={2}
                  md={4}
                  display="flex"
                  justifyContent={isMobile ? "start" : "end"}
                >
                  {userInfo?.isAdmin && (
                    <Button
                      sx={{
                        fontSize: {
                          minWidth: "8rem",
                          lg: 15,
                          md: 14,
                          sm: 13,
                          xs: 12,
                        },
                        marginRight: "1rem",
                      }}
                      variant="contained"
                      onClick={handleAddEventClick}
                    >
                      Add Event
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              {series && sites && !isLoadingSponsors && (
                <>
                  {/* Event View */}
                  {listView.includes(ViewOptions.Events) &&
                    !listView.includes(ViewOptions.History) &&
                    (!isLoadingEvents || isFetchingNextEventPage) && (
                      <>
                        {filteredEvents && filteredEvents.length !== 0 ? (
                          <EventList
                            Events={filteredEvents}
                            isAdmin={userInfo?.isAdmin || false}
                            updateEventHandler={(
                              event: IEvent,
                              isSessionUpdate,
                              isDelayedOrCanceled,
                              isDelete,
                            ) => {
                              handleEventUpdateBecauseOfSessionUpdate(
                                event,
                                isSessionUpdate,
                                isDelayedOrCanceled,
                                isDelete,
                              );
                            }}
                            confirmDeleteHandler={handleConfirmDelete}
                            editEventHandler={handleEditEvent}
                            refreshHandler={handleEventRefresh}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            isEventView={true}
                            scrollRef={getTargetRef()}
                          />
                        ) : filteredEvents &&
                          filteredEvents.length === 0 &&
                          hasNextEventPage ? (
                          <div ref={getTargetRef()} />
                        ) : (
                          filteredEvents &&
                          filteredEvents.length === 0 &&
                          !hasNextEventPage && (
                            <Typography
                              component="div"
                              mt={3}
                              fontStyle="bold"
                              textAlign="center"
                              fontSize={{
                                lg: 22,
                                md: 22,
                                sm: 16,
                                xs: 16,
                              }}
                            >
                              No events were found.
                            </Typography>
                          )
                        )}
                      </>
                    )}

                  {/* Previous Event View */}
                  {listView.includes(ViewOptions.Events) &&
                    listView.includes(ViewOptions.History) &&
                    (!isLoadingPreviousEvents ||
                      isFetchingNextPreviousEventPage) && (
                      <>
                        {filteredPreviousEvents &&
                        filteredPreviousEvents.length !== 0 ? (
                          <>
                            <EventList
                              Events={filteredPreviousEvents}
                              isAdmin={userInfo?.isAdmin || false}
                              updateEventHandler={(
                                event: IEvent,
                                isSessionUpdate,
                                isDelayedOrCanceled,
                                isDelete,
                              ) => {
                                handleEventUpdateBecauseOfSessionUpdate(
                                  event,
                                  isSessionUpdate,
                                  isDelayedOrCanceled,
                                  isDelete,
                                );
                              }}
                              confirmDeleteHandler={handleConfirmDelete}
                              editEventHandler={handleEditEvent}
                              refreshHandler={handleEventRefresh}
                              isMobile={isMobile}
                              isTablet={isTablet}
                              isEventView={true}
                              scrollRef={getTargetRef()}
                            />
                          </>
                        ) : filteredPreviousEvents &&
                          filteredPreviousEvents.length === 0 &&
                          hasNextPreviousEventPage ? (
                          <div ref={getTargetRef()} />
                        ) : (
                          filteredPreviousEvents &&
                          filteredPreviousEvents.length === 0 &&
                          !hasNextPreviousEventPage && (
                            <Typography
                              component="div"
                              mt={3}
                              fontStyle="bold"
                              textAlign="center"
                              fontSize={{
                                lg: 22,
                                md: 22,
                                sm: 16,
                                xs: 16,
                              }}
                            >
                              No past events were found.
                            </Typography>
                          )
                        )}
                      </>
                    )}

                  {/* Session View */}
                  {listView.includes(ViewOptions.Sessions) &&
                    !listView.includes(ViewOptions.History) &&
                    (!isLoadingSessions || isFetchingNextSessionPage) && (
                      <>
                        {filteredSessions && filteredSessions.length !== 0 ? (
                          <SessionsList
                            Sessions={filteredSessions}
                            isAdmin={userInfo?.isAdmin || false}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            sessionChangeHandler={sessionChangeHandler}
                            scrollRef={getTargetRef()}
                          />
                        ) : filteredSessions &&
                          filteredSessions.length === 0 &&
                          hasNextSessionPage ? (
                          <div ref={getTargetRef()} />
                        ) : (
                          filteredSessions &&
                          filteredSessions.length === 0 &&
                          !hasNextSessionPage && (
                            <Typography
                              component="div"
                              mt={3}
                              fontStyle="bold"
                              textAlign="center"
                              fontSize={{
                                lg: 22,
                                md: 22,
                                sm: 16,
                                xs: 16,
                              }}
                            >
                              No sessions were found.
                            </Typography>
                          )
                        )}
                      </>
                    )}

                  {/* Previous Session View */}
                  {listView.includes(ViewOptions.Sessions) &&
                    listView.includes(ViewOptions.History) &&
                    (!isLoadingPreviousSessions ||
                      isFetchingNextPreviousSessionsPage) && (
                      <>
                        {filteredPreviousSessions &&
                        filteredPreviousSessions.length !== 0 ? (
                          <>
                            <SessionsList
                              Sessions={filteredPreviousSessions}
                              isAdmin={userInfo?.isAdmin || false}
                              isMobile={isMobile}
                              isTablet={isTablet}
                              sessionChangeHandler={sessionChangeHandler}
                              scrollRef={getTargetRef()}
                            />
                          </>
                        ) : filteredPreviousEvents &&
                          filteredPreviousEvents.length === 0 &&
                          hasNextPreviousEventPage ? (
                          <div ref={getTargetRef()} />
                        ) : (
                          filteredPreviousEvents &&
                          filteredPreviousEvents.length === 0 &&
                          !hasNextPreviousEventPage && (
                            <Typography
                              component="div"
                              mt={3}
                              fontStyle="bold"
                              textAlign="center"
                              fontSize={{
                                lg: 22,
                                md: 22,
                                sm: 16,
                                xs: 16,
                              }}
                            >
                              No past events were found.
                            </Typography>
                          )
                        )}
                      </>
                    )}
                </>
              )}

              {!series ||
              !sites ||
              isLoadingSponsors ||
              isLoadingEvents ||
              isLoadingPreviousEvents ||
              isLoadingSessions ||
              isLoadingPreviousSessions ? (
                <LoadingSkeleton
                  numberOfSkeletons={3}
                  isMobile={isMobile}
                  listView={listView}
                />
              ) : null}
            </Grid>
            <Typography
              component="span"
              ml={3}
              fontStyle="italic"
              fontSize={{
                lg: 14,
                md: 12,
                sm: 12,
                xs: 12,
              }}
            >
              Last Updated: {lastRefresh}
            </Typography>
          </Grid>
          <EventDialog
            isMobile={isMobile}
            isTablet={isTablet}
            sponsors={sponsors}
            isEditMode={isEdit}
            isAddEventFormOpen={isAddEventFormOpen}
            onClose={handleClose}
            onCancel={handleCancel}
            selectedEvent={selectedEvent}
            onAddEvent={handleAddEvent}
            onUpdateEvent={(event: IEvent) => updateEvent(event)}
            onDeleteEvent={handleConfirmDelete}
          />
          {selectedEvent && (
            <ConfirmationDialog
              open={isDeleteConfirmationOpen}
              onClose={(accept) => {
                if (accept) handleDeleteEvent(selectedEvent!);
              }}
              onCancel={handleCancelConfirm}
              confirmationQuestion={`Are you sure you want to delete ${selectedEvent.Name}?`}
            />
          )}
        </>
      }
    </>
  );
};
