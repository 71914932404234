import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { EventStatus, ISession } from "../../Interfaces";
import { SnackbarMessage } from "../../Interfaces/SnackbarMessages";
import { LoggerService } from "../../Services/LoggerService";
import {
  useCancelSession,
  useDelaySession,
  useDeleteSessionById,
} from "../../Services/SessionService";
import { eventValidationSchema } from "../../Validations/AddEvent.Yup";
import { DelayDialog, DelayDialogResult } from "../DialogDelay/DelayDialog";
import { SessionDialog } from "../DialogSession/SessionInputDialog";
import { EventDetails } from "../ListEvents/EventDetails";
import {
  DesktopActionsToolbar,
  MobileActionsToolbar,
} from "../SessionActions/ActionsToolbar";
import { SessionItemProps } from "./SessionItemProps";
import { SessionTime } from "./SessionText";
import { StatusDot } from "./StatusDot";

export const SessionCard = ({
  isMobile,
  isTablet,
  isEventView,
  index,
  session,
  isAdmin,
  showSessionType,
  showDelayMenu,
  isSessionHardDelete,
  onSessionChange,
  scrollRef,
  shouldHaveScrollRef,
}: SessionItemProps) => {
  const [openDelayDialog, setOpenDelayDialog] = useState(false);
  const [isSessionDialogOpen, setSessionDialogOpen] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [delayMinutes, setDelayMinutes] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();
  const {
    isLoading: delaySessionLoading,
    isSuccess: delaySessionSuccess,
    error: delaySessionError,
    mutate: delaySession,
  } = useDelaySession();
  const {
    isLoading: cancelSessionLoading,
    isSuccess: cancelSessionSuccess,
    error: cancelSessionError,
    mutate: cancelSession,
  } = useCancelSession();
  const { isSuccess: deleteSessionSuccess, error: deleteSessionError } =
    useDeleteSessionById();

  useEffect(() => {
    // Delay Session
    if (delaySessionLoading) {
      enqueueSnackbar(SnackbarMessage.DelaySessionLoading, {
        variant: "info",
      });
    }
    if (delaySessionSuccess) {
      enqueueSnackbar(SnackbarMessage.DelaySessionSuccess, {
        variant: "success",
      });
      onSessionChange(session, "Edit", true, false);
    }
    if (delaySessionError) {
      enqueueSnackbar(SnackbarMessage.DelaySessionError, {
        variant: "error",
      });

      LoggerService.logError(
        "Delay session error occured:",
        delaySessionError?.message,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delaySessionError, delaySessionLoading, delaySessionSuccess]);

  useEffect(() => {
    // Cancel Session
    if (cancelSessionLoading) {
      enqueueSnackbar(
        isCanceling
          ? SnackbarMessage.CancelSessionLoading
          : SnackbarMessage.ActivateSessionLoading,
        {
          variant: "info",
        },
      );
    }
    if (cancelSessionSuccess) {
      enqueueSnackbar(
        isCanceling
          ? SnackbarMessage.CancelSessionSuccess
          : SnackbarMessage.ActivateSessionSuccess,
        {
          variant: "success",
        },
      );
      onSessionChange(session, "Edit", true, false);
    }
    if (cancelSessionError) {
      enqueueSnackbar(
        isCanceling
          ? SnackbarMessage.CancelSessionError
          : SnackbarMessage.ActivateSessionError,
        {
          variant: "error",
        },
      );

      LoggerService.logError(
        "Delay session error occured:",
        cancelSessionError?.message,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelSessionError, cancelSessionLoading, cancelSessionSuccess]);

  useEffect(() => {
    // Delete Session
    if (deleteSessionSuccess) {
      onSessionChange(session, "Delete", false, true);
    }
    if (deleteSessionError) {
      LoggerService.logError(
        "Delete session error occured:",
        deleteSessionError?.message,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSessionSuccess, deleteSessionError]);

  const cancelDelay = () => {
    setDelayMinutes("");
    setOpenDelayDialog(false);
  };

  const setDelay = (delayResult: DelayDialogResult) => {
    const delay = {
      eventId: session.ParentEvent.EventId as string,
      sessionId: session?.SessionId as string,
      delayMinutes: delayResult.delayMinutes,
    };
    delaySession(delay);
    setOpenDelayDialog(false);
  };

  const handleDelay = () => {
    setOpenDelayDialog(true);
  };

  const handleCanceled = (sessionData: ISession) => {
    const currentState = sessionData?.Status;
    const isCanceling = EventStatus.Canceled === currentState ? false : true;

    const cancel = {
      eventId: session.ParentEvent.EventId as string,
      sessionId: sessionData.SessionId as string,
      isCanceled: isCanceling,
    };
    setIsCanceling(isCanceling);
    cancelSession(cancel);
  };

  const handleCloseSessionDialog = (): void => {
    setSessionDialogOpen(false);
  };

  const handleEdit = () => {
    setSessionDialogOpen(true);
  };

  const handleDelete = (sessionData: ISession) => {
    const updatedSessions = [...session.ParentEvent.Sessions!];
    const index = updatedSessions.findIndex(
      (s) => s.SessionId === sessionData?.SessionId,
    );
    updatedSessions.splice(index, 1);
    const updatedEvent = {
      ...session.ParentEvent,
      Sessions: updatedSessions,
    };
    eventValidationSchema("delete")
      .validate(updatedEvent)
      .then(() => {
        onSessionChange(
          { ...session, ParentEvent: updatedEvent },
          "Delete",
          false,
          true,
        );
      })
      .catch((error) => {
        enqueueSnackbar(SnackbarMessage.InvalidEventError, {
          variant: "error",
        });

        LoggerService.logError(
          "Invalid event error occured on:",
          `Error: ${error}, Data: ${JSON.stringify(updatedEvent)}`,
        );
      });
  };

  return (
    <Card variant="outlined">
      {isMobile && (
        <CardHeader
          action={
            isAdmin && (
              <MobileActionsToolbar
                selectedSession={session}
                onEdit={() => handleEdit()}
                onDelete={() => handleDelete(session)}
                onCanceled={() => handleCanceled(session)}
                onDelay={() => handleDelay()}
                showDelayMenu={showDelayMenu}
              />
            )
          }
          title={isMobile && SessionName(session, showSessionType)}
          titleTypographyProps={{
            variant: "h6",
          }}
          subheader={
            isMobile &&
            SessionTime(session.ParentEvent, session, showSessionType)
          }
          subheaderTypographyProps={{
            color: "inherit",
          }}
          avatar={StatusDot(session)}
          {...(shouldHaveScrollRef
            ? {
                ref: scrollRef,
              }
            : undefined)}
        />
      )}
      <CardContent
        sx={{
          width: "100%",
          paddingBottom: !isMobile ? "0.5rem !important" : "unset",
        }}
      >
        <Grid container alignItems="center">
          {!isMobile && (
            <>
              <Grid
                item
                lg={2}
                md={isTablet ? 4 : 2}
                {...(shouldHaveScrollRef
                  ? {
                      ref: scrollRef,
                    }
                  : undefined)}
              >
                <Stack direction="row">
                  {StatusDot(session)}
                  {SessionName(session, showSessionType)}
                </Stack>
                {isTablet &&
                  SessionTime(session.ParentEvent, session, showSessionType)}
              </Grid>
              {!isTablet && (
                <Grid item lg={2.5} md={3}>
                  {SessionTime(session.ParentEvent, session, showSessionType)}
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12} lg={6} md={5}>
            <EventDetails
              isEventView={isEventView ?? false}
              isMobile={isMobile ?? false}
              selectedEvent={session.ParentEvent}
              displaySponsors={false}
            />
          </Grid>
          {isAdmin && !isMobile && (
            <Grid item lg={1.5} md={2}>
              <Grid container flexWrap="nowrap" justifyContent="end">
                {!isTablet ? (
                  <DesktopActionsToolbar
                    selectedSession={session}
                    onEdit={() => handleEdit()}
                    onDelete={() => handleDelete(session)}
                    onCanceled={() => handleCanceled(session)}
                    onDelay={() => handleDelay()}
                    showDelayMenu={showDelayMenu}
                  />
                ) : (
                  <MobileActionsToolbar
                    selectedSession={session}
                    onEdit={() => handleEdit()}
                    onDelete={() => handleDelete(session)}
                    onCanceled={() => handleCanceled(session)}
                    onDelay={() => handleDelay()}
                    showDelayMenu={showDelayMenu}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
      {openDelayDialog && (
        <DelayDialog
          open={openDelayDialog}
          delayMinutes={delayMinutes}
          setDelayMinutes={setDelayMinutes}
          onCancel={cancelDelay}
          onClose={setDelay}
          activeSession={session}
          timeZone={session.ParentEvent.Site?.TimeZoneOffset as string}
        />
      )}
      {session && (
        <SessionDialog
          open={isSessionDialogOpen}
          onClose={handleCloseSessionDialog}
          selectedEvent={session.ParentEvent}
          existingSessions={session.ParentEvent.Sessions!}
          selectedSession={session}
          isUpdateMode={true}
          onUpdateSession={(updatedSession) => {
            onSessionChange(
              {
                ...updatedSession,
                ParentEvent: session.ParentEvent,
              },
              "Edit",
              false,
              false,
            );
          }}
        />
      )}
    </Card>
  );
};

const SessionName = (session: ISession, showSessionType: boolean) => {
  return (
    <>
      <Typography
        component="span"
        fontWeight="bolder"
        fontSize={{
          lg: 18,
          md: 16,
          sm: 16,
          xs: 16,
        }}
      >
        {session.Name}
        &nbsp;
      </Typography>
      <Typography
        component="span"
        fontSize={{
          lg: 18,
          md: 16,
          sm: 16,
          xs: 16,
        }}
      >
        {showSessionType && `(${session.SessionType})`}
      </Typography>
    </>
  );
};
