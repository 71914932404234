import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import { ViewOptions } from "../ListView/ListView";

export type LoadingSkeletonProps = {
  numberOfSkeletons: number;
  isMobile: boolean;
  listView: ViewOptions[];
};

export const LoadingSkeleton = (props: LoadingSkeletonProps) => {
  const skeletons = Array.from(
    { length: props.numberOfSkeletons },
    (v, index) => (
      <Skeleton
        variant="rounded"
        width={"100%"}
        height={
          !props.isMobile
            ? props.listView.includes(ViewOptions.Events)
              ? 84
              : 66
            : 180
        }
        key={index}
        sx={{
          border: "1px solid rgba(255, 255, 255, 0.12)",
        }}
      />
    ),
  );

  return (
    <Stack spacing={1} sx={{ margin: "0.5rem 0.5rem 1rem 0.5rem" }}>
      {skeletons}
    </Stack>
  );
};
