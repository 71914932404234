import { BaseEntity } from "./BaseEntity";
import { IEventSession } from "./IEventSessions";
import { ISeries } from "./ISeries";
import { ISite } from "./ISites";
import { ITimingScoring } from "./ITimingScoring";
import { IWeatherStation } from "./IWeatherStation";

export enum EventStatus {
  All = "All",
  Ok = "Ok",
  Delayed = "Delayed",
  Canceled = "Canceled",
}

export const EventType_Race = "Race";

export type IEventType = {
  Name: string;
  IsPublic: boolean;
  IsDisplayed: boolean;
  IsPublished: boolean;
  IsStored: boolean;
};

export type IEvent = {
  EventId?: string;
  SiteId: string;
  SeriesId: string;
  Name: string;
  ShortName: string;
  LogoBase64EncodedLight: string;
  LogoBase64EncodedDark: string;
  TimingScoringId: string;
  WeatherStationId: string;
  EventType: string;
  IsActive: boolean;
  Sessions?: IEventSession[];
  Site?: ISite;
  Series?: ISeries;
  WeatherStation?: IWeatherStation;
  TimingScoring?: ITimingScoring;
  SponsorIds?: string;
  AllowedRaceTeams?: string;
  EventCode?: string;
  AnnualSiteVisitCount?: number;
  WeatherStationIds: string;
} & BaseEntity;

export type Page<T> = {
  HasNextPage: boolean;
  Data: Array<T>;
  NextPage: number;
};
