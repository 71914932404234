import { Grid } from "@mui/material";
import { styled } from "styled-components";
import { DarkTheme, LightTheme } from "./Theme";

export const GridFlexCenter = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const boxButtonStyle = (
  theme: { palette: { mode: string } },
  isMobile: boolean,
) => {
  return {
    backgroundColor:
      theme.palette.mode === "dark"
        ? isMobile
          ? DarkTheme.mobileCardButtonBackground
          : DarkTheme.gridButtonBackground
        : isMobile
          ? LightTheme.mobileCardButtonBackground
          : LightTheme.gridButtonBackground,
    outline:
      theme.palette.mode === "dark"
        ? isMobile
          ? `${DarkTheme.mobileCardButtonOutline} solid 0.1rem`
          : `${DarkTheme.gridButtonOutline} solid 0.1rem`
        : isMobile
          ? `${LightTheme.mobileCardButtonOutline} solid 0.1rem`
          : `${LightTheme.gridButtonOutline} solid 0.1rem`,
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? DarkTheme.hoverBackgroundColor
          : LightTheme.hoverBackgroundColor,
      outline:
        theme.palette.mode === "dark"
          ? `${DarkTheme.hoverOutlineColor} solid 0.1rem`
          : `${LightTheme.hoverOutlineColor} solid 0.1rem`,
    },
  };
};
