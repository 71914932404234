import { useEffect, useState } from "react";
import { useSitesWithSeries } from "../../Services/LookupService";
import { ValueSelect } from "./ValueSelect";
import { ISite } from "../../Interfaces";

export type SiteSelectProps = {
  selectedSite: string | null;
  onSiteChange: (value: string) => void;
  onFocus?: () => void;
  showLabel?: boolean;
  defaultText?: string;
  selectedSeries?: string;
};

export const SiteSelect = ({
  selectedSite,
  onSiteChange,
  onFocus,
  showLabel,
  defaultText,
  selectedSeries,
}: SiteSelectProps) => {
  const [seriesSites, setSeriesSites] = useState<ISite[]>();
  const { data: siteData } = useSitesWithSeries();

  useEffect(() => {
    if (siteData) {
      let filteredSites = [];
      if (selectedSeries) {
        filteredSites = siteData.filter((s) => {
          if (
            s.Series.some(
              (se) =>
                se.SeriesId.toLowerCase() === selectedSeries.toLowerCase(),
            )
          )
            return s;
          return undefined;
        });
      } else {
        filteredSites = siteData;
      }
      setSeriesSites(filteredSites);
    }
  }, [siteData, selectedSeries]);

  return (
    <>
      {siteData && (
        <ValueSelect
          labelText="Site"
          showLabel={showLabel}
          defaultText={defaultText}
          onValueChange={onSiteChange}
          onFocus={onFocus}
          data={seriesSites}
          namePropName="Name"
          valuePropName="SiteId"
          selectedValue={selectedSite}
        />
      )}
    </>
  );
};
