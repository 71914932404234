import { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Divider,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useWeatherStations } from "../../Services/LookupService";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { IWeatherStation } from "../../Interfaces";
import { DarkTheme, LightTheme } from "../App/Theme";
import { useMediaQuery } from "@material-ui/core";

const reorder = (
  list: { id: string; text: string }[],
  startIndex: number,
  endIndex: number,
): { id: string; text: string }[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1) as [
    { id: string; text: string },
  ];
  result.splice(endIndex, 0, removed);
  return result;
};

export type WeatherStationSelectProps = {
  selectedSeries: string;
  selectedWeatherStations: string | null;
  onFocus?: () => void;
  showLabel?: boolean;
  onSelectionChange: (selectedWeatherStations: string[]) => void;
};

export const SortableList = ({
  selectedSeries,
  selectedWeatherStations,
  onFocus = undefined,
  showLabel = false,
  onSelectionChange,
}: WeatherStationSelectProps) => {
  const theme = useTheme();
  const { data: weatherStationData } = useWeatherStations(selectedSeries);
  const [items, setItems] = useState<{ id: string; text: string }[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (weatherStationData) {
      const updatedItems = weatherStationData.map((station) => ({
        id: station.WeatherStationId,
        text: station.Name,
      }));
      setItems(updatedItems);
      // Set all WeatherStationId values to selectedItems during initial load
      if (selectedWeatherStations === "") {
        const allIds = updatedItems.map((item) => item.id);
        setSelectedItems(allIds);
        onSelectionChange(allIds); // Trigger onSelectionChange with all ids
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherStationData]);

  useEffect(() => {
    const parsedSelectedItems = Array.isArray(selectedWeatherStations)
      ? selectedWeatherStations
      : typeof selectedWeatherStations === "string"
        ? selectedWeatherStations
            .split(",")
            .filter((station) => station.trim() !== "")
        : [];

    setSelectedItems(parsedSelectedItems);

    if (weatherStationData && typeof selectedWeatherStations === "string") {
      const rearrangedData = rearrangeObjectCollection(
        weatherStationData,
        selectedWeatherStations,
      );
      const RearrangedItems = rearrangedData.map((station) => ({
        id: station.WeatherStationId,
        text: station.Name,
      }));
      setItems(RearrangedItems);
    }
  }, [selectedWeatherStations, weatherStationData]);

  function rearrangeObjectCollection(
    objectCollection: IWeatherStation[],
    orderString: string,
  ): IWeatherStation[] {
    const orderArray = orderString.split(",");

    // Create a mapping of WeatherStationId to IWeatherStation
    const stationMap = Object.fromEntries(
      objectCollection.map((station) => [station.WeatherStationId, station]),
    );

    // Rearrange the array based on orderString
    const rearrangedCollection = orderArray
      .map((stationId) => stationMap[stationId])
      .filter(Boolean); // Filter out undefined values

    // Include items not present in orderArray at the end
    const remainingItems = objectCollection.filter(
      (station) => !orderArray.includes(station.WeatherStationId),
    );
    rearrangedCollection.push(...remainingItems);

    return rearrangedCollection;
  }

  const moveItem = (currentIndex: number, newIndex: number) => {
    const updatedItems = reorder(items, currentIndex, newIndex);
    setItems(updatedItems);

    // Update selectedItems based on the new order
    setSelectedItems((prevSelectedItems) => {
      const updatedSelectedItems = updatedItems
        .filter((item, index) => prevSelectedItems.includes(item.id))
        .map((item) => item.id);

      onSelectionChange(updatedSelectedItems);
      return updatedSelectedItems;
    });
  };
  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    moveItem(result.source.index, result.destination.index);
  };

  const toggleSelection = (itemId: string) => {
    setSelectedItems((prevSelectedItems) => {
      const isItemSelected = prevSelectedItems.includes(itemId);
      let newSelectedItems;

      if (isItemSelected) {
        newSelectedItems = prevSelectedItems.filter((id) => id !== itemId);
      } else {
        // Find the index of the item in the items array
        const itemIndex = items.findIndex((item) => item.id === itemId);

        // Insert the item at the correct position in the selectedItems array
        newSelectedItems = [
          ...prevSelectedItems.slice(0, itemIndex),
          itemId,
          ...prevSelectedItems.slice(itemIndex),
        ];
      }

      onSelectionChange(newSelectedItems);
      return newSelectedItems;
    });
  };
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <>
      {showLabel && selectedSeries && <InputLabel>Weather Stations</InputLabel>}
      {!selectedSeries && (
        <>
          <label>Weather Station</label>
          <Skeleton
            variant="rounded"
            aria-label="Weather Stations not available until Series selection"
            placeholder="Weather Station"
          />
        </>
      )}

      {selectedSeries && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided) => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  border: `1px solid ${isFocused ? "#1976d2" : "#ccc"}`,
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListItem style={{ cursor: "grab" }}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: isSmallScreen ? "column" : "row",
                            }}
                          >
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <IconButton
                                size="small"
                                onClick={() => moveItem(index, index - 1)}
                                disabled={index === 0}
                              >
                                <ArrowUpwardIcon />
                              </IconButton>
                              <ListItemText
                                primary={
                                  <div
                                    style={{
                                      width: isSmallScreen ? "95%" : "100%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {item.text}
                                  </div>
                                }
                                style={{
                                  color: selectedItems.includes(item.id)
                                    ? theme.palette.mode === "dark"
                                      ? DarkTheme.fontColor
                                      : LightTheme.fontColor
                                    : "gray",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                marginLeft: isSmallScreen ? "0rem" : "1rem",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                size="small"
                                onClick={() => moveItem(index, index + 1)}
                                disabled={index === items.length - 1}
                              >
                                <ArrowDownwardIcon />
                              </IconButton>
                              <Switch
                                defaultChecked
                                checked={selectedItems.includes(item.id)}
                                onChange={() => toggleSelection(item.id)}
                                size="small"
                                style={{ marginLeft: "auto" }}
                              />
                              <Typography variant="body2">
                                {selectedItems.includes(item.id)
                                  ? "Enabled"
                                  : "Disabled"}
                              </Typography>
                            </div>
                          </div>
                        </ListItem>
                        {index !== items.length - 1 && <Divider />}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};
