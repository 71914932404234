import { Button, SxProps, Theme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export type DeleteButtonProps = {
  entityId?: string;
  onDelete: (event: React.MouseEvent, entityId?: string) => void;
  sx?: SxProps<Theme>;
};

export const DeleteButton = ({ onDelete, entityId, sx }: DeleteButtonProps) => {
  return (
    <Button
      variant="text"
      sx={sx}
      title="Delete"
      onClick={(event) => onDelete(event, entityId)}
      color="error"
    >
      <DeleteIcon
        tabIndex={0}
        fontSize="small"
        sx={{
          position: "relative",
          bottom: "0.125rem",
          padding: "0.25rem",
        }}
      />
      Delete Event
    </Button>
  );
};
