import { ReactComponent as NascarLight } from "../../Assets/Logos/LightTheme/nascar.svg";
import { ReactComponent as NascarDark } from "../../Assets/Logos/DarkTheme/nascar.svg";
import { ReactComponent as IMSAWecLight } from "../../Assets/Logos/LightTheme/imsa_wec.svg";
import { ReactComponent as IMSAWecDark } from "../../Assets/Logos/DarkTheme/imsa_wec.svg";
import { ReactComponent as NascarCupLight } from "../../Assets/Logos/LightTheme/nascar_cup.svg";
import { ReactComponent as NascarCupDark } from "../../Assets/Logos/DarkTheme/nascar_cup.svg";
import { ReactComponent as NascarXfinityLight } from "../../Assets/Logos/LightTheme/nascar_xfinity.svg";
import { ReactComponent as NascarXfinityDark } from "../../Assets/Logos/DarkTheme/nascar_xfinity.svg";
import { ReactComponent as NascarCraftsmanTruckLight } from "../../Assets/Logos/LightTheme/nascar_truck.svg";
import { ReactComponent as NascarCraftsmanTruckDark } from "../../Assets/Logos/DarkTheme/nascar_truck.svg";
import { ReactComponent as Formula1Light } from "../../Assets/Logos/LightTheme/formula1.svg";
import { ReactComponent as Formula1Dark } from "../../Assets/Logos/DarkTheme/formula1.svg";
import { ReactComponent as NTTIndyCarLight } from "../../Assets/Logos/LightTheme/indycar.svg";
import { ReactComponent as NTTIndyCarDark } from "../../Assets/Logos/DarkTheme/indycar.svg";
import { ReactComponent as IMSALight } from "../../Assets/Logos/LightTheme/imsa.svg";
import { ReactComponent as IMSADark } from "../../Assets/Logos/DarkTheme/imsa.svg";
import { ReactComponent as WECLight } from "../../Assets/Logos/LightTheme/wec.svg";
import { ReactComponent as WECDark } from "../../Assets/Logos/DarkTheme/wec.svg";
import { SeriesName } from "../../Interfaces/SeriesName";
import { Theme } from "../../Interfaces/Theme";

export const getSeriesLogoSrc = (
  theme: string,
  seriesName: SeriesName,
  sx?: any,
  onClick?: () => any,
) => {
  const seriesNameToUpper = seriesName.toUpperCase();

  const style = !sx
    ? {
        maxWidth: "100%",
        width: "auto",
        height: "auto",
        maxHeight: "4rem",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        margin: "auto",
      }
    : sx;

  switch (seriesNameToUpper) {
    case SeriesName.Nascar:
      return theme === Theme.Light ? (
        <NascarLight aria-label="Nascar" onClick={onClick} style={style} />
      ) : (
        <NascarDark aria-label="Nascar" onClick={onClick} style={style} />
      );
    case SeriesName.NascarCup:
      return theme === Theme.Light ? (
        <NascarCupLight
          aria-label="Nascar Cup Series"
          onClick={onClick}
          style={style}
        />
      ) : (
        <NascarCupDark
          aria-label="Nascar Cup Series"
          onClick={onClick}
          style={style}
        />
      );
    case SeriesName.NascarXfinity:
      return theme === Theme.Light ? (
        <NascarXfinityLight
          aria-label="Nascar Xfinity Series"
          onClick={onClick}
          style={style}
        />
      ) : (
        <NascarXfinityDark
          aria-label="Nascar Xfinity Series"
          onClick={onClick}
          style={style}
        />
      );
    case SeriesName.NascarCraftsmanTruck:
      return theme === Theme.Light ? (
        <NascarCraftsmanTruckLight
          aria-label="Nascar Truck Series"
          onClick={onClick}
          style={style}
        />
      ) : (
        <NascarCraftsmanTruckDark
          aria-label="Nascar Truck Series"
          onClick={onClick}
          style={style}
        />
      );
    case SeriesName.Formula1:
      return theme === Theme.Light ? (
        <Formula1Light aria-label="Formula 1" onClick={onClick} style={style} />
      ) : (
        <Formula1Dark aria-label="Formula 1" onClick={onClick} style={style} />
      );
    case SeriesName.NTTIndyCar:
      return theme === Theme.Light ? (
        <NTTIndyCarLight
          aria-label="Indy Car"
          onClick={onClick}
          style={style}
        />
      ) : (
        <NTTIndyCarDark aria-label="Indy Car" onClick={onClick} style={style} />
      );
    case SeriesName.IMSA_WEC:
      return theme === Theme.Light ? (
        <IMSAWecLight aria-label="IMSA / WEC" onClick={onClick} style={style} />
      ) : (
        <IMSAWecDark aria-label="IMSA / WEC" onClick={onClick} style={style} />
      );
    case SeriesName.IMSA:
      return theme === Theme.Light ? (
        <IMSALight aria-label="IMSA" onClick={onClick} style={style} />
      ) : (
        <IMSADark aria-label="IMSA" onClick={onClick} style={style} />
      );
    case SeriesName.WEC:
      return theme === Theme.Light ? (
        <WECLight aria-label="WEC" onClick={onClick} style={style} />
      ) : (
        <WECDark aria-label="WEC" onClick={onClick} style={style} />
      );
  }
};
