import { useEffect, useState } from "react";
import moment, { Moment } from "moment-timezone";
import { Grid } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { StyledDatePicker } from "../ListEvents/EventListStyles";

export type DateRangePickerProps = {
  selectedDates: [Moment | null | undefined, Moment | null | undefined] | null;
  onDateChange: (newValues: Array<Moment | undefined> | null) => void;
  isMobile: boolean;
};

export const DateRangePicker = ({
  selectedDates,
  onDateChange,
  isMobile,
}: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState<Moment | null | undefined>(
    selectedDates ? selectedDates[0] : undefined,
  );
  const [endDate, setEndDate] = useState<Moment | null | undefined>(
    selectedDates ? selectedDates[1] : undefined,
  );

  useEffect(() => {
    if (selectedDates) {
      setStartDate(selectedDates[0] ? moment.utc(selectedDates[0]) : undefined);
      setEndDate(selectedDates[1] ? moment.utc(selectedDates[1]) : undefined);
    }
  }, [selectedDates]);

  const handleDateChange = (which: string, value: Moment | null) => {
    const currentStart = startDate ? startDate : undefined;
    const currentEnd = endDate ? endDate : undefined;
    const utcValue = value ? moment.utc(value) : undefined;
    switch (which) {
      case "start":
        setStartDate(utcValue);
        onDateChange([utcValue, currentEnd]);
        break;
      case "end":
        setEndDate(utcValue);
        onDateChange([currentStart, utcValue]);
        break;
    }
  };

  const handleDisablingStartDate = (day: Moment) => {
    if (!endDate) return false;
    const disabledAfterDay = moment.utc(endDate);
    return day.isAfter(disabledAfterDay) || day.isSame(disabledAfterDay);
  };

  const handleDisablingEndDate = (day: Moment) => {
    if (!startDate) return false;
    const disableBeforeDay = moment.utc(startDate);
    return day.isBefore(disableBeforeDay) || day.isSame(disableBeforeDay);
  };

  return (
    <StyledDatePicker>
      <Grid
        container
        display="flex"
        alignItems="center"
        textAlign="center"
        justifyContent={isMobile ? "center" : "end"}
        margin={isMobile ? "1rem 0rem" : "0rem"}
        gap="0.75rem"
        sx={{
          "@media (max-width: 425px)": {
            padding: "0rem",
            margin: "0rem",
          },
        }}
      >
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment}
        >
          <Grid item xs={5.5}>
            <DatePicker
              label="From"
              format="MM/DD/YYYY"
              value={startDate}
              onChange={(value) => handleDateChange("start", value)}
              slotProps={{
                field: {
                  clearable: true,
                },
                actionBar: {
                  actions: ["today", "accept", "clear"],
                },
              }}
              sx={{
                marginTop: "0.5rem",
              }}
              shouldDisableDate={handleDisablingStartDate}
              timezone={moment.tz.guess()}
            />
          </Grid>
          <Grid item xs={5.5}>
            <DatePicker
              label="To"
              format="MM/DD/YYYY"
              value={endDate}
              onChange={(value) => handleDateChange("end", value)}
              slotProps={{
                field: {
                  clearable: true,
                },
                actionBar: {
                  actions: ["today", "accept", "clear"],
                },
              }}
              sx={{
                marginTop: "0.5rem",
              }}
              shouldDisableDate={handleDisablingEndDate}
              timezone={moment.tz.guess()}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
    </StyledDatePicker>
  );
};
