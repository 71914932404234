import { Typography, Grid } from "@mui/material";
import { IEvent, IEventSession } from "../../Interfaces";
import moment from "moment-timezone";

const startTimeFormat = "ddd, MMM D h:mma";
const startTimeFormatWithTimeZone = "ddd, MMM D h:mma z";
const endTimeFormat = "h:mma z";

export const computeSessionTimeDisplayWithTimeZone = (
  session: IEventSession,
  timeZone: string,
) => {
  if (timeZone) {
    const startTime = moment(session.StartTime)
      .tz(timeZone)
      .format(session.EndTime ? startTimeFormat : startTimeFormatWithTimeZone);

    const endTime = session.EndTime
      ? moment(session.EndTime).tz(timeZone).format(endTimeFormat)
      : "";

    return endTime === "" ? startTime : `${startTime}-${endTime}`;
  }
};

export const SessionTime = (
  parentEvent: IEvent,
  session: IEventSession,
  showSiteTime: boolean,
) => {
  const parentEventTimeZone = parentEvent.Site?.TimeZoneOffset as string;
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <>
      <Grid item>
        <Typography variant="body2" noWrap>
          {showSiteTime
            ? `Site: ${computeSessionTimeDisplayWithTimeZone(
                session,
                parentEventTimeZone,
              )}`
            : `Local: ${computeSessionTimeDisplayWithTimeZone(
                session,
                userTimeZone,
              )}`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption" noWrap>
          {showSiteTime
            ? `Local: ${computeSessionTimeDisplayWithTimeZone(
                session,
                userTimeZone,
              )}`
            : `Site: ${computeSessionTimeDisplayWithTimeZone(
                session,
                parentEventTimeZone,
              )}`}
        </Typography>
      </Grid>
    </>
  );
};

export const SessionText = (
  session: IEventSession,
  showSessionType: boolean,
) => {
  return (
    <Grid item zeroMinWidth>
      <Typography
        variant="body1"
        component="span"
        noWrap
        title={session.Name}
        fontWeight="bold"
      >
        {session.Name}
      </Typography>
      &nbsp;
      {showSessionType && (
        <Typography
          variant="body1"
          component="span"
          noWrap
          title={session.SessionType}
        >
          ({session.SessionType})
        </Typography>
      )}
    </Grid>
  );
};
