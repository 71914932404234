import React, { ChangeEvent, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import * as yup from "yup";

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import {
  EventType_Race,
  IEvent,
  IEventSession,
  IRaceTeam,
  ISponsor,
} from "../../Interfaces";
import { SnackbarMessage } from "../../Interfaces/SnackbarMessages";
import { getShortNameExistence } from "../../Services/EventService";
import { LoggerService } from "../../Services/LoggerService";
import {
  getRaceTeams,
  getSiteVisitCount,
  useSeries,
  useSites,
} from "../../Services/LookupService";
import {
  sortSessions,
  useDeleteSessionById,
} from "../../Services/SessionService";
import { eventValidationSchema } from "../../Validations/AddEvent.Yup";
import { SessionList } from "../ListSessions/SessionList";
import { DeleteIconButton } from "../Shared/DeleteIconButton";
import { RaceTeamSelect } from "../Shared/RaceTeamSelect";
import { SeriesSelect } from "../Shared/SeriesSelect";
import { SiteSelect } from "../Shared/SiteSelect";
import { SponsorSelect } from "../Shared/SponsorSelect";
import { TimingAndScoringSelect } from "../Shared/TimingAndScoringSelect";
import { SortableList } from "../Shared/WeatherStationSortableSelect";
import {
  StyledDialog,
  StyledErrorMessage,
  StyledFormHelper,
} from "./EventFormDialog.styles";
import { EventTypeSelect } from "../Shared/EventTypeSelect";

interface EventDialogProps {
  sponsors: ISponsor[] | undefined;
  isAddEventFormOpen: boolean;
  isEditMode: boolean;
  isMobile: boolean;
  isTablet: boolean;
  onClose: () => void;
  onCancel: () => void;
  selectedEvent: IEvent | null;
  onAddEvent: (eventData: IEvent) => void;
  onUpdateEvent: (eventData: IEvent) => void;
  onDeleteEvent: (eventData: IEvent) => void;
}

const initialValidationErrors = {
  Name: "",
  ShortName: "",
  SeriesId: "",
  SiteId: "",
  TimingScoringId: "",
  WeatherStationId: "",
  Sessions: "",
  Sponsors: "",
  AllowedRaceTeams: "",
  WeatherStationIds: "",
};

const initialEvent: IEvent = {
  EventId: uuidv4(),
  Name: "",
  ShortName: "",
  EventType: EventType_Race,
  LogoBase64EncodedLight: "",
  LogoBase64EncodedDark: "",
  SeriesId: "",
  SiteId: "",
  TimingScoringId: "",
  WeatherStationId: "6e42481c-7ac0-4c65-894b-1269e562f644", // Temporarly keeping a value for managing FK constraint. This will be removed with a PR for removing WeatherStationId
  SponsorIds: "",
  AllowedRaceTeams: "",
  Sessions: new Array<IEventSession>(),
  IsActive: true,
  CreatedOn: new Date(Date.now()),
  UpdatedOn: new Date(Date.now()),
  EventCode: "",
  AnnualSiteVisitCount: 0,
  WeatherStationIds: "",
};

export const EventDialog: React.FC<EventDialogProps> = ({
  sponsors,
  isAddEventFormOpen,
  isEditMode,
  isMobile,
  isTablet,
  selectedEvent,
  onCancel,
  onClose,
  onAddEvent,
  onUpdateEvent,
  onDeleteEvent,
}) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [selectedDarkLogoFile, setSelectedDarkLogoFile] = useState({
    fileName: "",
    fileURL: "",
  });
  const [selectedLightLogoFile, setSelectedLightLogoFile] = useState({
    fileName: "",
    fileURL: "",
  });
  const [validationErrors, setValidationErrors] = useState(
    initialValidationErrors,
  );
  const [sessionIdsToDelete, setSessionIdsToDelete] = useState<string[]>([]);
  const [canUpdateEventCode, setCanUpdateEventCode] = useState(false);

  const [activeEvent, setActiveEvent] = useState<IEvent>(
    selectedEvent ?? initialEvent,
  );

  const queryClient = useQueryClient();
  const useRaceTeams = (seriesId: string) => {
    return useQuery<IRaceTeam[]>(
      ["raceteams", seriesId],
      () => getRaceTeams(seriesId),
      {
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24,
        enabled: activeEvent.SeriesId !== "",
      },
    );
  };
  const { data: raceTeams } = useRaceTeams(activeEvent.SeriesId);
  const [siteCode, setSiteCode] = useState<string>();

  const [seriesCode, setSeriesCode] = useState<string>();
  const { data: sites } = useSites();
  const { data: series } = useSeries();
  const {
    error: deleteSessionError,
    isLoading: deleteSessionLoading,
    isSuccess: deleteSessionSuccess,
    mutate: deleteSession,
  } = useDeleteSessionById();
  const [selectedWeatherStations, setSelectedWeatherStations] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (selectedEvent) {
      setSeriesCode(selectedEvent.Series?.SeriesCode);
      setSiteCode(selectedEvent.Site?.SiteCode);
      setActiveEvent(selectedEvent);
      setSelectedDarkLogoFile({
        fileName: "",
        fileURL: selectedEvent.LogoBase64EncodedDark,
      });
      setSelectedLightLogoFile({
        fileName: "",
        fileURL: selectedEvent.LogoBase64EncodedLight,
      });
    } else {
      setActiveEvent({ ...initialEvent, EventId: uuidv4() });
      setSelectedDarkLogoFile({ fileName: "", fileURL: "" });
      setSelectedLightLogoFile({ fileName: "", fileURL: "" });
      setValidationErrors(initialValidationErrors);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (
      (activeEvent.Sessions && !activeEvent?.Sessions.length) ||
      !canUpdateEventCode
    ) {
      return;
    }
    const activeEventDate = new Date(
      activeEvent?.Sessions && activeEvent?.Sessions[0].StartTime
        ? activeEvent.Sessions[0].StartTime
        : "",
    );
    const selectedEventdate = new Date(
      selectedEvent?.Sessions && selectedEvent?.Sessions[0].StartTime
        ? selectedEvent.Sessions[0].StartTime
        : "",
    );
    // Check if the date is valid
    if (isNaN(activeEventDate.getTime())) {
      console.error("Invalid date string");
    }

    const year = activeEventDate.getFullYear();

    try {
      getSiteVisitCount(activeEvent.SiteId, activeEvent.SeriesId, year).then(
        (siteVisitCount) => {
          if (siteVisitCount === -1) {
            console.error("Error getting site visit count");
          }
          var eventCode = "";
          if (siteCode && year && seriesCode && siteVisitCount >= 0) {
            siteVisitCount = siteVisitCount + 1;
            eventCode =
              selectedEvent?.SeriesId.toLowerCase() ===
                activeEvent?.SeriesId.toLowerCase() &&
              selectedEvent?.SiteId.toLowerCase() ===
                activeEvent.SiteId.toLowerCase() &&
              year === selectedEventdate.getFullYear() &&
              selectedEvent.EventCode
                ? selectedEvent.EventCode
                : (year % 100) + siteCode + siteVisitCount + "-" + seriesCode;
            setActiveEvent((prevActiveEvent) => ({
              ...prevActiveEvent,
              EventCode: eventCode,
              AnnualSiteVisitCount: siteVisitCount,
            }));
          }
        },
      );
    } catch (error) {
      console.error("Error generating event code", error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeEvent.Sessions, siteCode, seriesCode, canUpdateEventCode]);

  useEffect(() => {
    if (raceTeams && !activeEvent.AllowedRaceTeams) {
      const allowedRaceTeams =
        raceTeams && EventType_Race === activeEvent.EventType
          ? raceTeams
              .filter((rt) => rt.ReceivesOnTrackEvents)
              .map((rt) => rt.RaceTeamId)
              .toString()
          : "";
      setActiveEvent((actEvt) => {
        return {
          ...actEvt,
          AllowedRaceTeams: allowedRaceTeams,
        };
      });
    } else {
      setActiveEvent((actEvt) => {
        return {
          ...actEvt,
          AllowedRaceTeams: actEvt.AllowedRaceTeams,
        };
      });
    }
  }, [activeEvent.AllowedRaceTeams, activeEvent.EventType, raceTeams]);

  useEffect(() => {
    // Delete session
    if (deleteSessionLoading) {
      enqueueSnackbar(SnackbarMessage.DeleteSessionLoading, {
        variant: "info",
      });
    }
    if (deleteSessionSuccess) {
      enqueueSnackbar(SnackbarMessage.DeleteSessionSuccess, {
        variant: "success",
      });
    }
    if (deleteSessionError) {
      enqueueSnackbar(SnackbarMessage.DeleteSessionError, {
        variant: "error",
      });

      LoggerService.logError(
        "Delete session error occured:",
        deleteSessionError?.message,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSessionError, deleteSessionLoading, deleteSessionSuccess]);

  useEffect(() => {
    // Print the selected weather stations whenever they change
    console.log("Selected Weather Stations:", selectedWeatherStations);
  }, [selectedWeatherStations]);

  const handleCancel = () => {
    setActiveEvent({ ...initialEvent, EventId: uuidv4() });
    setSelectedDarkLogoFile({ fileName: "", fileURL: "" });
    setSelectedLightLogoFile({ fileName: "", fileURL: "" });
    setSeriesCode("");
    setSiteCode("");
    setCanUpdateEventCode(false);
    setValidationErrors(initialValidationErrors);
    queryClient.cancelQueries("raceteams");
    onCancel();
  };

  const handleAddOrUpdateEvent = async () => {
    const eventData: IEvent = {
      ...(activeEvent as IEvent),
    };

    eventData.EventCode
      ? setCanUpdateEventCode(false)
      : setCanUpdateEventCode(true);
    LoggerService.logInfo(
      "handleAddOrUpdateEvent()",
      "eventToSave:",
      eventData,
    );
    eventValidationSchema()
      .validate(eventData, { abortEarly: false })
      .then(async () => {
        const shortNameExists = await checkShortNameExists(
          eventData.ShortName,
          eventData.SeriesId,
          eventData.EventId,
        );
        if (Object.keys(shortNameExists).length !== 0) {
          setValidationErrors((prevErrors) => ({
            ...prevErrors,
            ShortName: "Short Name must be unique within the series",
          }));
          return;
        }
        isEditMode ? onUpdateEvent(eventData) : onAddEvent(eventData);
        if (sessionIdsToDelete.length > 0) {
          sessionIdsToDelete.forEach((s) => deleteSession(s));
        }
        setActiveEvent({
          ...initialEvent,
          EventId: uuidv4(),
          LogoBase64EncodedLight: "",
          LogoBase64EncodedDark: "",
        });
        setSelectedLightLogoFile({ fileName: "", fileURL: "" });
        setSelectedDarkLogoFile({ fileName: "", fileURL: "" });
        onClose();
      })
      .catch((error: any) => {
        const newValidationErrors = { ...initialValidationErrors };

        error.inner.forEach((validationError: yup.ValidationError) => {
          newValidationErrors[
            validationError.path as keyof typeof newValidationErrors
          ] = validationError.message;
        });

        setValidationErrors(newValidationErrors);
      });
  };

  const checkShortNameExists = async (
    shortName: string,
    seriesId: string,
    eventId: string | undefined,
  ): Promise<boolean> => {
    const isShortNameExists = await getShortNameExistence(
      shortName,
      seriesId,
      eventId,
    );
    return isShortNameExists;
  };

  const handleDeleteEvent = () => {
    if (!selectedEvent) return;
    onDeleteEvent(selectedEvent);
  };

  const clearError = (field: string) => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleNameBlur = () => {
    eventValidationSchema()
      .validateAt("Name", {
        Name: activeEvent.Name,
      })
      .then(() => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          Name: "",
        }));
      })
      .catch((error) => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          Name: error.message,
        }));
      });
  };

  const handleShortNameBlur = () => {
    eventValidationSchema()
      .validateAt("ShortName", {
        ShortName: activeEvent.ShortName,
      })
      .then(() => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          ShortName: "",
        }));
      })
      .catch((error) => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          ShortName: error.message,
        }));
      });
  };

  const handleLogoDelete = (isLogoLight: boolean) => {
    if (isLogoLight) {
      setSelectedLightLogoFile({ fileName: "", fileURL: "" });
      setActiveEvent({
        ...activeEvent,
        LogoBase64EncodedLight: "",
      });
    } else {
      setSelectedDarkLogoFile({ fileName: "", fileURL: "" });
      setActiveEvent({
        ...activeEvent,
        LogoBase64EncodedDark: "",
      });
    }
  };

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    isLogoLight: boolean,
  ) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result as string;
        if (isLogoLight) {
          setSelectedLightLogoFile({
            fileName: file.name,
            fileURL: base64String,
          });
          setActiveEvent({
            ...activeEvent,
            LogoBase64EncodedLight: base64String,
          });
        } else {
          setSelectedDarkLogoFile({
            fileName: file.name,
            fileURL: base64String,
          });

          setActiveEvent({
            ...activeEvent,
            LogoBase64EncodedDark: base64String,
          });
        }
      };
    }
    clearError("logo");
  };

  const handleWeatherStationSelectionChange = (
    selectedWeatherStations: string[],
  ) => {
    // Do something with the selectedWeatherStations
    setSelectedWeatherStations(selectedWeatherStations);
    const updatedEvent: IEvent = {
      ...activeEvent,
      WeatherStationIds: selectedWeatherStations
        .filter((station) => station.trim() !== "")
        .join(","),
    };
    setActiveEvent(updatedEvent);
    console.log(selectedWeatherStations);
  };

  const handleChange = async (fieldName: string, value: string) => {
    const updatedEvent: IEvent = {
      ...activeEvent,
      [fieldName]: value.trimStart(),
    };

    switch (fieldName) {
      case "EventType":
        updatedEvent.SeriesId = "";
        updatedEvent.AllowedRaceTeams = "";
        break;
      case "SeriesId":
        var seriesCode = series?.find(
          (s) => s.SeriesId.toLowerCase() === value.toLowerCase(),
        )?.SeriesCode;
        setSeriesCode(seriesCode);
        updatedEvent.SiteId = "";
        updatedEvent.Site = undefined;
        updatedEvent.TimingScoringId = "";
        updatedEvent.TimingScoring = undefined;
        updatedEvent.WeatherStationId = "6e42481c-7ac0-4c65-894b-1269e562f644"; // Temporarly keeping a value for managing FK constraint. This will be removed with a PR for removing WeatherStationId
        updatedEvent.WeatherStation = undefined;
        updatedEvent.AllowedRaceTeams = "";
        updatedEvent.EventCode = "";
        updatedEvent.WeatherStationIds = "";
        setCanUpdateEventCode(true);
        break;
      case "SiteId":
        const selectedSite = sites?.find(
          (site) => site.SiteId.toLowerCase() === value.toLowerCase(),
        );
        setSiteCode(selectedSite?.SiteCode);
        updatedEvent.Site = selectedSite;
        setCanUpdateEventCode(true);
        break;
      default:
        break;
    }
    setActiveEvent(updatedEvent);
  };

  const handleSessionsUpdated = (updatedSessions: IEventSession[]) => {
    updatedSessions.sort(sortSessions);
    const updatedEvent = {
      ...activeEvent,
      Sessions: updatedSessions,
    };
    setActiveEvent(updatedEvent);
    clearError("sessions");
  };

  const handleSessionDeleted = (sessionIdToDelete: string) => {
    const deletedSessions = sessionIdsToDelete.map((s) => s);
    deletedSessions.push(sessionIdToDelete);
    setSessionIdsToDelete(deletedSessions);
  };

  const handleSponsorsChange = (sponsorNames: string[]) => {
    let selectedSponsorIds: string[] = [];

    sponsorNames.forEach((sponsorName) => {
      const sponsor = sponsors?.find((s) => s.Name === sponsorName);

      if (sponsor) {
        selectedSponsorIds.push(sponsor.SponsorId);
      }
    });

    if (selectedSponsorIds) {
      const updatedEvent = {
        ...activeEvent,
        SponsorIds: selectedSponsorIds.toString(),
      };
      setActiveEvent(updatedEvent);
    }
  };

  return (
    <>
      {activeEvent && (
        <StyledDialog
          open={isAddEventFormOpen}
          onClose={handleCancel}
          fullScreen={fullScreen}
          maxWidth="md"
        >
          <DialogTitle paddingLeft="1.563rem">
            {isEditMode ? "Update Event" : "Add Event"}
          </DialogTitle>
          <DialogContent style={{ padding: "1.25rem 1.875rem" }}>
            <Grid
              container
              direction="column"
              justifyContent="left"
              alignItems="left"
              rowSpacing={3}
            >
              <Grid item>
                {/* Event Name */}
                <TextField
                  label="Name"
                  variant="standard"
                  className="custom-select"
                  inputProps={{
                    maxLength: 100,
                  }}
                  value={activeEvent.Name}
                  onChange={(event: any) =>
                    handleChange("Name", event.target.value)
                  }
                  onFocus={() => clearError("Name")}
                  onBlur={handleNameBlur}
                  focused
                />
                {validationErrors.Name && (
                  <StyledErrorMessage>
                    {validationErrors.Name}
                  </StyledErrorMessage>
                )}
              </Grid>
              <Grid item>
                {/* Short Name */}
                <TextField
                  label="Short Name"
                  variant="standard"
                  className="custom-select"
                  inputProps={{
                    maxLength: 100,
                  }}
                  value={activeEvent.ShortName}
                  onChange={(event: any) =>
                    handleChange("ShortName", event.target.value)
                  }
                  onFocus={() => clearError("ShortName")}
                  onBlur={handleShortNameBlur}
                  focused
                />
                {validationErrors.ShortName && (
                  <StyledErrorMessage>
                    {validationErrors.ShortName}
                  </StyledErrorMessage>
                )}
              </Grid>
              {activeEvent.EventCode && (
                <Grid item>
                  {/* Event Code */}
                  <Typography
                    marginRight="1rem"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Event Code: {activeEvent.EventCode}
                  </Typography>
                </Grid>
              )}
              <Grid item>
                <FormControl variant="standard" className="custom-select">
                  <EventTypeSelect
                    showLabel={true}
                    selectedEventType={activeEvent.EventType}
                    onEventTypeChange={(value: string) => {
                      handleChange("EventType", value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                {/* Logo Upload File */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2">Logo(Dark Theme)</Typography>
                    <input
                      type="file"
                      id="darkLogoFile"
                      style={{ display: "none" }}
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => handleFileChange(e, false)}
                    />
                    <label htmlFor="darkLogoFile">
                      <Button size="small" color="primary" component="span">
                        Upload Logo
                      </Button>
                    </label>
                  </div>
                  {selectedDarkLogoFile.fileURL && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography marginRight="0.5rem" variant="body2">
                        Selected Logo
                      </Typography>
                      <img
                        alt="previewimage"
                        src={selectedDarkLogoFile.fileURL}
                      />
                      <DeleteIconButton
                        onDelete={() => handleLogoDelete(false)}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingBottom: "2rem",
                    }}
                  >
                    <Typography variant="body2">Logo(Light Theme)</Typography>
                    <input
                      type="file"
                      id="lightLogoFile"
                      style={{ display: "none" }}
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => handleFileChange(e, true)}
                    />
                    <label htmlFor="lightLogoFile">
                      <Button size="small" color="primary" component="span">
                        Upload Logo
                      </Button>
                    </label>
                  </div>
                  {selectedLightLogoFile.fileURL && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "2rem",
                      }}
                    >
                      <Typography marginRight="0.5rem" variant="body2">
                        Selected Logo
                      </Typography>
                      <img
                        alt="previewimage"
                        src={selectedLightLogoFile.fileURL}
                      />
                      <DeleteIconButton
                        onDelete={() => handleLogoDelete(true)}
                      />
                    </div>
                  )}
                  {!selectedDarkLogoFile.fileURL &&
                    !selectedLightLogoFile.fileURL && (
                      <Typography
                        fontStyle="italic"
                        marginRight="1rem"
                        variant="caption"
                      >
                        Series logo will be used if none is added
                      </Typography>
                    )}
                </div>
              </Grid>
              <Grid item>
                {/* Select Series */}
                <FormControl variant="standard" className="custom-select">
                  <SeriesSelect
                    selectedSeries={activeEvent.SeriesId}
                    onSeriesChange={(value) => handleChange("SeriesId", value)}
                    onFocus={() => clearError("SeriesId")}
                    showLabel={true}
                  />
                  <StyledFormHelper>
                    {validationErrors.SeriesId}
                  </StyledFormHelper>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant="standard" className="custom-select">
                  <RaceTeamSelect
                    seriesId={activeEvent.SeriesId}
                    eventRaceTeamIds={
                      activeEvent.AllowedRaceTeams?.split(",") ?? []
                    }
                    onRaceTeamChange={(raceTeamIds) =>
                      handleChange("AllowedRaceTeams", raceTeamIds.toString())
                    }
                    onFocus={() => clearError("AllowedRaceTeams")}
                  />
                  <StyledFormHelper>
                    {validationErrors.AllowedRaceTeams}
                  </StyledFormHelper>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant="standard" className="custom-select">
                  <SiteSelect
                    selectedSite={activeEvent.SiteId}
                    onSiteChange={(value) => handleChange("SiteId", value)}
                    onFocus={() => clearError("SiteId")}
                    showLabel={true}
                    selectedSeries={activeEvent.SeriesId}
                  />
                  <StyledFormHelper>{validationErrors.SiteId}</StyledFormHelper>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant="standard" className="custom-select">
                  <TimingAndScoringSelect
                    selectedSeries={activeEvent.SeriesId}
                    selectedTnS={activeEvent.TimingScoringId}
                    onTnSChange={(value) =>
                      handleChange("TimingScoringId", value)
                    }
                    onFocus={() => clearError("TimingScoringId")}
                    showLabel={true}
                  />
                  <StyledFormHelper>
                    {validationErrors.TimingScoringId}
                  </StyledFormHelper>
                </FormControl>
              </Grid>
              <Grid item width={"70%"}>
                <SortableList
                  selectedSeries={activeEvent.SeriesId}
                  selectedWeatherStations={activeEvent.WeatherStationIds}
                  showLabel={true}
                  onSelectionChange={handleWeatherStationSelectionChange}
                />
                <StyledFormHelper>
                  {validationErrors.WeatherStationIds}
                </StyledFormHelper>
              </Grid>
              <Grid item>
                <FormControl variant="standard" className="custom-select">
                  {sponsors && (
                    <SponsorSelect
                      sponsors={sponsors}
                      eventSponsorIds={activeEvent.SponsorIds?.split(",")}
                      onSponsorChange={(sponsorNames) =>
                        handleSponsorsChange(sponsorNames)
                      }
                      isMobile={isMobile}
                    />
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item>
              {/* Adding sessions */}
              <SessionList
                parentEvent={activeEvent}
                sponsors={sponsors}
                isAdmin={true}
                isMobile={isMobile}
                isTablet={isTablet}
                isSessionHardDelete={false}
                showTitleBar={true}
                showSessionType={false}
                onSessionsUpdated={handleSessionsUpdated}
                onSessionDeleted={handleSessionDeleted}
                showDelayMenu={true}
              />
              {validationErrors.Sessions && (
                <StyledErrorMessage>
                  {validationErrors.Sessions}
                </StyledErrorMessage>
              )}
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingRight: "1.563rem" }}>
            <Button onClick={handleCancel} variant="outlined">
              Cancel
            </Button>
            {isEditMode ? (
              <Button onClick={handleDeleteEvent} variant="outlined">
                Delete
              </Button>
            ) : null}
            <Button onClick={handleAddOrUpdateEvent} variant="contained">
              {isEditMode ? "Update" : "Add"}
            </Button>
          </DialogActions>
        </StyledDialog>
      )}
    </>
  );
};
