import { Button, SxProps, Theme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export type EditButtonProps = {
  entityId?: string;
  onEdit: (event: React.MouseEvent, entityId?: string) => void;
  sx?: SxProps<Theme>;
};

export const EditButton = ({ onEdit, entityId, sx }: EditButtonProps) => {
  return (
    <Button
      variant="text"
      sx={sx}
      title="Edit"
      onClick={(event) => onEdit(event, entityId)}
      color="primary"
    >
      <EditIcon
        tabIndex={0}
        fontSize="small"
        sx={{
          position: "relative",
          bottom: "0.125rem",
          padding: "0.25rem",
        }}
      />
      Edit Event
    </Button>
  );
};
