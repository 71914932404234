import { Grid, useTheme } from "@mui/material";
import { IEvent, ISeries } from "../../Interfaces";
import { StyledLogo } from "./EventListStyles";
import { EventLogos } from "./EventLogos";
import { EventText } from "./EventText";
import { useSeries, useSponsors } from "../../Services/LookupService";
import { EventSponsors } from "./EventSponsors";
import { GridFlexCenter } from "../App/App.styles";
import { useEffect } from "react";
import { getEventLogoSrc } from "../../utils";
import { SeriesName } from "../../Interfaces/SeriesName";
import { getSeriesLogoSrc } from "../Shared/SeriesLogos";

export type EventDetailsProps = {
  isMobile: boolean;
  isEventView: boolean;
  selectedEvent: IEvent;
  displaySponsors?: boolean;
};

export const EventDetails = ({
  isMobile,
  isEventView,
  selectedEvent,
  displaySponsors = true,
}: EventDetailsProps) => {
  const theme = useTheme();
  const { data: sponsors } = useSponsors();
  const { data: series } = useSeries();

  useEffect(() => {
    if (!series) return;
    selectedEvent.Series = series.find(
      (s) =>
        s.SeriesId?.toLowerCase() === selectedEvent.SeriesId?.toLowerCase(),
    );
  }, [series, selectedEvent]);

  const getSeries = (seriesId: string): ISeries | undefined => {
    return series?.find(
      (s) => s.SeriesId.toLowerCase() === seriesId.toLowerCase(),
    );
  };

  return (
    <Grid
      columnGap={!isMobile && isEventView ? 1 : "unset"}
      container
      direction="row"
    >
      {!isMobile && EventLogos(selectedEvent)}
      {isMobile && (
        <GridFlexCenter item xs={3} container position="relative">
          {getSeriesLogoSrc(
            theme.palette.mode,
            (selectedEvent?.Series?.Name ??
              getSeries(selectedEvent.SeriesId)?.Name) as SeriesName,
          )}
        </GridFlexCenter>
      )}
      <Grid
        item
        paddingLeft={isMobile ? 1 : "unset"}
        xs={isMobileWithEventLogo() || !isMobile ? 6 : 9}
        md={isMobileWithEventLogo() || !isMobile ? 4.5 : 7}
        lg={isMobileWithEventLogo() || displaySponsors ? 5 : 10}
      >
        {EventText(selectedEvent)}
      </Grid>
      {isMobileWithEventLogo() && (
        <Grid item xs={3} container paddingLeft={isMobile ? 1 : "unset"}>
          <StyledLogo
            src={getEventLogoSrc(
              theme.palette.mode,
              selectedEvent.LogoBase64EncodedLight,
              selectedEvent.LogoBase64EncodedDark,
            )}
            alt={"Event Logo for " + selectedEvent.Name}
          />
        </Grid>
      )}
      {!isMobile && displaySponsors && (
        <EventSponsors selectedEvent={selectedEvent} sponsors={sponsors} />
      )}
    </Grid>
  );

  function isMobileWithEventLogo() {
    return (
      isMobile &&
      selectedEvent &&
      (selectedEvent.LogoBase64EncodedLight ||
        selectedEvent.LogoBase64EncodedDark)
    );
  }
};
