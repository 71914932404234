import { Paper, Container, Box, Typography } from "@mui/material";
import { DarkTheme, LightTheme } from "../App/Theme";

interface IFooter {
  darkMode: boolean;
}

export const Footer = ({ darkMode }: IFooter) => {
  return (
    <Paper
      sx={{
        backgroundColor: darkMode
          ? DarkTheme.bgColorHeaderFooter
          : LightTheme.bgColorHeaderFooter,
        color: darkMode
          ? DarkTheme.fontColorHeaderFooter
          : LightTheme.fontColorHeaderFooter,
        width: "100%",
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          flexGrow="1"
          justifyContent="center"
          margin="0.5rem 0rem"
        >
          <Typography fontSize="x-small" variant="caption">
            Copyright © 2023. All rights reserved to General Motors LLC.
          </Typography>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            textAlign: "center",
            display: "flex",
            mb: 2,
          }}
        >
          <Typography fontSize="x-small" variant="caption">
            This website is unofficial and is not associated in any way with
            NASCAR companies, NASCAR Cup Series, NASCAR Xfinity Series, NASCAR
            Craftsman Truck Series, THE NASCAR DIGITAL MEDIA NETWORK, IndyCar
            companies, NTT INDYCAR SERIES, Formula 1 companies, F1, FORMULA ONE,
            FORMULA 1, FIA ONE WORLD ENDURANCE CHAMPIONSHIP(WEC), GRAND PRIX,
            and International Motor Sports Association(IMSA) companies. This
            website is not affiliated with any trade marks of NASCAR Digital
            Media LLC, INDYCAR LLC, Formula One LLC, FIA World Endurance
            Championship LLC, and International Motor Sports Association LLC.
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
};
