import { IEvent, ISite } from "../../Interfaces";
import { useSites } from "../../Services/LookupService";
import { EventCard } from "./EventCard";

type EventListProps = {
  Events: Array<IEvent>;
  isAdmin: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isEventView: boolean;
  editEventHandler: (event: IEvent) => void;
  confirmDeleteHandler: (event: IEvent) => void;
  updateEventHandler: (
    event: IEvent,
    isSessionUpdate: boolean,
    isDelayedOrCanceled: boolean,
    isDelete: boolean,
  ) => void;
  refreshHandler: () => void;
  scrollRef: (node?: Element | null | undefined) => void;
};

export const EventList = ({
  Events,
  isAdmin,
  isMobile,
  isTablet,
  isEventView,
  updateEventHandler,
  confirmDeleteHandler,
  editEventHandler,
  refreshHandler,
  scrollRef,
}: EventListProps) => {
  const { data: sites } = useSites();
  return (
    <>
      {sites &&
        Events?.map((event: IEvent, index: number) => {
          return (
            <EventCard
              key={`${event.EventId}-${index}`}
              index={index}
              selectedEvent={event}
              site={event.Site ?? getSite(sites, event.SiteId)}
              onEdit={() => editEventHandler(event)}
              onDelete={() => confirmDeleteHandler(event)}
              onUpdate={(
                event: IEvent,
                isSessionUpdate,
                isDelayedOrCanceled,
                isDelete,
              ) => {
                updateEventHandler(
                  event,
                  isSessionUpdate,
                  isDelayedOrCanceled,
                  isDelete,
                );
              }}
              isAdmin={isAdmin ?? false}
              isMobile={isMobile}
              isTablet={isTablet}
              isEventView={isEventView}
              refresh={() => refreshHandler()}
              scrollRef={scrollRef}
              shouldHaveScrollRef={
                Events.length >= 50 && Events.length - 10 === index + 1
              }
            />
          );
        })}
    </>
  );
};

const getSite = (sites: Array<ISite>, siteId: string): ISite => {
  const site = sites?.filter(
    (s) => s.SiteId?.toLowerCase() === siteId?.toLowerCase(),
  )[0];
  return site;
};
