import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment-timezone";
import React, { useState } from "react";
import { IEvent, IEventSession, ISite } from "../../Interfaces";
import { useSponsors } from "../../Services/LookupService";
import { SessionList } from "../ListSessions/SessionList";
import { DeleteIconButton } from "../Shared/DeleteIconButton";
import { EditIconButton } from "../Shared/EditIconButton";
import { EventDetails } from "./EventDetails";

interface EventProps {
  isAdmin: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isEventView: boolean;
  index: number;
  selectedEvent: IEvent;
  site: ISite;
  onEdit: () => void;
  onDelete: () => void;
  onUpdate: (
    eventData: IEvent,
    isSessionUpdate: boolean,
    isDelayedOrCanceled: boolean,
    isDelete: boolean,
  ) => void;
  refresh: () => void;
  scrollRef: (node?: Element | null | undefined) => void;
  shouldHaveScrollRef: boolean;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
  isMobile: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, isMobile, ...other } = props;
  return (
    <Tooltip title={!expand ? "Expand" : "Collapse"}>
      <IconButton aria-label={!expand ? "Expand" : "Collapse"} {...other} />
    </Tooltip>
  );
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  marginRight: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  fontSize: "2rem",
  color: "inherit",
}));

export const EventCard = (props: EventProps) => {
  const {
    selectedEvent,
    onEdit,
    onDelete,
    onUpdate,
    isAdmin,
    isMobile,
    isEventView,
    isTablet,
    refresh,
    shouldHaveScrollRef,
  } = props;
  const [expanded, setExpanded] = useState(false);

  const { data: sponsors } = useSponsors();

  const handleExpandCard = () => {
    setExpanded(!expanded);
  };

  const handleEditEvent = (event: React.MouseEvent) => {
    event.stopPropagation();
    onEdit();
  };

  const handleDeleteEvent = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDelete();
  };

  const handleUpdatedSessions = (
    updatedSessions: IEventSession[],
    isDelayedOrCanceled: boolean,
    isDelete: boolean,
  ): void => {
    const eventData: IEvent = {
      ...selectedEvent,
      Sessions: updatedSessions,
    };
    onUpdate(eventData, true, isDelayedOrCanceled, isDelete);
  };

  const handleRefetchSessions = (): void => {
    refresh && refresh();
  };

  let dateString = "";
  if (selectedEvent.Sessions && selectedEvent.Sessions.length > 0) {
    const startSessionDayOfWeek = moment(
      selectedEvent.Sessions[0].StartTime,
    ).format("ddd");
    const startSessionMMDoddd = moment(
      selectedEvent.Sessions[0].StartTime,
    ).format("MMM Do ddd");
    const endSessionDayOfWeek = moment(
      selectedEvent.Sessions[selectedEvent.Sessions.length - 1].StartTime,
    ).format("ddd");
    const lastSessionMMDoddd = moment(
      selectedEvent.Sessions[selectedEvent.Sessions.length - 1].StartTime,
    ).format("MMM Do ddd");
    const startSessionMMMDo = moment(
      selectedEvent.Sessions[0].StartTime,
    ).format("MMM Do");
    const endSessionDo = moment(
      selectedEvent.Sessions[selectedEvent.Sessions.length - 1].StartTime,
    ).format("Do");

    if (startSessionMMDoddd === lastSessionMMDoddd) {
      dateString = `${startSessionDayOfWeek}, ${startSessionMMMDo}`;
    } else {
      dateString = `${startSessionDayOfWeek} - ${endSessionDayOfWeek}, ${startSessionMMMDo} - ${endSessionDo}`;
    }
  }

  return (
    <>
      {selectedEvent.Sessions && selectedEvent.Sessions.length > 0 && (
        <Card
          key={selectedEvent.EventId}
          sx={{
            margin: "0.5rem",
          }}
          variant="outlined"
        >
          <CardActionArea onClick={handleExpandCard}>
            {isMobile && (
              <CardHeader
                title={dateString}
                titleTypographyProps={{
                  sx: {
                    fontWeight: "bolder",
                    fontSize: {
                      lg: 15,
                      md: 13,
                      sm: 13,
                      xs: 13,
                    },
                  },
                }}
              />
            )}
            <CardContent
              {...(shouldHaveScrollRef
                ? {
                    ref: props.scrollRef,
                  }
                : undefined)}
            >
              <Grid container columns={isMobile ? 12 : 16}>
                {!isMobile && (
                  <Grid item xs={2} display="flex" paddingRight={1}>
                    <Typography
                      alignSelf="center"
                      fontWeight="bolder"
                      fontSize={{
                        lg: 15,
                        md: 13,
                        sm: 13,
                        xs: 13,
                      }}
                    >
                      {dateString}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={11} lg={11.5} xl={12.5}>
                  <EventDetails
                    isMobile={isMobile}
                    isEventView={isEventView}
                    selectedEvent={selectedEvent}
                  />
                </Grid>
                {!isMobile && (
                  <Grid
                    item
                    xs={2}
                    sm={2.5}
                    lg={2}
                    xl={1.5}
                    display="flex"
                    alignSelf="center"
                    paddingTop="0"
                    paddingLeft="1"
                  >
                    {isAdmin && (
                      <DeleteIconButton onDelete={handleDeleteEvent} />
                    )}
                    {isAdmin && <EditIconButton onEdit={handleEditEvent} />}
                    {!isMobile && (
                      <ExpandMore
                        expand={expanded}
                        isMobile={isMobile}
                        onClick={handleExpandCard}
                        aria-expanded={expanded}
                        aria-label="Show sessions"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    )}
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </CardActionArea>
          {isMobile && (
            <CardActions>
              <ExpandMore
                expand={expanded}
                isMobile={isMobile}
                onClick={handleExpandCard}
                aria-expanded={expanded}
                aria-label="Show sessions"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
          )}
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <SessionList
              sponsors={sponsors}
              parentEvent={selectedEvent}
              onEditEvent={onEdit}
              onDeleteEvent={onDelete}
              onSessionsUpdated={handleUpdatedSessions}
              showTitleBar={false}
              showDelayMenu={true}
              showEditDeleteEvent={true}
              refetchSessions={handleRefetchSessions}
              isAdmin={isAdmin}
              isMobile={isMobile}
              isTablet={isTablet}
              isSessionHardDelete={true}
              shouldAlternateSessionRows={true}
              useEventUpdate={true}
            />
          </Collapse>
        </Card>
      )}
    </>
  );
};
