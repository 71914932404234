import moment from "moment-timezone";
import { EventStatus, IEvent, IEventType, ISession } from "../../Interfaces";
import { FilterPreferences } from "../../Interfaces/FilterPreferences";
import { sortSessionsAscending } from "../../Services/SessionService";
import { ClientPrincipal } from "../../Services/UserInfoService";

export const processSessions = (
  sortedNotifications: ISession[],
  combinedSessions: any[],
  useAllSeries: boolean,
  filterValues?: FilterPreferences,
) => {
  const now = new Date();

  const sortedSessions = combinedSessions
    .filter((session) => session?.StartTime)
    .filter((session) => {
      const startTime = new Date(session?.StartTime ?? "");
      const startPlus24hours = startTime.getTime() + 24 * 60 * 60 * 1000;
      const endTime = new Date(session?.EndTime ?? startPlus24hours);

      if (session?.Status === EventStatus.Delayed) {
        return startTime > now;
      }

      if (session?.Status === EventStatus.Canceled) {
        return endTime > now;
      }

      return false;
    })
    .sort(sortSessionsAscending);

  let mergedNotifications = [];
  if (filterValues?.selectedSeries === undefined || useAllSeries) {
    mergedNotifications = [
      ...sortedNotifications,
      ...sortedSessions.filter(
        (s) => !sortedNotifications.some((sn) => sn?.SessionId === s.SessionId),
      ),
    ];
  } else {
    mergedNotifications = [
      ...sortedNotifications,
      ...sortedSessions.filter(
        (s) => !sortedNotifications.some((sn) => sn?.SessionId === s.SessionId),
      ),
    ].filter(
      (sn) =>
        filterValues &&
        filterValues.selectedSeries &&
        filterValues?.selectedSeries.some(
          (ss) =>
            ss.SeriesId.toUpperCase() === sn.ParentEvent.SeriesId.toUpperCase(),
        ),
    );
  }
  return mergedNotifications;
};

export const filterEvents = (
  events: Array<IEvent>,
  filterValues: FilterPreferences | undefined,
  userInfo: ClientPrincipal | null,
  isPastEvents: boolean,
  eventTypes: Array<IEventType>,
): Array<IEvent> => {
  const publicEvents = events.filter((evt) => {
    const publicEventTypes = eventTypes.filter((et) => et.IsPublic);
    if (publicEventTypes.some((et) => et.Name === evt.EventType)) return evt;
    return undefined;
  });

  if (!filterValues) {
    if (userInfo === null) {
      return publicEvents;
    }
    return userInfo.isAdmin ? events : publicEvents;
  }

  const selectedSeries = filterValues.selectedSeries;
  const selectedStartDate = moment(filterValues.selectedStartDate);
  const selectedEndDate = moment(filterValues.selectedEndDate);

  let combinedFilteredEvents =
    userInfo === null ? publicEvents : userInfo.isAdmin ? events : publicEvents;

  if (filterValues.selectedSeries) {
    const eventsBySeries = combinedFilteredEvents.filter((evt) =>
      selectedSeries?.some(
        (s) => s.SeriesId.toLowerCase() === evt.SeriesId.toLowerCase(),
      ),
    );
    combinedFilteredEvents = eventsBySeries;
  }

  if (
    selectedStartDate &&
    selectedStartDate.isValid() &&
    selectedEndDate &&
    selectedEndDate.isValid()
  ) {
    combinedFilteredEvents = combinedFilteredEvents?.filter((evt) =>
      evt?.Sessions?.some((s) => {
        const endDate = selectedEndDate.clone().add(1, "day");
        return (
          moment(s.StartTime)
            .add(24, "hours")
            .isSameOrAfter(selectedStartDate) &&
          moment(s.StartTime).isSameOrBefore(endDate)
        );
      }),
    );
  } else if (selectedStartDate && selectedStartDate.isValid()) {
    combinedFilteredEvents = combinedFilteredEvents?.filter((evt) =>
      evt?.Sessions?.some((s) => {
        return moment(s.StartTime)
          .add(24, "hours")
          .isSameOrAfter(selectedStartDate);
      }),
    );
  } else if (selectedEndDate && selectedEndDate.isValid()) {
    combinedFilteredEvents = combinedFilteredEvents?.filter((evt) =>
      evt?.Sessions?.some((s) => {
        const endDate = selectedEndDate.clone().add(1, "day");
        return moment(s.StartTime).isSameOrBefore(endDate);
      }),
    );
  }

  if (isPastEvents) {
    combinedFilteredEvents = combinedFilteredEvents?.filter((evt) =>
      evt?.Sessions?.some((s) => {
        return moment(s.StartTime).add(24, "hours").isBefore(moment().utc());
      }),
    );
  }
  return combinedFilteredEvents;
};

export const filterSessions = (
  sessions: Array<ISession>,
  filterValues: FilterPreferences | undefined,
  userInfo: ClientPrincipal | null,
  isPastSessions: boolean,
  eventTypes: Array<IEventType>,
): Array<ISession> => {
  const publicSessions = sessions.filter((session) => {
    const publicEventTypes = eventTypes.filter((et) => et.IsPublic);
    if (
      publicEventTypes.some((et) => et.Name === session.ParentEvent.EventType)
    )
      return session;
    return undefined;
  });

  if (!filterValues) {
    if (userInfo === null) {
      return publicSessions;
    }
    return userInfo.isAdmin ? sessions : publicSessions;
  }

  const selectedSeries = filterValues.selectedSeries;
  const selectedStartDate = moment(filterValues.selectedStartDate);
  const selectedEndDate = moment(filterValues.selectedEndDate);
  let combinedFilteredSessions =
    userInfo === null
      ? publicSessions
      : userInfo.isAdmin
        ? sessions
        : publicSessions;

  if (filterValues.selectedSeries) {
    const sessionsBySeries = combinedFilteredSessions.filter((session) =>
      selectedSeries?.some(
        (s) =>
          s.SeriesId.toLowerCase() ===
          session.ParentEvent.SeriesId.toLowerCase(),
      ),
    );
    combinedFilteredSessions = sessionsBySeries;
  }

  if (
    selectedStartDate &&
    selectedStartDate.isValid() &&
    selectedEndDate &&
    selectedEndDate.isValid()
  ) {
    const endDate = selectedEndDate.clone().add(1, "day");
    combinedFilteredSessions = combinedFilteredSessions?.filter((session) => {
      return (
        moment(session.StartTime).isSameOrAfter(selectedStartDate) ||
        moment(session.StartTime).isSameOrBefore(endDate)
      );
    });
  } else if (selectedStartDate && selectedStartDate.isValid()) {
    combinedFilteredSessions = combinedFilteredSessions?.filter((session) => {
      return moment(session.StartTime).isSameOrAfter(selectedStartDate);
    });
  } else if (selectedEndDate && selectedEndDate.isValid()) {
    const endDate = selectedEndDate.clone().add(1, "day");
    combinedFilteredSessions = combinedFilteredSessions?.filter((session) => {
      return moment(session.StartTime).isSameOrBefore(endDate);
    });
  }

  if (isPastSessions) {
    combinedFilteredSessions = combinedFilteredSessions?.filter((s) => {
      return (
        moment(s.StartTime).isSameOrBefore(moment().utc()) ||
        (moment(s.StartTime).isBefore(moment().utc()) && s.EndTime === null)
      );
    });
  }
  return combinedFilteredSessions;
};
