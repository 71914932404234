import { createTheme } from "@mui/material/styles";

export enum LightTheme {
  bgColor = "#FFFFFF",
  bgColorCard = "#DFDFDF",
  bgColorHeaderFooter = "#005DAB",
  fontColor = "#333333",
  fontColorHeaderFooter = "#FFFFFF",
  gridButtonBackground = "#FEFEFE",
  gridButtonOutline = "#F2F2F2",
  mobileCardButtonBackground = "#D6D6D6",
  mobileCardButtonOutline = "#C1C1C1",
  mobileDrawerBorderBottom = "#C1C1C1",
  hoverBackgroundColor = "rgba(0,0,0,0.1)",
  hoverOutlineColor = "rgba(0,0,0,0.25)",
}

export enum DarkTheme {
  bgColor = "#202020",
  bgColorButton = "#005DAB",
  bgColorCard = "#2D2D2D",
  bgColorHeaderFooter = "#005DAB",
  fontColor = "#FFFFFF",
  fontColorAlt = "#BABABA",
  fontColorHeaderFooter = "#FFFFFF",
  gridButtonBackground = "#211D1D",
  gridButtonOutline = "#2F2F2F",
  mobileCardButtonBackground = "#2A2A2A",
  mobileCardButtonOutline = "#252525",
  mobileDrawerBorderBottom = "#666666",
  hoverBackgroundColor = "rgba(255,255,255,0.15)",
  hoverOutlineColor = "rgba(255,255,255,0.35)",
}

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: LightTheme.bgColor,
    },
    text: {
      primary: LightTheme.fontColor,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Overpass",
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: LightTheme.bgColorHeaderFooter,
          color: LightTheme.fontColorHeaderFooter,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: LightTheme.bgColorCard,
          color: LightTheme.fontColor,
          margin: "0.5rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: DarkTheme.bgColor,
    },
    text: {
      primary: DarkTheme.fontColor,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "Overpass",
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: DarkTheme.bgColorHeaderFooter,
          color: DarkTheme.fontColorHeaderFooter,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: DarkTheme.bgColorButton,
          color: DarkTheme.fontColor,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: DarkTheme.bgColorButton,
        colorError: "red",
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: DarkTheme.bgColorCard,
          color: DarkTheme.fontColor,
          margin: "0.5rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...((ownerState?.rowNumber as number) % 2 === 1 && {
            backgroundColor: "#000",
          }),
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        }),
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
        },
      },
    },
  },
});
