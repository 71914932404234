import AccountCircle from "@mui/icons-material/AccountCircle";
import Logout from "@mui/icons-material/Logout";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import styled from "styled-components";

import {
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DarkModeGMLogo from "../../Assets/Icons/gm-brandmark-dark.svg";
import { ClientPrincipal, useUserInfo } from "../../Services/UserInfoService";

const HeaderText = styled.h1`
  font-size: 1.5rem;
  line-height: 1.35;
  flex-grow: 1;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export default function HeaderAppBar() {
  const [userName, setUserName] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { data: userInfo } = useUserInfo();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const name = userInfo?.claims.filter((c) => c.typ === "name");
    if (name && name.length > 0) {
      setUserName(name[0].val);
    }
  }, [userInfo]);

  return (
    <>
      <Box
        component="img"
        src={DarkModeGMLogo}
        width={35}
        height={35}
        marginRight="0.625rem"
        marginLeft="1rem"
        alt="GM Logo"
      />
      <HeaderText>GM Motorsports Schedule</HeaderText>
      {userInfo ? (
        <>
          <AuthenticatedUserInfo
            userInfo={userInfo}
            userName={userName}
            open={open}
            handleClick={handleClick}
          />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Link
              href="/.auth/logout"
              color="inherit"
              sx={{ textDecoration: "none" }}
            >
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <Typography>Logout</Typography>
              </MenuItem>
            </Link>
          </Menu>
        </>
      ) : (
        <>
          <UnauthenticatedUserInfo />
        </>
      )}
    </>
  );
}

const AuthenticatedUserInfo = ({
  userInfo,
  userName,
  open,
  handleClick,
}: {
  userInfo: ClientPrincipal;
  userName: string;
  open: boolean;
  handleClick: any;
}) => {
  return (
    <>
      {userInfo?.isAdmin && (
        <span
          style={{
            padding: "0.25rem",
            marginRight: "0.5rem",
            backgroundColor: "white",
            color: "#005dab",
            borderRadius: "0.25rem",
          }}
        >
          ADMIN
        </span>
      )}
      <span style={{ paddingRight: "0.25rem" }}>{userName}</span>
      <IconButton
        onClick={handleClick}
        style={{ color: "#ece6e6", fontSize: "0px" }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <AccountCircle />
      </IconButton>
    </>
  );
};

const UnauthenticatedUserInfo = () => {
  return (
    <>
      <Link
        href="/.auth/login/aad?post_login_redirect_uri=/home"
        color="inherit"
        sx={{ textDecoration: "none" }}
      >
        <Button
          aria-label="Login with your GM account"
          variant="contained"
          style={{ color: "#ece6e6", fontSize: "0px" }}
        >
          <Typography>Motorsports Home Login</Typography>
        </Button>
      </Link>
    </>
  );
};
