import moment, { Moment } from "moment-timezone";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  EventStatus,
  IEventSession,
  ISession,
  ISessionCancel,
  ISessionDelay,
  Page,
} from "../Interfaces";
import { FilterPreferences } from "../Interfaces/FilterPreferences";
import {
  APP_REFRESH_INTERVAL_IN_MS,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../app.constants";
import { circularReplacer } from "../utils";
import { LoggerService } from "./LoggerService";
import { createAdminRequest, createLookupRequest } from "./ServiceUtils";

enum SessionServiceUrl {
  getSessionById = "/api/session",
  createOrUpdateSession = "/api/session",
  deleteSession = "/api/session",
  getSessionsForAdmin = "api/sessions",
  getSessions = "api/public/sessions",
  cancelSession = "/api/session/cancel",
  delayedSession = "/api/session/delay",
}

export const sortSessionsAscending = (
  s1: IEventSession,
  s2: IEventSession,
): number => {
  return sortSessions(s1, s2);
};

export const sortSessionsDescending = (
  s1: IEventSession,
  s2: IEventSession,
): number => {
  return sortSessions(s1, s2) * -1;
};

let forceRefresh = false;

export const sortSessions = (
  a: IEventSession,
  b: IEventSession,
): 0 | 1 | -1 => {
  const aStart = moment.utc(a.StartTime);
  const bStart = moment.utc(b.StartTime);
  const difference = aStart.diff(bStart, "millisecond");
  return difference === 0 ? 0 : difference > 0 ? 1 : -1;
};

const mapApiSessionToSession = (apiSession: any): ISession => {
  const mappedSession: ISession = {
    ...apiSession,
  };
  // replace null ref with mapped session
  const fixedSessions = mappedSession.ParentEvent.Sessions?.filter(
    (s) => s !== null,
  );
  fixedSessions?.push(mappedSession);
  fixedSessions?.sort(sortSessionsAscending);
  mappedSession.ParentEvent.Sessions = fixedSessions;
  return mappedSession;
};

const buildSessionPageRoute = (
  isAdmin: Boolean,
  pageNumber: Number = DEFAULT_PAGE_NUMBER,
  pageSize: Number = DEFAULT_PAGE_SIZE,
  force: boolean = false,
  sortDirection: string = "ASC",
  includeLast12MonthsOnly: boolean,
  filterCriteria?: FilterPreferences,
): string => {
  const baseUrl = isAdmin
    ? SessionServiceUrl.getSessionsForAdmin
    : SessionServiceUrl.getSessions;

  const requestUrlArguments = new URLSearchParams();
  requestUrlArguments.append("pageNumber", `${pageNumber}`);
  requestUrlArguments.append("pageSize", `${pageSize}`);
  requestUrlArguments.append("sortDirection", `${sortDirection}`);
  requestUrlArguments.append(
    "useCurrentCalendarYear",
    `${includeLast12MonthsOnly}`,
  );

  if (filterCriteria) {
    const startsAfter = filterCriteria.selectedStartDate;
    if (startsAfter) {
      const isoDate = startsAfter.toISOString();
      const dateSegment = isoDate.substring(0, isoDate.indexOf("T"));
      requestUrlArguments.append("startsAfterDate", dateSegment);
    }

    const endsBefore = filterCriteria.selectedEndDate;
    if (endsBefore) {
      const isoDate = endsBefore.toISOString();
      const dateSegment = isoDate.substring(0, isoDate.indexOf("T"));
      requestUrlArguments.append("endsBeforeDate", dateSegment);
    }

    const seriesIds: Array<string> = new Array<string>();
    filterCriteria.selectedSeries?.forEach((value) =>
      seriesIds.push(value.SeriesId),
    );
    requestUrlArguments.append("seriesIds", `${seriesIds.join(",")}`);
  }

  let requestUrl = `${baseUrl}?${requestUrlArguments.toString()}`;

  if (force) {
    requestUrl += `&${new Date().getTime()}`;
  }
  return requestUrl;
};

const getSessionPages = async (
  isAdmin: boolean,
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  force: boolean = false,
  includeLast12MonthsOnly: boolean = false,
  filterCriteria?: FilterPreferences,
  sortDirection: string = "ASC",
): Promise<Page<ISession>> => {
  const requestUrl = buildSessionPageRoute(
    isAdmin,
    pageNumber,
    pageSize,
    force,
    sortDirection,
    includeLast12MonthsOnly,
    filterCriteria,
  );
  const requestOptions = isAdmin
    ? createAdminRequest("GET")
    : createLookupRequest("GET");
  const response = await fetch(requestUrl, requestOptions);
  if (!response.ok) {
    const error = `${
      response.status
    } error has occurred: ${await response.text()}`;

    LoggerService.logError("getSessionPages()", error, {
      url: SessionServiceUrl.getSessionsForAdmin,
      ...requestOptions,
    });
    throw new Error(error);
  }
  const data = await response.json();
  const sessions: Array<ISession> = data.Data.map((session: any) => {
    return mapApiSessionToSession(session);
  });

  return {
    HasNextPage: data.HasNextPage,
    Data: sessions,
    NextPage: data.NextPageIndex,
  };
};

const getSessionById = async (
  sessionId: string,
): Promise<IEventSession | null> => {
  if (!sessionId) return null;
  const getSessionByIdUrl = `${SessionServiceUrl.getSessionById}/${sessionId}`;
  const requestOptions = createLookupRequest("GET");
  const response = await fetch(getSessionByIdUrl, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("getSessionById()", error, {
      url: getSessionByIdUrl,
    });
    throw new Error(error);
  }

  const data = await response.json();
  return data;
};

export const RemoveEndDateIfNotSpecified = (
  sessionData: IEventSession,
): IEventSession => {
  const sessionDataToChange = { ...sessionData };
  if (!sessionData.EndTime) {
    delete sessionDataToChange.EndTime;
  }
  return sessionDataToChange;
};

export const createSession = async (
  sessionData: IEventSession,
): Promise<IEventSession> => {
  if ("ParentEvent" in sessionData) {
    delete sessionData.ParentEvent;
  }

  LoggerService.logInfo("updateSession()", "Source:", sessionData);
  const sessionToCreate = RemoveEndDateIfNotSpecified(sessionData);
  LoggerService.logInfo("updateSession()", "Trimmed:", sessionToCreate);

  const requestOptions = createAdminRequest("POST", sessionToCreate);
  const response = await fetch(
    `${SessionServiceUrl.createOrUpdateSession}/${sessionData.SessionId}`,
    requestOptions,
  );

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("createSession()", error, {
      url: SessionServiceUrl.createOrUpdateSession,
      ...requestOptions,
    });
    throw new Error(error);
  }

  const newSession = await response.json();
  return newSession.value;
};

const GetSessionToUpdate = (sessionDataToUpdate: IEventSession) => {
  if (sessionDataToUpdate.hasOwnProperty("EndTime")) {
    return {
      Name: sessionDataToUpdate.Name,
      StartTime: sessionDataToUpdate.StartTime,
      EndTime: sessionDataToUpdate.EndTime,
      Status: sessionDataToUpdate.Status,
      EventId: sessionDataToUpdate.EventId,
      CreatedOn: sessionDataToUpdate.CreatedOn,
      CreatedBy: sessionDataToUpdate.CreatedBy,
      UpdatedOn: sessionDataToUpdate.UpdatedOn,
      UpdatedBy: sessionDataToUpdate.UpdatedBy,
      SessionType: sessionDataToUpdate.SessionType,
    };
  } else {
    return {
      Name: sessionDataToUpdate.Name,
      StartTime: sessionDataToUpdate.StartTime,
      Status: sessionDataToUpdate.Status,
      EventId: sessionDataToUpdate.EventId,
      CreatedOn: sessionDataToUpdate.CreatedOn,
      CreatedBy: sessionDataToUpdate.CreatedBy,
      UpdatedOn: sessionDataToUpdate.UpdatedOn,
      UpdatedBy: sessionDataToUpdate.UpdatedBy,
      SessionType: sessionDataToUpdate.SessionType,
    };
  }
};

export const updateSession = async (
  sessionData: IEventSession,
  fromEventUpdate: boolean = false,
): Promise<IEventSession> => {
  if ("ParentEvent" in sessionData) {
    delete sessionData.ParentEvent;
  }
  // copy session object to new object without entity ID field
  const sessionDataToUpdate = RemoveEndDateIfNotSpecified(sessionData);
  const sessionToUpdate = GetSessionToUpdate(sessionDataToUpdate);
  const updateSessionUrl = `${SessionServiceUrl.createOrUpdateSession}/${sessionData.SessionId}`;
  const requestOptions = createAdminRequest("POST", sessionToUpdate);

  LoggerService.logInfo(
    "updateSession()",
    "Source:",
    // This log is throwing an error because of circular structure so we use a replacer.
    JSON.stringify(sessionData, circularReplacer()),
  );
  LoggerService.logInfo("updateSession()", "Trimmed:", sessionToUpdate);

  const response = await fetch(updateSessionUrl, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("updateSession()", error, {
      url: updateSessionUrl,
      ...requestOptions,
    });
    throw new Error(error);
  }

  const newSession = await response.json();
  return newSession;
};

const deleteSessionById = async (sessionId: string) => {
  const existingSession = await getSessionById(sessionId);
  if (!existingSession) return;
  const deleteSessionByIdUrl = `${SessionServiceUrl.deleteSession}/${sessionId}`;
  const requestOptions = createAdminRequest("DELETE");
  const response = await fetch(deleteSessionByIdUrl, requestOptions);

  if (response.status !== 204) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("deleteSessionById()", error, {
      url: deleteSessionByIdUrl,
      ...requestOptions,
    });
    throw new Error(error);
  }
};

const delaySession = async (
  sessionDelay: ISessionDelay,
): Promise<IEventSession[]> => {
  const delayedSession = await getSessionById(sessionDelay.sessionId);
  const startDate: Moment = moment.utc(delayedSession?.StartTime as string);
  const endDate: Moment = moment.utc(delayedSession?.EndTime as string);

  const delayedSessionData = {
    StartTime: startDate.add(sessionDelay.delayMinutes, "minutes"),
    EndTime: endDate.add(sessionDelay.delayMinutes, "minutes"),
    Status: EventStatus.Delayed,
  };

  const requestOptions = createAdminRequest("PATCH", delayedSessionData);
  const updateSessionByIdUrl = `${SessionServiceUrl.delayedSession}/${sessionDelay.sessionId}`;
  const response = await fetch(updateSessionByIdUrl, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("delaySession()", error, {
      url: updateSessionByIdUrl,
      ...requestOptions,
    });
    throw new Error(error);
  }

  return response.json();
};

const cancelSession = async (
  sessionCanceled: ISessionCancel,
): Promise<IEventSession[]> => {
  const canceledSessionData = {
    Status: sessionCanceled.isCanceled ? EventStatus.Canceled : EventStatus.Ok,
  };

  const requestOptions = createAdminRequest("PATCH", canceledSessionData);
  const updateSessionByIdUrl = `${SessionServiceUrl.cancelSession}/${sessionCanceled.sessionId}`;
  const response = await fetch(updateSessionByIdUrl, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("cancelSession()", error, {
      url: updateSessionByIdUrl,
      ...requestOptions,
    });
    throw new Error(error);
  }
  return response.json();
};

export const useInfiniteSessions = (
  isAdmin: boolean = false,
  shouldRefresh: boolean = false,
  isSessionViewEnabled: boolean = false,
  useSliding12MonthWindow: boolean = false,
  filterCriteria?: FilterPreferences,
  sortDirection: string = "ASC",
) => {
  return useInfiniteQuery(
    ["sessionDataPage", isAdmin, useSliding12MonthWindow, filterCriteria],
    ({ pageParam }) =>
      getSessionPages(
        isAdmin,
        pageParam,
        DEFAULT_PAGE_SIZE,
        forceRefresh,
        useSliding12MonthWindow,
        filterCriteria,
        sortDirection,
      ),
    {
      ...(shouldRefresh && { refetchInterval: APP_REFRESH_INTERVAL_IN_MS }),
      getNextPageParam: (lastPage) => {
        return lastPage.HasNextPage ? lastPage.NextPage : undefined; //If this is undefined it knows to stop calling the page function
      },
      enabled: isSessionViewEnabled,
    },
  );
};

export const useGetSessionById = (sessionId: string) => {
  return useQuery<IEventSession | null>("getSession", () =>
    getSessionById(sessionId),
  );
};

export const useDelaySession = () => {
  const queryClient = useQueryClient();

  return useMutation<IEventSession[], Error, ISessionDelay>(delaySession, {
    onSuccess: () => {
      forceRefresh = true;
      queryClient.invalidateQueries("sessionsPage");
      queryClient.invalidateQueries("sessionDataPage");
    },
  });
};

export const useCancelSession = () => {
  const queryClient = useQueryClient();

  return useMutation<IEventSession[], Error, ISessionCancel>(cancelSession, {
    onSuccess: () => {
      forceRefresh = true;
      queryClient.invalidateQueries("sessionsPage");
      queryClient.invalidateQueries("sessionDataPage");
    },
  });
};

export const useUpdateSession = () => {
  const queryClient = useQueryClient();

  return useMutation<IEventSession, Error, IEventSession>(updateSession, {
    onSuccess: () => {
      forceRefresh = true;
      queryClient.invalidateQueries("sessionsPage");
      queryClient.invalidateQueries("sessionDataPage");
    },
  });
};

export const useDeleteSessionById = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, string>(deleteSessionById, {
    onSuccess: () => {
      forceRefresh = true;
      queryClient.invalidateQueries("sessionsPage");
      queryClient.invalidateQueries("sessionDataPage");
    },
  });
};
