import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { StyledAutocompleteWrapper } from "../ListEvents/EventListStyles";

export type ValueSelectProps = {
  data: Array<any> | undefined;
  labelText?: string;
  showLabel?: boolean;
  selectedValue: string | null;
  namePropName: string;
  valuePropName: string;
  onValueChange: (value: string) => void;
  onFocus?: () => void;
  defaultText?: string;
};

export const ValueSelect = ({
  data,
  labelText,
  showLabel = false,
  selectedValue,
  namePropName,
  valuePropName,
  onValueChange,
  onFocus = undefined,
  defaultText = "",
}: ValueSelectProps) => {
  const emptyOption = { [namePropName]: "", [valuePropName]: "" };

  const handleOptionSelect = (
    event: any,
    value: any,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<any> | undefined,
  ) => {
    switch (reason) {
      case "clear":
        value = emptyOption;
        break;
      default:
        break;
    }
    onValueChange(value[valuePropName]);
  };

  const getValue = (): any => {
    if (!data) return emptyOption;

    return !selectedValue
      ? emptyOption
      : data.find((val: any) => {
          return (
            val[namePropName].toLocaleLowerCase() ===
              selectedValue.toLocaleLowerCase() ||
            val[valuePropName].toLocaleLowerCase() ===
              selectedValue.toLocaleLowerCase()
          );
        });
  };

  return (
    <>
      {!data && <Skeleton variant="rectangular" />}
      {data && (
        <StyledAutocompleteWrapper>
          <Autocomplete
            id={`${labelText}id`}
            key={`${labelText}key`}
            disableListWrap
            autoSelect
            fullWidth
            openOnFocus
            handleHomeEndKeys
            options={data}
            noOptionsText={defaultText}
            getOptionLabel={(option: any) => {
              return option[namePropName];
            }}
            renderOption={(props, option: any) => {
              return (
                <MenuItem
                  {...(props as any)}
                  key={option[valuePropName]}
                  value={option[valuePropName]}
                >
                  <Typography component="div" noWrap>
                    {option[namePropName]}
                  </Typography>
                </MenuItem>
              );
            }}
            isOptionEqualToValue={(option: any, value: any) => {
              return !option
                ? false
                : !value
                  ? false
                  : option[valuePropName].toLocaleLowerCase() ===
                      value[valuePropName].toLocaleLowerCase() ??
                    option[namePropName].toLocaleLowerCase() ===
                      value[namePropName].toLocaleLowerCase();
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  variant="outlined"
                  label={showLabel ? labelText : ""}
                  placeholder={labelText}
                  helperText={defaultText}
                  title={getValue()[namePropName]}
                >
                  <Typography component="span" noWrap>
                    {getValue()}
                  </Typography>
                </TextField>
              );
            }}
            value={getValue()}
            onChange={(event, value, reason, details) =>
              handleOptionSelect(event, value, reason, details)
            }
            onFocus={onFocus && onFocus}
          />
        </StyledAutocompleteWrapper>
      )}
    </>
  );
};
