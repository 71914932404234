import { Grid, useTheme } from "@mui/material";
import { IEvent } from "../../Interfaces";
import { StyledLogo } from "./EventListStyles";
import { SeriesName } from "../../Interfaces/SeriesName";
import { getSeriesLogoSrc } from "../Shared/SeriesLogos";
import { getEventLogoSrc } from "../../utils";

export const EventLogos = (selectedEvent: IEvent) => {
  const theme = useTheme();
  return (
    <>
      {selectedEvent && selectedEvent.Series && (
        <>
          <Grid item xs={1} pr={1.75} textAlign="center" alignSelf="center">
            {getSeriesLogoSrc(
              theme.palette.mode,
              selectedEvent?.Series?.Name as SeriesName,
            )}
          </Grid>
          <Grid item xs={1} pr={3} textAlign="center" alignSelf="center">
            {selectedEvent &&
              (selectedEvent.LogoBase64EncodedLight ||
                selectedEvent.LogoBase64EncodedDark) && (
                <StyledLogo
                  src={getEventLogoSrc(
                    theme.palette.mode,
                    selectedEvent.LogoBase64EncodedLight,
                    selectedEvent.LogoBase64EncodedDark,
                  )}
                  alt={"Event Logo for " + selectedEvent.Name}
                />
              )}
          </Grid>
        </>
      )}
    </>
  );
};
