import React, { forwardRef } from "react";
import {
  Typography,
  Slide,
  DialogActions,
  DialogContent,
  useTheme,
  useMediaQuery,
  Button,
  Grid,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { StyledDialog } from "../EventDialog/EventFormDialog.styles";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export type ConfirmationDialogProps = {
  open: boolean;
  onCancel: () => void;
  onClose: (accept: boolean) => void;
  confirmationQuestion: string;
};

export const ConfirmationDialog = ({
  open,
  onCancel,
  onClose,
  confirmationQuestion,
}: ConfirmationDialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <StyledDialog
      open={open}
      onClose={() => onCancel()}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
    >
      <DialogContent style={{ padding: "1.875rem" }}>
        <Grid
          container
          direction="column"
          justifyContent="left"
          alignItems="left"
          padding="1"
          width="100%"
          height="100%"
        >
          <Grid item paddingBottom={2}>
            <Typography variant="body1">{confirmationQuestion}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => onCancel()}
          aria-label="Cancel"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClose(true)}
          aria-label="Confirm"
        >
          Confirm
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
