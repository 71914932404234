import { useQuery } from "react-query";
import {
  IEventType,
  IRaceTeam,
  ISeries,
  ISessionType,
  ISite,
  ISponsor,
  ITimingScoring,
  IWeatherStation,
} from "../Interfaces";
import { LoggerService } from "./LoggerService";
import { createAdminRequest, createLookupRequest } from "./ServiceUtils";

enum LookupServiceUrl {
  getSeries = "api/public/series",
  getSites = "api/public/sites",
  getSitesWithSeries = "api/sites",
  getSponsors = "api/public/sponsors",
  getWeatherStations = "api/WeatherStation?seriesId=",
  getTimingScorings = "api/TimingScoring?seriesId=",
  getRaceTeams = "api/raceteams",
  getSiteVisitCount = "api/sitecount",
  getEventTypes = "api/public/eventtype",
  getSessionTypes = "api/public/sessiontype",
}
const DefaultCacheControlHeaderValue = "max-age=300, must-revalidate";

const getSeries = async (): Promise<ISeries[]> => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": DefaultCacheControlHeaderValue,
    },
  };
  const response = await fetch(LookupServiceUrl.getSeries, requestOptions);
  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("getSeries()", error, {
      url: LookupServiceUrl.getSeries,
    });
    throw new Error(error);
  }
  const data = await response.json();
  return data;
};

const getSitesWithSeries = async (): Promise<ISite[]> => {
  const requestOptions = createLookupRequest("GET");
  const response = await fetch(
    LookupServiceUrl.getSitesWithSeries,
    requestOptions,
  );

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("getSitesWithSeries()", error, {
      url: LookupServiceUrl.getSitesWithSeries,
      ...requestOptions,
    });
    throw new Error(error);
  }
  const data = await response.json();
  const sites = data.map((site: any) => {
    const mappedSite = {
      ...site,
    };
    return mappedSite;
  });
  return sites;
};

const getSites = async (): Promise<ISite[]> => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": DefaultCacheControlHeaderValue,
    },
  };
  const response = await fetch(LookupServiceUrl.getSites, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("getSites()", error, {
      url: LookupServiceUrl.getSites,
    });
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data.sort();
};

const getWeatherStations = async (
  seriesId: string,
): Promise<IWeatherStation[]> => {
  if (!seriesId) return [];
  const weatherStationsUrl = `${LookupServiceUrl.getWeatherStations}${seriesId}`;
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": DefaultCacheControlHeaderValue,
    },
  };
  const response = await fetch(weatherStationsUrl, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("getWeatherStations()", error, {
      url: weatherStationsUrl,
    });
    throw new Error(error);
  }

  const data = await response.json();
  return data;
};

const getSponsors = async (): Promise<ISponsor[]> => {
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": DefaultCacheControlHeaderValue,
    },
  };
  const response = await fetch(LookupServiceUrl.getSponsors, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("getSponsors()", error, {
      url: LookupServiceUrl.getSponsors,
    });

    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data;
};

const getTimingScorings = async (
  seriesId: string,
): Promise<ITimingScoring[]> => {
  if (!seriesId) return [];
  const requestOptions = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": DefaultCacheControlHeaderValue,
    },
  };
  const timingScoringUrl = `${LookupServiceUrl.getTimingScorings}${seriesId}`;
  const response = await fetch(timingScoringUrl, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("getTimingScorings()", error, {
      url: timingScoringUrl,
    });
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data;
};

export const getRaceTeams = async (seriesId: string): Promise<IRaceTeam[]> => {
  if (!seriesId) return [];
  const raceTeamUrl = `${LookupServiceUrl.getRaceTeams}?seriesId=${seriesId}`;
  const requestOptions = createAdminRequest("GET");
  const response = await fetch(raceTeamUrl, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("getRaceTeams", error, { url: raceTeamUrl });
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data;
};

const getEventTypes = async (): Promise<IEventType[]> => {
  const raceTeamUrl = `${LookupServiceUrl.getEventTypes}`;
  const requestOptions = createAdminRequest("GET");
  const response = await fetch(raceTeamUrl, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("getEventTypes", error, { url: raceTeamUrl });
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data;
};

const getSessionTypes = async (): Promise<ISessionType[]> => {
  const raceTeamUrl = `${LookupServiceUrl.getSessionTypes}`;
  const requestOptions = createAdminRequest("GET");
  const response = await fetch(raceTeamUrl, requestOptions);

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("getSessionTypes", error, { url: raceTeamUrl });
    throw new Error("Network response was not ok");
  }

  const data = await response.json();
  return data;
};

export const useSites = () => {
  return useQuery<ISite[]>("sites", getSites, {
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useEventTypes = () => {
  return useQuery<IEventType[]>("evenTypes", getEventTypes, {
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useSessionTypes = () => {
  return useQuery<ISessionType[]>("sessionTypes", getSessionTypes, {
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useSitesWithSeries = () => {
  return useQuery<ISite[]>("sitesWithSeries", getSitesWithSeries, {
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useSeries = () => {
  return useQuery<ISeries[]>("series", getSeries, {
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useWeatherStations = (seriesId: string) => {
  return useQuery<IWeatherStation[]>(
    ["weatherstations", seriesId],
    () => getWeatherStations(seriesId),
    {
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  );
};

export const useSponsors = () => {
  return useQuery<ISponsor[]>("sponsors", getSponsors, {
    staleTime: 1000 * 60 * 60 * 24,
    cacheTime: 1000 * 60 * 60 * 24,
  });
};

export const useTimingScorings = (seriesId: string) => {
  return useQuery<ITimingScoring[]>(
    ["timingscorings", seriesId],
    () => getTimingScorings(seriesId),
    {
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  );
};

export const useRaceTeams = (seriesId: string) => {
  return useQuery<IRaceTeam[]>(
    ["raceteams", seriesId],
    () => getRaceTeams(seriesId),
    {
      staleTime: 1000 * 60 * 60 * 24,
      cacheTime: 1000 * 60 * 60 * 24,
    },
  );
};

export const getSiteVisitCount = async (
  siteId: string,
  seriesId: string,
  year: number,
): Promise<number> => {
  if (!siteId || !seriesId || !year) {
    return -1;
  }

  const siteVisitCountParams = {
    siteId: siteId,
    seriesId: seriesId,
    year: year,
  };

  const params = Object.entries(siteVisitCountParams)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join("&");

  const siteVisitCountUrl = `${LookupServiceUrl.getSiteVisitCount}?${params}`;
  const requestOptions = createAdminRequest("GET");
  requestOptions.cache = "no-cache";
  try {
    const response = await fetch(siteVisitCountUrl, requestOptions);
    if (!response.ok) {
      const error = `${
        response.status
      } error has occurred: ${await response.text()}`;
      LoggerService.logError("getSiteVisitCount", error, {
        url: siteVisitCountUrl,
      });
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    return data["SiteVisitCount"];
  } catch (error: any) {
    console.error("Error:", error.message);
    throw error;
  }
};
