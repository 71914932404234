import React, { forwardRef, useEffect, useState } from "react";
import styled from "styled-components";
import moment, { Moment } from "moment-timezone";
import {
  Typography,
  Slide,
  DialogActions,
  DialogContent,
  TextField,
  useTheme,
  useMediaQuery,
  Button,
  DialogTitle,
  Grid,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { DateTimePickerContainer } from "../Shared/DateTimePickerContainer";
import { IEventSession } from "../../Interfaces";
import { StyledDialog } from "../EventDialog/EventFormDialog.styles";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogToolbarText = styled(Typography)`
  color: inherit;
`;

export type DelayDialogProps = {
  open: boolean;
  delayMinutes: string;
  setDelayMinutes: React.Dispatch<React.SetStateAction<string>>;
  onClose: (result: DelayDialogResult) => void;
  onCancel: () => void;
  activeSession: IEventSession;
  timeZone: string;
};

export type DelayDialogResult = {
  delayMinutes: number;
};

export const DelayDialog = ({
  open,
  delayMinutes,
  setDelayMinutes,
  onCancel,
  onClose,
  activeSession,
  timeZone,
}: DelayDialogProps) => {
  const [startTime, setStartTime] = useState("");
  const [error, setError] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setStartTime(activeSession.StartTime);
  }, [activeSession]);

  const handleKeyDown = (keyDownEvent: any) => {
    if (keyDownEvent.key === "Enter") {
      onClose && onClose({ delayMinutes: parseInt(delayMinutes) as number });
      keyDownEvent.preventDefault();
      return;
    }
  };

  const handleFieldChange = (changeEvent: any) => {
    const value = changeEvent.target.value;
    setError(isNaN(parseInt(value)));
    setStartTime(
      moment.utc(activeSession.StartTime).add(value, "minutes").format(),
    );
    setDelayMinutes(value);
  };

  const handleDateChange = (newValue: Moment | null) => {
    const originalStartTime = moment.utc(activeSession.StartTime).format();

    if (newValue) {
      const diff = newValue.diff(originalStartTime, "minutes").toString();
      setStartTime(newValue.format());
      setDelayMinutes(diff);
      setError(false);
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={() => onCancel()}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
    >
      <DialogTitle>
        <Typography variant="h4">Delay Session</Typography>
        <Typography variant="caption" fontStyle="italic">
          Session time is displayed in the Site time zone.
        </Typography>
      </DialogTitle>
      <DialogContent style={{ padding: "1.875rem" }}>
        <Grid
          container
          direction="column"
          justifyContent="left"
          alignItems="left"
          width="100%"
          height="100%"
          padding="1"
        >
          <Grid item paddingBottom={2}>
            <TextField
              id="delayMinutes"
              name="delayMinutes"
              label="By minutes"
              variant="standard"
              autoFocus={true}
              onKeyDown={handleKeyDown}
              helperText={error ? "Numeric value required" : ""}
              onChange={handleFieldChange}
              error={error}
              autoComplete="off"
              placeholder="0"
              value={delayMinutes}
            />
          </Grid>
          <Grid item>
            <DateTimePickerContainer
              label="To new start time"
              dateTimeToDisplay={moment(startTime)}
              disablePast={true}
              displayClockRenderer={true}
              onDateTimeChange={handleDateChange}
              valueTimeZone={timeZone}
            />
            {error && (
              <Typography variant="caption" color="error">
                New start must be after original start.
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => onCancel()}
          aria-label="Cancel"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onClose({ delayMinutes: parseInt(delayMinutes) })}
          aria-label="Update"
        >
          Update
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
