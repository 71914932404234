import { Grid, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { EventStatus, IEvent, IEventSession, ISession } from "../../Interfaces";
import { SnackbarMessage } from "../../Interfaces/SnackbarMessages";
import { LoggerService } from "../../Services/LoggerService";
import {
  useCancelSession,
  useDelaySession,
  useDeleteSessionById,
} from "../../Services/SessionService";
import { eventValidationSchema } from "../../Validations/AddEvent.Yup";
import { DelayDialog } from "../DialogDelay/DelayDialog";
import { SessionDialog } from "../DialogSession/SessionInputDialog";
import {
  DesktopActionsToolbar,
  MobileActionsToolbar,
} from "../SessionActions/ActionsToolbar";
import { SessionItemProps } from "./SessionItemProps";
import { StyledGrid } from "./SessionListStyles";
import { SessionText, SessionTime } from "./SessionText";
import { StatusDot } from "./StatusDot";

export const SessionItem = ({
  isMobile,
  isTablet,
  index,
  session,
  isAdmin,
  showSessionType,
  showDelayMenu,
  isSessionHardDelete,
  onSessionChange,
  useEventUpdate = false,
}: SessionItemProps) => {
  const [openDelayDialog, setOpenDelayDialog] = useState(false);
  const [isSessionDialogOpen, setSessionDialogOpen] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [delayMinutes, setDelayMinutes] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const {
    isLoading: delaySessionLoading,
    isSuccess: delaySessionSuccess,
    error: delaySessionError,
    mutate: delaySession,
    data: sessionsDelayed,
  } = useDelaySession();
  const {
    isLoading: cancelSessionLoading,
    isSuccess: cancelSessionSuccess,
    error: cancelSessionError,
    mutate: cancelSession,
    data: canceledSession,
  } = useCancelSession();
  const {
    isLoading: deleteSessionLoading,
    isSuccess: deleteSessionSuccess,
    error: deleteSessionError,
    mutate: deleteSession,
  } = useDeleteSessionById();

  useEffect(() => {
    // Delay Session
    if (delaySessionLoading) {
      enqueueSnackbar(SnackbarMessage.DelaySessionLoading, {
        variant: "info",
      });
    }
    if (delaySessionSuccess) {
      enqueueSnackbar(SnackbarMessage.DelaySessionSuccess, {
        variant: "success",
      });

      if (sessionsDelayed) {
        const updatedEventSession = sessionsDelayed as any;

        const updatedSession = {
          ...(updatedEventSession as IEventSession),
          ParentEvent: session.ParentEvent as IEvent,
        };

        onSessionChange(updatedSession, "Edit", true, false);
      }
    }
    if (delaySessionError) {
      enqueueSnackbar(SnackbarMessage.DelaySessionError, {
        variant: "error",
      });

      LoggerService.logError(
        "Delay session error occured:",
        delaySessionError?.message,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delaySessionError, delaySessionLoading, delaySessionSuccess]);

  useEffect(() => {
    // Cancel Session
    if (cancelSessionLoading) {
      enqueueSnackbar(
        isCanceling
          ? SnackbarMessage.CancelSessionLoading
          : SnackbarMessage.ActivateSessionLoading,
        {
          variant: "info",
        },
      );
    }
    if (cancelSessionSuccess) {
      enqueueSnackbar(
        isCanceling
          ? SnackbarMessage.CancelSessionSuccess
          : SnackbarMessage.ActivateSessionSuccess,
        {
          variant: "success",
        },
      );

      if (sessionsDelayed) {
        const updatedEventSession = sessionsDelayed as any;

        const updatedSession = {
          ...(updatedEventSession as IEventSession),
          ParentEvent: session.ParentEvent as IEvent,
        };

        onSessionChange(updatedSession, "Edit", true, false);
      }

      if (canceledSession) {
        const updatedEventSession = canceledSession as any;

        const updatedSession = {
          ...(updatedEventSession as IEventSession),
          ParentEvent: session.ParentEvent as IEvent,
        };

        onSessionChange(updatedSession, "Edit", true, false);
      }
    }
    if (cancelSessionError) {
      enqueueSnackbar(
        isCanceling
          ? SnackbarMessage.CancelSessionError
          : SnackbarMessage.ActivateSessionError,
        {
          variant: "error",
        },
      );

      LoggerService.logError(
        "Delay session error occured:",
        cancelSessionError?.message,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelSessionError, cancelSessionLoading, cancelSessionSuccess]);

  useEffect(() => {
    if (deleteSessionSuccess) {
      onSessionChange(session, "Delete", false, true);
    }

    if (deleteSessionError) {
      LoggerService.logError(
        "Delete session error occured:",
        deleteSessionError?.message,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteSessionLoading, deleteSessionSuccess, deleteSessionError]);

  const cancelDelay = () => {
    setDelayMinutes("");
    setOpenDelayDialog(false);
  };

  const setDelay = () => {
    const delay = {
      eventId: session.ParentEvent.EventId as string,
      sessionId: session?.SessionId as string,
      delayMinutes: parseInt(delayMinutes),
    };

    delaySession(delay);
    setOpenDelayDialog(false);
  };

  const handleCanceled = (sessionData: ISession) => {
    const currentState = sessionData?.Status;
    const isCanceling = EventStatus.Canceled === currentState ? false : true;

    const cancel = {
      eventId: session.ParentEvent.EventId as string,
      sessionId: sessionData.SessionId as string,
      isCanceled: isCanceling,
    };

    setIsCanceling(isCanceling);
    cancelSession(cancel);
  };

  const handleCloseSessionDialog = (): void => {
    setSessionDialogOpen(false);
  };

  const handleEdit = () => {
    setSessionDialogOpen(true);
  };

  const handleDelete = (sessionData: ISession) => {
    const updatedSessions = [...session.ParentEvent.Sessions!];
    const index = updatedSessions.findIndex(
      (s) => s.SessionId.toLowerCase() === sessionData?.SessionId.toLowerCase(),
    );
    updatedSessions.splice(index, 1);

    const updatedEvent = {
      ...session.ParentEvent,
      Sessions: updatedSessions,
    };
    eventValidationSchema("delete")
      .validate(updatedEvent)
      .then(() => {
        if (sessionData && isSessionHardDelete && !useEventUpdate) {
          deleteSession(sessionData.SessionId);
        }
        onSessionChange(
          { ...session, ParentEvent: updatedEvent },
          "Delete",
          false,
          true,
        );
      })
      .catch((error) => {
        enqueueSnackbar(SnackbarMessage.InvalidEventError, {
          variant: "error",
        });

        LoggerService.logError(
          "Invalid event error occured on:",
          `Error: ${error}, Data: ${JSON.stringify(updatedEvent)}`,
        );
      });
  };

  return (
    <>
      {isMobile || isTablet ? (
        <StyledGrid
          container
          direction="row"
          key={index}
          sx={{ paddingBottom: "1rem" }}
          rowNumber={index}
          isDarkMode={theme.palette.mode === "dark"}
          width="100%"
        >
          <Grid
            item
            xs={1}
            position="relative"
            top="0.125rem"
            textAlign="center"
            sx={{
              "@media (max-width: 700px)": {
                position: "relative",
                top: "0.875rem",
              },
            }}
          >
            {StatusDot(session)}
          </Grid>
          <Grid item xs={10}>
            <Grid container direction="column">
              {SessionText(session, isAdmin && showSessionType)}
              {SessionTime(session.ParentEvent, session, showSessionType)}
            </Grid>
          </Grid>
          {isAdmin && (
            <Grid item xs={1}>
              <MobileActionsToolbar
                selectedSession={session}
                onEdit={() => handleEdit()}
                onDelete={() => handleDelete(session)}
                onCanceled={() => handleCanceled(session)}
                onDelay={() => setOpenDelayDialog(true)}
                showDelayMenu={showDelayMenu}
              />
            </Grid>
          )}
        </StyledGrid>
      ) : (
        <StyledGrid
          container
          direction="row"
          key={index}
          rowNumber={index}
          isDarkMode={theme.palette.mode === "dark"}
        >
          <Grid
            item
            xs={1}
            position="relative"
            top="0.125em"
            textAlign="center"
            sx={{
              "@media (max-width: 700px)": {
                position: "relative",
                top: "0.875rem",
              },
            }}
          >
            {StatusDot(session)}
          </Grid>
          <Grid item xs={8} lg={9}>
            {SessionText(session, isAdmin && showSessionType)}
            {SessionTime(session.ParentEvent, session, showSessionType)}
          </Grid>
          {isAdmin && (
            <DesktopActionsToolbar
              selectedSession={session}
              onEdit={() => handleEdit()}
              onDelete={() => handleDelete(session)}
              onCanceled={() => handleCanceled(session)}
              onDelay={() => setOpenDelayDialog(true)}
              showDelayMenu={showDelayMenu}
            />
          )}
        </StyledGrid>
      )}
      {openDelayDialog && (
        <DelayDialog
          open={openDelayDialog}
          delayMinutes={delayMinutes}
          setDelayMinutes={setDelayMinutes}
          onCancel={cancelDelay}
          onClose={setDelay}
          activeSession={session}
          timeZone={session.ParentEvent.Site?.TimeZoneOffset as string}
        />
      )}
      {session && (
        <SessionDialog
          open={isSessionDialogOpen}
          onClose={handleCloseSessionDialog}
          selectedEvent={session.ParentEvent}
          existingSessions={session.ParentEvent.Sessions!}
          selectedSession={session}
          isUpdateMode={true}
          onUpdateSession={(updatedSession) => {
            onSessionChange(
              {
                ...updatedSession,
                ParentEvent: session.ParentEvent,
              },
              "Edit",
              false,
              false,
            );
          }}
        />
      )}
    </>
  );
};
