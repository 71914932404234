export enum SnackbarMessage {
  CreateEventLoading = "Creating event.",
  CreateEventSuccess = "Event has been created.",
  CreateEventError = "An error occured while creating an event.",
  UpdateEventLoading = "Updating event.",
  UpdateEventSuccess = "Event has been updated.",
  UpdateEventError = "An error occured while updating an event.",
  DeleteEventLoading = "Deleting event.",
  DeleteEventSuccess = "Event has been deleted.",
  DeleteEventError = "An error occured while deleting an event.",
  InvalidEventError = "Event is invalid.",
  InvalidEventInSession = "Session is invalid in event.",
  UpdateSessionLoading = "Updating session.",
  UpdateSessionSuccess = "Session has been updated.",
  UpdateSessionError = "An error occured while updating a session.",
  DeleteSessionLoading = "Deleting session.",
  DeleteSessionSuccess = "Session has been deleted.",
  DeleteSessionError = "An error occured while deleting a session.",
  DelaySessionLoading = "Delaying session.",
  DelaySessionSuccess = "Session has been delayed.",
  DelaySessionError = "An error occured while delaying a session.",
  CancelSessionLoading = "Canceling session.",
  CancelSessionSuccess = "Session has been canceled.",
  CancelSessionError = "An error occured while canceling a session.",
  ActivateSessionLoading = "Activating session.",
  ActivateSessionSuccess = "Session activated.",
  ActivateSessionError = "An error occured while activating a session.",
}
