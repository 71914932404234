import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { IEvent, IEventSession, ISession, ISponsor } from "../../Interfaces";
import { GridFlexCenter } from "../App/App.styles";
import { SessionDialog } from "../DialogSession/SessionInputDialog";
import { StyledLogo } from "../ListEvents/EventListStyles";
import { DeleteButton } from "../Shared/DeleteButton";
import { EditButton } from "../Shared/EditButton";
import { SessionItem } from "./SessionItem";
import { SessionOperations } from "./SessionItemProps";
import { StyledDesktopDiv, StyledMobileDiv } from "./SessionListStyles";
import { SessionTitleBar } from "./SessionTitleBar";

export type SessionDetailsProps = {
  parentEvent: IEvent;
  sponsors: ISponsor[] | undefined;
  useEventUpdate?: boolean;
  isAdmin: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isSessionHardDelete: boolean;
  onSessionsUpdated: (
    updatedSessions: IEventSession[],
    isDelayedOrCanceled: boolean,
    isDelete: boolean,
  ) => void;
  onSessionDeleted?: (sessionIdToDelete: string) => void;
  refetchSessions?: () => void;
  showTitleBar?: boolean;
  showDelayMenu?: boolean;
  showSessionType?: boolean;
  shouldAlternateSessionRows?: boolean;
  onEditEvent?: () => void; // supports mobile view
  onDeleteEvent?: () => void; // supports mobile view
  showEditDeleteEvent?: boolean; // supports mobile view
};

export const SessionList = ({
  isSessionHardDelete,
  parentEvent,
  sponsors,
  onSessionsUpdated,
  onEditEvent,
  onDeleteEvent,
  isMobile,
  isTablet,
  showEditDeleteEvent = false,
  showTitleBar = false,
  showDelayMenu = false,
  isAdmin = false,
  showSessionType = false,
  useEventUpdate = false,
}: SessionDetailsProps) => {
  const [sessions, setSessions] = useState<IEventSession[]>([]);
  const [isSessionUpdateMode, setIsSessionUpdateMode] = useState(false);
  const [isSessionDialogOpen, setSessionDialogOpen] = useState(false);
  const [selectedSession, setSelectedSession] = useState<IEventSession>();

  useEffect(() => {
    setSessions(parentEvent.Sessions ?? []);
  }, [parentEvent.Sessions]);

  const handleAddSession = (sessionData: IEventSession) => {
    onSessionsUpdated([...sessions, sessionData], false, false);
  };

  const handleCloseSessionDialog = (): void => {
    setSessionDialogOpen(false);
    setSelectedSession(undefined);
  };

  const onAddSession = (): void => {
    setIsSessionUpdateMode(false);
    setSelectedSession(undefined);
    setSessionDialogOpen(true);
  };

  const handleSessionChange = (
    changedSession: ISession,
    operation: SessionOperations,
    isDelayedOrCanceled: boolean,
    isDelete: boolean,
  ) => {
    let updatedSessions = [...sessions];

    const index = updatedSessions.findIndex(
      (s) =>
        s.SessionId?.toLowerCase() === changedSession?.SessionId?.toLowerCase(),
    );

    switch (operation) {
      case "Edit":
        updatedSessions[index] = changedSession;
        break;
      case "Delete":
        updatedSessions = updatedSessions.filter(
          (s) =>
            s.SessionId.toLowerCase() !==
            changedSession.SessionId.toLowerCase(),
        );
        break;
    }
    setSessions(updatedSessions);
    onSessionsUpdated(updatedSessions, isDelayedOrCanceled, isDelete);
  };

  const handleEditEvent = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onEditEvent) {
      onEditEvent();
    }
  };

  const handleDeleteEvent = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (onDeleteEvent) {
      onDeleteEvent();
    }
  };

  return (
    <>
      <StyledMobileDiv key={`${parentEvent.EventId}-sessionList-mobile`}>
        <Grid container direction="column" rowSpacing={1} paddingTop={2}>
          {showTitleBar && (
            <SessionTitleBar
              disabled={!parentEvent.Site?.TimeZoneOffset}
              onAddSession={onAddSession}
            />
          )}
          {sessions?.length > 0 && (
            <Grid container direction="column">
              {isAdmin && showEditDeleteEvent && (
                <Grid container direction="row" justifyContent="center">
                  <DeleteButton
                    sx={{ position: "relative", bottom: "0.25rem" }}
                    onDelete={handleDeleteEvent}
                  />
                  <EditButton
                    sx={{ position: "relative", bottom: "0.25rem" }}
                    onEdit={handleEditEvent}
                  />
                </Grid>
              )}
              {showEditDeleteEvent && (
                <GridFlexCenter container direction="row" position="relative">
                  {parentEvent?.SponsorIds?.split(",")?.map(
                    (sponsorId: string) => {
                      const sponsor = sponsors?.find(
                        (s: { SponsorId: string }) => s.SponsorId === sponsorId,
                      );

                      return (
                        <a href={sponsor?.URL} target="_blank" rel="noreferrer">
                          <StyledLogo
                            style={{
                              margin: "0rem 0.25rem",
                              maxWidth: "4rem",
                            }}
                            className="sponsor"
                            src={sponsor?.LogoBase64Encoded}
                            alt={sponsor?.Name}
                          />
                        </a>
                      );
                    },
                  )}
                </GridFlexCenter>
              )}
              {sessions.map((session, index) => (
                <SessionItem
                  isMobile={isMobile}
                  isTablet={isTablet}
                  isSessionHardDelete={isSessionHardDelete}
                  index={index}
                  session={{ ...session, ParentEvent: parentEvent }}
                  isAdmin={isAdmin}
                  showSessionType={showSessionType}
                  onSessionChange={(
                    changedSession,
                    operation,
                    isDelayedOrCanceled,
                    isDelete,
                  ) =>
                    handleSessionChange(
                      changedSession,
                      operation,
                      isDelayedOrCanceled,
                      isDelete,
                    )
                  }
                  showDelayMenu={showDelayMenu}
                  useEventUpdate={useEventUpdate}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </StyledMobileDiv>
      <StyledDesktopDiv key={`${parentEvent.EventId}-sessionList-desktop`}>
        <Grid container direction="column" rowSpacing={1} padding={2}>
          {showTitleBar && (
            <SessionTitleBar
              onAddSession={onAddSession}
              disabled={!parentEvent.Site?.TimeZoneOffset}
            />
          )}
          {sessions?.length > 0 && (
            <Grid container direction="column">
              {sessions.map((session, index) => (
                <SessionItem
                  isMobile={isMobile}
                  isTablet={isTablet}
                  isSessionHardDelete={isSessionHardDelete}
                  index={index}
                  session={{ ...session, ParentEvent: parentEvent }}
                  isAdmin={isAdmin}
                  showSessionType={showSessionType}
                  onSessionChange={(
                    changedSession,
                    operation,
                    isDelayedOrCanceled,
                    isDelete,
                  ) =>
                    handleSessionChange(
                      changedSession,
                      operation,
                      isDelayedOrCanceled,
                      isDelete,
                    )
                  }
                  showDelayMenu={showDelayMenu}
                  useEventUpdate={useEventUpdate}
                />
              ))}
            </Grid>
          )}
        </Grid>
      </StyledDesktopDiv>
      {sessions && (
        <SessionDialog
          open={isSessionDialogOpen}
          onClose={handleCloseSessionDialog}
          onAddSession={handleAddSession}
          selectedEvent={parentEvent}
          existingSessions={sessions}
          selectedSession={selectedSession}
          isUpdateMode={isSessionUpdateMode}
        />
      )}
    </>
  );
};
