import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Grid, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { DelayMenu } from "./DelayMenu";
import { IEventSession } from "../../Interfaces";
import { EditIconButton } from "../Shared/EditIconButton";
import { DeleteIconButton } from "../Shared/DeleteIconButton";
import React, { useState } from "react";

export type ActionsToolbarProps = {
  selectedSession: IEventSession;
  onEdit: (sessionId: string) => void;
  onDelete: (sessionId: string) => void;
  onDelay?: (sessionId: string) => void;
  onCanceled?: (sessionId: string) => void;
  showDelayMenu?: boolean;
};

export const DesktopActionsToolbar = ({
  selectedSession,
  onEdit,
  onDelay,
  onCanceled,
  onDelete,
  showDelayMenu = false,
}: ActionsToolbarProps) => {
  const rowId = selectedSession.SessionId;
  return (
    <>
      <Grid item>
        <EditIconButton
          onEdit={(_event, entityId) => onEdit(entityId!)}
          entityId={rowId}
        />
      </Grid>
      <Grid item>
        <DeleteIconButton
          onDelete={(_event, entityId) => onDelete(entityId!)}
          entityId={rowId}
        />
      </Grid>
      {showDelayMenu && onDelay && onCanceled && (
        <Grid item>
          <DelayMenu
            selectedSession={selectedSession}
            onDelay={onDelay}
            onCanceled={onCanceled}
          />
        </Grid>
      )}
    </>
  );
};

export const MobileActionsToolbar = ({
  selectedSession,
  onEdit,
  onDelay,
  onCanceled,
  onDelete,
  showDelayMenu = false,
}: ActionsToolbarProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const rowId = selectedSession.SessionId;
  return (
    <Tooltip title="Actions">
      <>
        <IconButton
          aria-label="Actions"
          aria-controls={open ? "actions-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          id="actions-menu-button"
          onClick={handleClick}
          color="inherit"
          sx={{ justifyContent: "end" }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="actions-menu"
          MenuListProps={{ "aria-labelledby": "actions-menu-button" }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem>
            <EditIconButton
              onEdit={(_event, entityId) => onEdit(entityId!)}
              entityId={rowId}
            />
          </MenuItem>
          <MenuItem>
            <DeleteIconButton
              onDelete={(_event, entityId) => onDelete(entityId!)}
              entityId={rowId}
            />
          </MenuItem>
          {showDelayMenu && onDelay && onCanceled && (
            <MenuItem>
              <DelayMenu
                selectedSession={selectedSession}
                onDelay={onDelay}
                onCanceled={onCanceled}
              />
            </MenuItem>
          )}
        </Menu>
      </>
    </Tooltip>
  );
};
