import { EventType_Race } from "../../Interfaces";
import { useEventTypes } from "../../Services/LookupService";
import { ValueSelect } from "./ValueSelect";

export type EventTypeSelectProps = {
  selectedEventType: string | null;
  onEventTypeChange: (value: string) => void;
  onFocus?: () => void;
  showLabel?: boolean;
  defaultText?: string;
};

export const EventTypeSelect = ({
  selectedEventType,
  onEventTypeChange,
  onFocus = undefined,
  showLabel = false,
  defaultText = "",
}: EventTypeSelectProps) => {
  const { data: eventTypes } = useEventTypes();

  return (
    <>
      {eventTypes && (
        <ValueSelect
          labelText="Event Type"
          showLabel={showLabel}
          defaultText={defaultText}
          onValueChange={onEventTypeChange}
          onFocus={onFocus}
          data={eventTypes}
          namePropName="Name"
          valuePropName="Name"
          selectedValue={selectedEventType ? selectedEventType : EventType_Race}
        />
      )}
    </>
  );
};
