import { useSeries } from "../../Services/LookupService";
import { ValueSelect } from "./ValueSelect";

export type SeriesSelectProps = {
  selectedSeries: string | null;
  onSeriesChange: (value: string) => void;
  onFocus?: () => void;
  showLabel?: boolean;
  defaultText?: string;
};

export const SeriesSelect = ({
  selectedSeries,
  onSeriesChange,
  onFocus = undefined,
  showLabel = false,
  defaultText = "",
}: SeriesSelectProps) => {
  const { data: seriesData } = useSeries();

  return (
    <>
      {seriesData && (
        <ValueSelect
          labelText="Series"
          showLabel={showLabel}
          defaultText={defaultText}
          onValueChange={onSeriesChange}
          onFocus={onFocus}
          data={seriesData}
          namePropName="Name"
          valuePropName="SeriesId"
          selectedValue={selectedSeries}
        />
      )}
    </>
  );
};
