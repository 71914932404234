import { Typography } from "@mui/material";
import { IEvent } from "../../Interfaces";

export const EventText = (selectedEvent: IEvent) => {
  return (
    <>
      {selectedEvent && (
        <>
          <Typography
            component="div"
            fontWeight="bolder"
            fontSize={{
              lg: 18,
              md: 16,
              sm: 16,
              xs: 16,
            }}
            sx={{
              // Mobile
              "@media only screen and (min-width: 0px)": {
                minWidth: "10.5rem",
                maxWidth: "13.5rem",
              },
              "@media only screen and (min-width: 400px)": {
                minWidth: "12.5rem",
                maxWidth: "13.5rem",
              },
              "@media only screen and (min-width: 430px)": {
                minWidth: "13rem",
                maxWidth: "13.5rem",
              },
              "@media only screen and (min-width: 500px)": {
                minWidth: "16.5rem",
                maxWidth: "22rem",
              },
              // Tablet
              "@media only screen and (min-width: 700px)": {
                minWidth: "19rem",
                maxWidth: "22rem",
              },
              // Laptop
              "@media only screen and (min-width: 1000px)": {
                minWidth: "13.5rem",
                maxWidth: "22rem",
              },
              "@media only screen and (min-width: 1200px)": {
                minWidth: "15.5rem",
                maxWidth: "22rem",
              },
              // Desktop
              "@media only screen and (min-width: 1400px)": {
                minWidth: "18.5rem",
                maxWidth: "100%",
              },
            }}
          >
            {selectedEvent.Name}
            <Typography variant="body2" component="span" display="block">
              {selectedEvent?.Site?.Name}
            </Typography>
          </Typography>
        </>
      )}
    </>
  );
};
