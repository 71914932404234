const DefaultCacheControlHeaderValue = "max-age=300, must-revalidate";

export const createLookupRequest = (
  method: "GET" | "POST",
  requestBody?: any,
): RequestInit => {
  const requestOptions: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": DefaultCacheControlHeaderValue,
    },
  };
  if (requestBody) {
    requestOptions["body"] = JSON.stringify(requestBody);
  }
  return requestOptions;
};

export const createAdminRequest = (
  method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
  requestBody?: any,
): RequestInit => {
  const requestOptions: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
      "X-MS-API-ROLE": "admin",
      "Cache-Control": `private, ${DefaultCacheControlHeaderValue}`,
    },
  };
  if (requestBody) {
    requestOptions["body"] = JSON.stringify(requestBody);
  }
  return requestOptions;
};
