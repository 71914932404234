import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { SeriesName } from "../../Interfaces/SeriesName";
import { ISeriesFilter } from "../../Interfaces/ISeriesFilter";
import { getSeriesLogoSrc } from "../Shared/SeriesLogos";
import { DarkTheme, LightTheme } from "../App/Theme";
import { boxButtonStyle } from "../App/App.styles";

export const SeriesFilter: React.FC<ISeriesFilter> = ({
  series,
  onSeriesChange,
  selectedSeries,
}: ISeriesFilter) => {
  const theme = useTheme();

  const seriesLogoStyle = () => {
    return {
      cursor: "pointer",
      maxWidth: "100%",
      minHeight: "2.5rem",
      maxHeight: "2.5rem",
      objectFit: "cover",
      verticalAlign: "middle",
    };
  };

  const renderSeries = () => {
    return (
      series &&
      series.map((s) => {
        return (
          <Grid
            item
            key={s.Name}
            xs={1}
            tabIndex={1}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                onSeriesChange(s.SeriesId);
              }
            }}
          >
            <Box
              sx={{
                ...boxButtonStyle(theme, false),
                lineHeight: "1rem",
                padding: "0.2rem 0rem",
              }}
            >
              {getSeriesLogoSrc(
                theme.palette.mode,
                s.Name.toUpperCase() as SeriesName,
                {
                  ...seriesLogoStyle(),
                  opacity: selectedSeries?.some(
                    (selected) => selected.Name === s.Name,
                  )
                    ? "1"
                    : !selectedSeries
                      ? "1"
                      : "0.4",
                },
                () => {
                  onSeriesChange(s.SeriesId);
                },
              )}
            </Box>
            <Typography
              display="block"
              lineHeight="1rem"
              mt={0.5}
              aria-label={s.Name}
              variant="caption"
              color={
                theme.palette.mode === "dark"
                  ? DarkTheme.fontColor
                  : LightTheme.fontColor
              }
            >
              {s.Name}
            </Typography>
          </Grid>
        );
      })
    );
  };

  return (
    <Grid
      container
      margin="0rem 0.5rem"
      minHeight="6rem"
      maxHeight="6rem"
      minWidth="100%"
      maxWidth="40rem"
      alignItems="baseline"
      rowGap={1}
      columnGap={1}
    >
      <Grid
        item
        xs={1.1}
        tabIndex={1}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSeriesChange(SeriesName.Nascar);
          }
        }}
      >
        <Box
          sx={{
            ...boxButtonStyle(theme, false),
            padding: "0.2rem 0rem",
          }}
        >
          {getSeriesLogoSrc(
            theme.palette.mode,
            SeriesName.Nascar,
            {
              ...seriesLogoStyle(),
              opacity:
                selectedSeries?.some(
                  (selected) =>
                    selected.Name.toUpperCase() === SeriesName.NascarCup,
                ) &&
                selectedSeries?.some(
                  (selected) =>
                    selected.Name.toUpperCase() === SeriesName.NascarXfinity,
                ) &&
                selectedSeries?.some(
                  (selected) =>
                    selected.Name.toUpperCase() ===
                    SeriesName.NascarCraftsmanTruck,
                )
                  ? "1"
                  : !selectedSeries
                    ? "1"
                    : "0.4",
            },
            () => {
              onSeriesChange(SeriesName.Nascar);
            },
          )}
        </Box>
        <Typography
          display="block"
          lineHeight="1rem"
          mt={0.5}
          aria-label="NASCAR"
          variant="caption"
          color={
            theme.palette.mode === "dark"
              ? DarkTheme.fontColor
              : LightTheme.fontColor
          }
        >
          NASCAR
        </Typography>
      </Grid>
      <Grid
        item
        xs={1}
        tabIndex={1}
        mr={3}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            onSeriesChange(SeriesName.IMSA_WEC);
          }
        }}
      >
        <Box
          sx={{
            ...boxButtonStyle(theme, false),
            padding: "0.2rem 0rem",
          }}
        >
          {getSeriesLogoSrc(
            theme.palette.mode,
            SeriesName.IMSA_WEC,
            {
              ...seriesLogoStyle(),
              opacity:
                selectedSeries?.some(
                  (selected) => selected.Name.toUpperCase() === SeriesName.IMSA,
                ) &&
                selectedSeries?.some(
                  (selected) => selected.Name.toUpperCase() === SeriesName.WEC,
                )
                  ? "1"
                  : !selectedSeries
                    ? "1"
                    : "0.4",
            },
            () => {
              onSeriesChange(SeriesName.IMSA_WEC);
            },
          )}
        </Box>
        <Typography
          display="block"
          lineHeight="1rem"
          mt={0.5}
          aria-label="WEC/IMSA"
          variant="caption"
          color={
            theme.palette.mode === "dark"
              ? DarkTheme.fontColor
              : LightTheme.fontColor
          }
        >
          WEC/IMSA
        </Typography>
      </Grid>
      {renderSeries()}
      <Grid item sm={1}>
        <Button
          tabIndex={1}
          variant="outlined"
          sx={{
            color: theme.palette.mode === "dark" ? "white" : "#005dab",
            fontWeight: "bold",
            padding: "0.75rem",
            lineHeight: "1",
            opacity:
              series &&
              selectedSeries &&
              series.length !== selectedSeries.length
                ? "1"
                : "0",
            cursor:
              series &&
              selectedSeries &&
              series.length !== selectedSeries.length
                ? "pointer"
                : "default",
          }}
          onClick={() => {
            onSeriesChange("ShowAll");
          }}
        >
          Show All
        </Button>
      </Grid>
    </Grid>
  );
};
