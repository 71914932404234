import * as yup from "yup";
import moment from "moment-timezone";
import { IEventSession } from "../Interfaces";
import { LoggerService } from "../Services/LoggerService";

interface SessionTestContextExtended {
  from: {
    value: IEventSession;
  }[];
}

export const sessionValidationSchema = (existingSessions: IEventSession[]) =>
  yup.object().shape({
    Name: yup
      .string()
      .trim()
      .test("duplicateName", "Session name already exists.", function (value) {
        if (!value) return true;
        const { SessionId } = this.parent;
        LoggerService.logInfo(
          "sessionValidationSchema()",
          `validatingName: ${this}`,
        );
        return !existingSessions?.some(
          (session) =>
            session?.Name.toLowerCase() === value.toLocaleLowerCase() &&
            session?.SessionId !== SessionId,
        );
      })
      .required("Session Name is required"),
    StartTime: yup
      .date()
      .test(
        "duplicateStart",
        "Session with start time already exists.",
        function (value) {
          if (!value) return true;
          const { from } = this as yup.TestContext & SessionTestContextExtended;
          return !existingSessions?.some((session) => {
            return (
              moment.utc(value).isSame(moment.utc(session?.StartTime)) &&
              session?.SessionId !== from[0].value.SessionId
            );
          });
        },
      )
      .required("Start Time is required"),
    EndTime: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .test(
        "start after end",
        "End time must be after Start time.",
        function (value) {
          if (!value) return true;

          const { from } = this as yup.TestContext & SessionTestContextExtended;
          if (
            moment.utc(from[0].value.StartTime) &&
            moment.utc(value) &&
            moment.utc(value).isBefore(moment.utc(from[0].value.StartTime))
          ) {
            return false;
          }
          return true;
        },
      ),
    SessionType: yup.string().trim().required("Session Type is required"),
  });
