import { Dialog, FormHelperText } from "@mui/material";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    .custom-select {
      width: 70%;
    }

    @media (min-width: 1080px) {
      width: 70%;
    }
  }
`;

export const StyledErrorMessage = styled.div`
  color: red;
  padding-top: 0.25rem;
  font-size: 0.8rem;
  font-family: Overpass, Roboto, Helvetica, Arial, sans-serif;
`;

export const StyledFormHelper = styled(FormHelperText)`
  color: red;
`;
