export enum SeriesName {
  Nascar = "NASCAR",
  NascarCup = "NASCAR CUP",
  NascarXfinity = "NASCAR XFINITY",
  NascarCraftsmanTruck = "NASCAR CRAFTSMAN TRUCK",
  Formula1 = "FORMULA 1",
  NTTIndyCar = "NTT INDYCAR",
  IMSA_WEC = "IMSA/WEC",
  IMSA = "IMSA",
  WEC = "WEC",
  LMDh = "LMDH",
}
