import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import {
  EventStatus,
  IEvent,
  IEventSession,
  SessionType_Practice,
} from "../../Interfaces";
import { sessionValidationSchema } from "../../Validations/AddSession.Yup";
import { StyledDialog } from "../EventDialog/EventFormDialog.styles";
import { DateTimePickerContainer } from "../Shared/DateTimePickerContainer";
import { SessionTypeSelect } from "../Shared/SessionTypeSelect";

interface SessionDialogProps {
  open: boolean;
  onClose: () => void;
  onAddSession?: (sessionData: IEventSession) => void;
  onUpdateSession?: (updatedSession: IEventSession) => void;
  selectedEvent: IEvent;
  existingSessions: IEventSession[];
  selectedSession?: IEventSession;
  isUpdateMode: boolean;
}

const initialValidationErrors = {
  Name: "",
  StartTime: "",
  EndTime: "",
  Type: "",
};

const initialSession = (eventId: string, sessionId: string): IEventSession => {
  return {
    SessionId: sessionId,
    Name: "",
    StartTime: "",
    EndTime: "",
    Status: EventStatus.Ok,
    EventId: eventId,
    CreatedOn: new Date(Date.now()),
    UpdatedOn: new Date(Date.now()),
    SessionType: SessionType_Practice,
  };
};

export const SessionDialog: React.FC<SessionDialogProps> = ({
  open,
  existingSessions,
  selectedEvent,
  selectedSession = undefined,
  onClose,
  onAddSession,
  onUpdateSession = undefined,
  isUpdateMode = false,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [validationErrors, setValidationErrors] = useState(
    initialValidationErrors,
  );
  const [activeSession, setActiveSession] = useState<IEventSession>(
    selectedSession ??
      initialSession(selectedEvent.EventId as string, uuidv4()),
  );

  useEffect(() => {
    if (selectedSession) {
      setActiveSession(selectedSession);
    } else {
      setActiveSession(
        initialSession(selectedEvent.EventId as string, uuidv4()),
      );
    }
  }, [selectedSession, selectedEvent, open]);

  const displayValidationErrors = (error: any) => {
    const newValidationErrors = { ...initialValidationErrors };

    if (error.inner.length > 1) {
      error.inner.forEach((validationError: yup.ValidationError) => {
        newValidationErrors[
          validationError.path as keyof typeof newValidationErrors
        ] = validationError.message;
      });
    } else {
      newValidationErrors[error.path as keyof typeof newValidationErrors] =
        error.message;
    }

    setValidationErrors(newValidationErrors);
  };

  const handleAddSession = () => {
    const sessionToAdd: IEventSession = {
      ...activeSession,
    };
    sessionValidationSchema(existingSessions)
      .validate(sessionToAdd)
      .then(() => {
        sessionToAdd.Status = "Ok";
        onAddSession && onAddSession(sessionToAdd);
        setValidationErrors(initialValidationErrors);
        onClose();
      })
      .catch((error) => {
        displayValidationErrors(error);
      });
  };

  const handleUpdateSession = () => {
    const sessionIndex = existingSessions.findIndex(
      (session) => session.SessionId === activeSession.SessionId,
    );

    if (sessionIndex !== -1) {
      const sessionToUpdate: IEventSession = {
        ...selectedSession,
        ...{
          ...activeSession,
          StartTime: moment(activeSession.StartTime).utc().format(),
          EndTime:
            activeSession.EndTime &&
            moment(activeSession.EndTime).utc().format(),
        },
      };
      sessionValidationSchema(existingSessions)
        .validate(sessionToUpdate)
        .then(() => {
          onUpdateSession && onUpdateSession(sessionToUpdate);
          setValidationErrors(initialValidationErrors);
          onClose();
        })
        .catch((error) => {
          displayValidationErrors(error);
        });
    }
  };

  const handleCancel = () => {
    setValidationErrors(initialValidationErrors);
    onClose();
  };

  const handleChange = (fieldName: string, newValue: any) => {
    let valueAsString = newValue;

    if (["StartTime", "EndTime"].some((s) => s === fieldName)) {
      valueAsString = moment(newValue).utc();
    }

    const changedSession = {
      ...activeSession,
      [fieldName]: valueAsString,
    };
    setActiveSession(changedSession);
  };

  const handleNameBlur = () => {
    sessionValidationSchema(existingSessions)
      .validateAt("Name", {
        SessionId: activeSession.SessionId,
        Name: activeSession.Name,
      })
      .then(() => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          Name: "",
        }));
      })
      .catch((error) => {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          Name: error.message,
        }));
      });
  };

  const hasRequiredFields = (): boolean =>
    !activeSession.Name ||
    !activeSession.StartTime ||
    !activeSession.SessionType;

  return (
    <StyledDialog open={open} onClose={onClose} fullScreen={fullScreen}>
      <DialogTitle>
        <Typography variant="h4">
          {isUpdateMode ? "Update Session" : "Add Session"}
        </Typography>
        <Typography variant="caption" fontStyle="italic">
          Session times displayed in Site time zone.
        </Typography>
      </DialogTitle>

      <DialogContent>
        {activeSession && (
          <Grid
            container
            direction="column"
            justifyContent="left"
            alignItems="left"
            rowSpacing={2}
          >
            <Grid item>
              <TextField
                label="Session Name"
                variant="standard"
                value={activeSession.Name}
                onChange={(event: { target: { value: any } }) => {
                  const value = event.target.value;
                  handleChange("Name", value);
                }}
                onBlur={handleNameBlur}
                error={!!validationErrors.Name}
                helperText={validationErrors.Name}
                autoFocus
                autoComplete="off"
                tabIndex={0}
              />
            </Grid>
            <Grid item>
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <SessionTypeSelect
                  showLabel={true}
                  selectedSessionType={activeSession?.SessionType}
                  onSessionTypeChange={(value: string) => {
                    handleChange("SessionType", value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item>
              <DateTimePickerContainer
                label="Site Start Time"
                dateTimeToDisplay={moment(activeSession.StartTime)}
                disablePast={true}
                displayClockRenderer={true}
                onDateTimeChange={(newValue: any) =>
                  handleChange("StartTime", newValue)
                }
                valueTimeZone={selectedEvent.Site?.TimeZoneOffset as string}
              />
              {validationErrors.StartTime && (
                <Typography color="error">
                  {validationErrors.StartTime}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <DateTimePickerContainer
                label="Site End Time"
                dateTimeToDisplay={moment(
                  activeSession.EndTime || activeSession.StartTime,
                )}
                disablePast={true}
                displayClockRenderer={true}
                onDateTimeChange={(newValue: any) =>
                  handleChange("EndTime", newValue)
                }
                disabled={!activeSession.StartTime}
                valueTimeZone={selectedEvent.Site?.TimeZoneOffset as string}
              />
              {validationErrors.EndTime && (
                <Typography color="error">
                  {validationErrors.EndTime}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
        {isUpdateMode ? (
          <Button
            disabled={hasRequiredFields()}
            onClick={handleUpdateSession}
            title="Update"
            variant="contained"
          >
            Update
          </Button>
        ) : (
          <Button
            disabled={hasRequiredFields()}
            onClick={handleAddSession}
            title="Add"
            variant="contained"
          >
            Add
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};
