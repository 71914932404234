import { Grid } from "@mui/material";
import styled from "styled-components";

export interface IStyledGridProps {
  rowNumber: number;
  isDarkMode: boolean;
  shouldAlternateSessionRows?: boolean;
}

export const StyledMobileDiv = styled.div`
  display: flex;

  @media (max-width: 901px) {
    display: block;
  }

  @media (min-width: 900px) {
    display: none;
  }
`;

export const StyledDesktopDiv = styled.div`
  display: flex;

  @media (max-width: 901px) {
    display: none;
  }

  @media (min-width: 900px) {
    display: block;
  }
`;

export const StyledGrid = styled(Grid)<IStyledGridProps>`
  display: flex;
  padding: 0.5rem 0;
  background-color: ${(props) =>
    props.isDarkMode
      ? props.rowNumber % 2 === 1
        ? "#2D2D2D !important"
        : "#333333"
      : props.rowNumber % 2 === 1
        ? "#e7e7e7 !important"
        : "#f6f6f6"};
  color: ${(props) =>
    props.isDarkMode
      ? props.rowNumber % 2 === 1
        ? "#fff !important"
        : "#fff"
      : props.rowNumber % 2 === 1
        ? "#000 !important"
        : ""};
`;

export const StyledStatusDot = styled.span`
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  border: 0.063rem solid rgba(0, 0, 0, 0.125);
  box-shadow: 0.063rem 0.063rem 0.063rem rgba(0, 0, 0 0.5);
  display: inline-block;
`;
