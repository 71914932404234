import { IconButton, SxProps, Theme, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

export type DeleteIconButtonProps = {
  entityId?: string;
  onDelete: (event: React.MouseEvent, entityId?: string) => void;
  sx?: SxProps<Theme>;
};

export const DeleteIconButton = ({
  onDelete,
  entityId,
  sx,
}: DeleteIconButtonProps) => {
  return (
    <Tooltip title="Delete">
      <IconButton
        aria-label="Delete"
        sx={sx}
        onClick={(event) => onDelete(event, entityId)}
        color="error"
      >
        <DeleteIcon tabIndex={0} fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
