import { Moment } from "moment-timezone";
import { Collapse, Grid, Typography, useTheme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ISeries } from "../../Interfaces";
import { StyledEventFilters } from "../ListEvents/EventListStyles";
import { SeriesFilter } from "./SeriesFilter";
import { MobileSeriesFilter } from "./MobileSeriesFilter";
import { DateRangePicker } from "../Shared/DateRangePicker";
import { DarkTheme, LightTheme } from "../App/Theme";

export type FilterProps = {
  series: ISeries[] | undefined;
  isMobile: boolean;
  isExpanded: boolean;
  screenWidth: number;
  selectedSeries: ISeries[] | undefined;
  onExpanded: (isExpanded: boolean) => void;
  onOpen: (isOpen: boolean) => void;
  onSeriesChange: (seriesId: string) => void;
  selectedDates: [Moment | null | undefined, Moment | null | undefined] | null;
  onDateChange: (date: Array<Moment | undefined> | null) => void;
};

export const Filters = ({
  series,
  selectedSeries,
  onOpen,
  onExpanded,
  onSeriesChange,
  selectedDates,
  onDateChange,
  isMobile,
  isExpanded,
  screenWidth,
}: FilterProps) => {
  const theme = useTheme();

  return (
    <Grid
      container
      minHeight={isMobile ? "unset" : "5rem"}
      bgcolor={
        isMobile
          ? theme.palette.mode === "dark"
            ? DarkTheme.bgColorCard
            : "#dfdfdf"
          : theme.palette.mode === "dark"
            ? DarkTheme.bgColor
            : LightTheme.bgColor
      }
      padding={isMobile ? "unset" : "0rem 0.75rem"}
      direction="row"
      zIndex={2}
    >
      <StyledEventFilters
        onClick={() => {
          if (isMobile && !isExpanded) {
            onExpanded(true);
            onOpen(true);
          }
        }}
        sx={{ cursor: isMobile && !isExpanded ? "pointer" : "unset" }}
      >
        <Collapse in={isMobile ? isExpanded : true} sx={{ width: "100%" }}>
          <Grid container>
            {!isMobile && (
              <Grid
                container
                sx={{
                  textAlign: "left",
                  marginTop: "0.75rem",
                }}
              >
                <Grid item xs={8.75}>
                  <Typography
                    variant="subtitle1"
                    color={
                      theme.palette.mode === "dark"
                        ? DarkTheme.fontColor
                        : LightTheme.fontColor
                    }
                    ml={0.5}
                  >
                    Select Series
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    color={
                      theme.palette.mode === "dark"
                        ? DarkTheme.fontColor
                        : LightTheme.fontColor
                    }
                  >
                    Select Date Range
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} md={8.5} sm={12}>
              {!isMobile ? (
                <SeriesFilter
                  series={series?.sort((a, b) => a.SortOrder - b.SortOrder)}
                  onSeriesChange={onSeriesChange}
                  selectedSeries={selectedSeries}
                />
              ) : (
                <MobileSeriesFilter
                  series={series?.sort((a, b) => a.SortOrder - b.SortOrder)}
                  screenWidth={screenWidth}
                  onSeriesChange={onSeriesChange}
                  selectedSeries={selectedSeries}
                />
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={3.5}
              display="flex"
              margin={isMobile ? "0.75rem 0rem 0.25rem 0rem" : "unset"}
            >
              <DateRangePicker
                selectedDates={selectedDates}
                onDateChange={onDateChange}
                isMobile={isMobile}
              />
            </Grid>
          </Grid>
        </Collapse>
        {isMobile && (
          <Grid
            xs={12}
            onClick={() => {
              onExpanded(false);
              onOpen(false);
            }}
            sx={{
              cursor: isExpanded ? "pointer" : "unset",
              borderBottom: `0.1rem solid ${
                theme.palette.mode === "dark"
                  ? DarkTheme.mobileDrawerBorderBottom
                  : LightTheme.mobileDrawerBorderBottom
              }`,
            }}
            item
          >
            <Typography
              color={
                theme.palette.mode === "dark"
                  ? DarkTheme.fontColor
                  : LightTheme.bgColorHeaderFooter
              }
              fontWeight="500"
            >
              Filters
              <ExpandMoreIcon
                sx={{
                  position: "relative",
                  top: "0.45rem",
                  rotate: !isExpanded ? "unset" : "180deg",
                }}
              />
            </Typography>
          </Grid>
        )}
      </StyledEventFilters>
    </Grid>
  );
};
