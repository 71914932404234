import {
  ApplicationInsights,
  ICustomProperties,
  IDependencyTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
  IMetricTelemetry,
  IPageViewPerformanceTelemetry,
  IPageViewTelemetry,
  ITraceTelemetry,
  Snippet,
} from "@microsoft/applicationinsights-web";

export let applicationInsights: ApplicationInsights;

export const getApplicationInsights = async (snippet: Snippet) => {
  applicationInsights = new ApplicationInsights(snippet);
  applicationInsights.loadAppInsights();

  return applicationInsights;
};

export const trackEvent = (
  event: IEventTelemetry,
  customProperties?: ICustomProperties | undefined,
) => {
  const properties = { data: JSON.stringify(customProperties) };
  applicationInsights.trackEvent(event, properties);
};

export const trackPageView = (pageView?: IPageViewTelemetry) => {
  applicationInsights.trackPageView(pageView);
};

export const trackPageViewPerformance = (
  pageViewPerformance: IPageViewPerformanceTelemetry,
) => {
  applicationInsights.trackPageViewPerformance(pageViewPerformance);
};

export const trackException = (
  exception: IExceptionTelemetry,
  customProperties?: ICustomProperties | undefined,
) => {
  const properties = { data: JSON.stringify(customProperties) };
  applicationInsights.trackException(exception, properties);
};

export const trackTrace = (
  trace: ITraceTelemetry,
  customProperties?: ICustomProperties | undefined,
) => {
  const properties = { data: JSON.stringify(customProperties) };
  applicationInsights.trackTrace(trace, properties);
};

export const trackMetric = (
  metric: IMetricTelemetry,
  customProperties?: ICustomProperties | undefined,
) => {
  const properties = { data: JSON.stringify(customProperties) };
  applicationInsights.trackMetric(metric, properties);
};

export const trackDependencyData = (dependency: IDependencyTelemetry) => {
  applicationInsights.trackDependencyData(dependency);
};

export const startTrackPage = (name?: string | undefined) => {
  applicationInsights.startTrackPage(name);
};

export const stopTrackPage = (
  name?: string | undefined,
  url?: string | undefined,
  customProperties?: ICustomProperties | undefined,
) => {
  const properties = { data: JSON.stringify(customProperties) };
  applicationInsights.stopTrackPage(name, url, properties);
};

export const startTrackEvent = (name?: string | undefined) => {
  applicationInsights.startTrackEvent(name);
};

export const stopTrackEvent = (
  name: string,
  customProperties?: ICustomProperties | undefined,
) => {
  const properties = { data: JSON.stringify(customProperties) };
  applicationInsights.stopTrackEvent(name, properties);
};

export const AppInsightsService = {
  getApplicationInsights,
  trackEvent,
  trackPageView,
  trackPageViewPerformance,
  trackException,
  trackTrace,
  trackMetric,
  trackDependencyData,
  startTrackPage,
  stopTrackPage,
  stopTrackEvent,
};
