import * as yup from "yup";
import { IEvent } from "../Interfaces";
type ValidationAction = "addorupdate" | "delete";

export const eventValidationSchema = (
  action: ValidationAction = "addorupdate",
) =>
  yup.object<IEvent>().shape({
    Name: yup.string().trim().required("Event Name is required"),
    ShortName: yup.string().trim().required("Short Name is required"),
    SeriesId: yup.string().trim().required("Series is required"),
    SiteId: yup.string().trim().required("Site is required"),
    TimingScoringId: yup
      .string()
      .trim()
      .required("Timing and scoring is required"),
    WeatherStationIds:
      action === "addorupdate"
        ? yup.string().trim().required("Weather station is required")
        : yup.string().trim(),
    Sessions: yup
      .array()
      .min(1, "Events must have at least 1 Session")
      .test("", "", function (value) {
        console.assert(value, "testingSessions");
        return true;
      }),
    AllowedRaceTeams: yup
      .string()
      .when(["EventType", "SeriesId"], (eventProps) => {
        return action === "addorupdate"
          ? yup
              .string()
              .trim()
              .required("Organization must have at least one team selected")
          : yup.string().nullable();
      }),
  });
