import { Theme } from "./Interfaces/Theme";

export const getInitialMode = () => {
  return window.matchMedia("(prefers-color-scheme: dark)").matches;
};

export const getEventLogoSrc = (
  theme: string,
  lightLogo: string,
  darkLogo: string,
) => {
  const eventLogoSrc =
    theme === Theme.Light ? lightLogo || darkLogo : darkLogo || lightLogo;
  return eventLogoSrc;
};

// This is to replace circular structures in JSON for logging.
export const circularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
