import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DelayIcon from "@mui/icons-material/NotificationImportant";
import CanceledIcon from "@mui/icons-material/DisabledByDefault";
import { EventStatus, IEventSession } from "../../Interfaces";
import { Grid, IconButton, Tooltip, useTheme } from "@mui/material";

export type DelayMenuProps = {
  selectedSession: IEventSession;
  onDelay: (sessionId: string) => void;
  onCanceled: (sessionId: string) => void;
};

export const DelayMenu = ({
  selectedSession,
  onDelay,
  onCanceled,
}: DelayMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const rowId = selectedSession.SessionId;
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Tooltip title="Delay">
          <IconButton
            aria-label="Delay"
            onClick={handleClick}
            color="info"
            sx={{ position: "relative", bottom: "0.05rem" }}
          >
            <DelayIcon tabIndex={0} />
          </IconButton>
        </Tooltip>
      </Grid>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            handleClose();
            onDelay(rowId);
          }}
          color={theme.palette.mode === "dark" ? "#fff" : "#333"}
        >
          <DelayIcon color="info" /> Delay
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            onCanceled(rowId);
          }}
          color={theme.palette.mode === "dark" ? "#fff" : "#333"}
        >
          <CanceledIcon color="error" />
          {selectedSession.Status === EventStatus.Canceled
            ? "Activate"
            : "Cancel"}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
