import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { IEvent, IEventSession, Page } from "../Interfaces";
import { FilterPreferences } from "../Interfaces/FilterPreferences";
import {
  APP_REFRESH_INTERVAL_IN_MS,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "../app.constants";
import { LoggerService } from "./LoggerService";
import { createAdminRequest, createLookupRequest } from "./ServiceUtils";
import { RemoveEndDateIfNotSpecified, sortSessions } from "./SessionService";

enum EventServiceUrl {
  getEventsForAdmins = "api/events",
  getEvents = "api/public/events",
  createEventV2 = "api/event/new",
  updateEventV2 = "api/event/",
  checkExistingShortNames = "api/event",
}

let forceRefresh = false;

export const sortEventsAscending = (e1: IEvent, e2: IEvent): number => {
  return sortBySessionStartTime(e1, e2);
};
export const sortEventsDescending = (e1: IEvent, e2: IEvent): number => {
  return sortBySessionStartTime(e1, e2) * -1;
};

const sortBySessionStartTime = (e1: IEvent, e2: IEvent): number => {
  let e1Sessions = e1.Sessions as IEventSession[];
  let e2Sessions = e2.Sessions as IEventSession[];
  let s1 = e1Sessions[0];
  let s2 = e2Sessions[0];

  if (typeof s2 === "undefined") return 1;
  if (typeof s1 === "undefined") return -1;

  return sortSessions(s1, s2);
};

const mapApiEventToEvent = (apiEvent: any) => {
  const mappedEvent: IEvent = {
    ...apiEvent,
  };
  mappedEvent.Sessions?.sort(sortSessions);
  return mappedEvent;
};

const buildEventPageRoute = (
  isAdmin: Boolean,
  pageNumber: Number = 1,
  pageSize: Number = 50,
  force: boolean,
  sortDirection: string = "ASC",
  includeLast12MonthsOnly: boolean = false,
  filterCriteria?: FilterPreferences,
): string => {
  const baseUrl = isAdmin
    ? EventServiceUrl.getEventsForAdmins
    : EventServiceUrl.getEvents;

  const requestUrlArguments = new URLSearchParams();
  requestUrlArguments.append("pageNumber", `${pageNumber}`);
  requestUrlArguments.append("pageSize", `${pageSize}`);
  requestUrlArguments.append("sortDirection", `${sortDirection}`);
  requestUrlArguments.append(
    "useCurrentCalendarYear",
    `${includeLast12MonthsOnly}`,
  );

  if (filterCriteria) {
    const startsAfter = filterCriteria.selectedStartDate;
    if (startsAfter) {
      const isoDate = startsAfter.toISOString();
      const dateSegment = isoDate.substring(0, isoDate.indexOf("T"));
      requestUrlArguments.append("startsAfterDate", dateSegment);
    }

    const endsBefore = filterCriteria.selectedEndDate;
    if (endsBefore) {
      const isoDate = endsBefore.toISOString();
      const dateSegment = isoDate.substring(0, isoDate.indexOf("T"));
      requestUrlArguments.append("endsBeforeDate", dateSegment);
    }

    const seriesIds: Array<string> = new Array<string>();
    filterCriteria.selectedSeries?.forEach((value) =>
      seriesIds.push(value.SeriesId),
    );
    requestUrlArguments.append("seriesIds", `${seriesIds.join(",")}`);
  }

  let requestUrl = `${baseUrl}?${requestUrlArguments.toString()}`;
  if (force) {
    requestUrl += `&${new Date().getTime()}`;
  }
  return requestUrl;
};

const getEventPages = async (
  isAdmin: boolean,
  pageNumber = DEFAULT_PAGE_NUMBER,
  pageSize = DEFAULT_PAGE_SIZE,
  force: boolean = false,
  includeLast12MonthsOnly?: boolean,
  filterCriteria?: FilterPreferences,
  sortDirection: string = "ASC",
): Promise<Page<IEvent>> => {
  const requestUrl = buildEventPageRoute(
    isAdmin,
    pageNumber,
    pageSize,
    force,
    sortDirection,
    includeLast12MonthsOnly,
    filterCriteria,
  );

  const requestOptions = isAdmin
    ? createAdminRequest("GET")
    : createLookupRequest("GET");

  const response = await fetch(requestUrl, requestOptions);
  if (!response.ok) {
    const error = `${
      response.status
    } error has occurred: ${await response.text()}`;

    LoggerService.logError("getEventPages()", error, { url: requestUrl });

    throw new Error(error);
  }

  const data = await response.json();
  const events: Array<IEvent> = data.Data.map((apiEvent: any) => {
    return mapApiEventToEvent(apiEvent);
  });

  return {
    HasNextPage: data.HasNextPage,
    Data: events,
    NextPage: data.NextPageIndex,
  };
};

const trimEventForCreate = async (eventData: IEvent): Promise<IEvent> => {
  const eventDataToChange = { ...eventData };
  eventDataToChange.Sessions = eventData.Sessions?.map((session) => {
    return RemoveEndDateIfNotSpecified(session);
  });
  delete eventDataToChange.Site;
  delete eventDataToChange.Series;
  delete eventDataToChange.WeatherStation;
  delete eventDataToChange.TimingScoring;
  return eventDataToChange;
};

const trimEventForUpdate = async (eventData: IEvent): Promise<IEvent> => {
  const eventDataToChange = await trimEventForCreate(eventData);
  delete eventDataToChange.EventId;
  return eventDataToChange;
};

const createEvent = async (eventData: IEvent): Promise<IEvent> => {
  LoggerService.logInfo("createEvent()", "eventData:", eventData);
  const newEvent: IEvent = await trimEventForCreate(eventData);
  const requestOptions = createAdminRequest("POST", newEvent);

  const response = await fetch(EventServiceUrl.createEventV2, requestOptions);
  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("createEvent()", error, {
      url: EventServiceUrl.createEventV2,
      ...requestOptions,
    });
    throw new Error(error);
  }

  const storedEvent = await response.json();
  let storedSessions: IEventSession[] = eventData.Sessions || [];

  return { ...storedEvent, Sessions: storedSessions };
};

const updateEvent = async (eventData: IEvent): Promise<IEvent> => {
  const updatedEvent: IEvent = await trimEventForUpdate(eventData);
  const requestOptions = createAdminRequest("PUT", updatedEvent);

  LoggerService.logInfo("updateEvent()", "updateBody:", requestOptions);
  const response = await fetch(
    `${EventServiceUrl.updateEventV2}/${eventData.EventId}`,
    requestOptions,
  );

  if (!response.ok) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("updateEvent()", error, {
      url: EventServiceUrl.updateEventV2,
      ...requestOptions,
    });
    throw new Error(error);
  }

  const storedEvent = await response.json();

  return { ...storedEvent, Sessions: storedEvent.Sessions };
};

const deleteEventById = async (eventId: string) => {
  const requestOptions = createAdminRequest("DELETE");
  const response = await fetch(
    `${EventServiceUrl.updateEventV2}/${eventId}`,
    requestOptions,
  );

  if (response.status !== 204) {
    const error = `${
      response.status
    } error has occured: ${await response.text()}`;

    LoggerService.logError("deleteEventById()", error, {
      url: EventServiceUrl.updateEventV2,
      ...requestOptions,
    });
    throw new Error(error);
  }
};

export const useInfiniteEvents = (
  isAdmin: boolean = false,
  shouldRefresh: boolean = false,
  isEventsViewEnabled: boolean = true,
  includeLast12MonthsOnly?: boolean,
  filterCriteria?: FilterPreferences,
  sortDirection: string = "ASC",
) => {
  return useInfiniteQuery(
    ["eventDataPage", isAdmin, includeLast12MonthsOnly, filterCriteria],
    ({ pageParam }) =>
      getEventPages(
        isAdmin,
        pageParam,
        DEFAULT_PAGE_SIZE,
        forceRefresh,
        includeLast12MonthsOnly,
        filterCriteria,
        sortDirection,
      ),
    {
      enabled: isEventsViewEnabled,
      ...(shouldRefresh && { refetchInterval: APP_REFRESH_INTERVAL_IN_MS }),
      getNextPageParam: (lastPage) => {
        return lastPage.HasNextPage ? lastPage.NextPage : undefined; //If this is undefined it knows to stop calling the page function
      },
    },
  );
};

export const useCreateEvent = () => {
  const queryClient = useQueryClient();

  return useMutation<IEvent, Error, IEvent>(createEvent, {
    onSuccess: () => {
      forceRefresh = true;
      queryClient.invalidateQueries("eventsPage");
      queryClient.invalidateQueries("eventDataPage");
    },
  });
};

export const useUpdateEvent = () => {
  const queryClient = useQueryClient();

  return useMutation<IEvent, Error, IEvent>(updateEvent, {
    onSuccess: () => {
      forceRefresh = true;
      queryClient.invalidateQueries("eventsPage");
      queryClient.invalidateQueries("eventDataPage");
    },
  });
};

export const useDeleteEventById = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error, IEvent>(
    (eventData: IEvent) => deleteEventById(eventData.EventId as string),
    {
      onSuccess: () => {
        forceRefresh = true;
        queryClient.invalidateQueries("eventsPage");
        queryClient.invalidateQueries("eventDataPage");
      },
    },
  );
};

export const getShortNameExistence = async (
  shortName: string,
  seriesId: string,
  eventId: string | undefined,
): Promise<boolean> => {
  LoggerService.logInfo(
    "getShortNameExistence()",
    "shortName:",
    shortName,
    "seriesId:",
    seriesId,
    "eventId:",
    eventId,
  );
  const baseUrl = EventServiceUrl.checkExistingShortNames;
  let requestUrl = `${baseUrl}?shortName=${shortName}&seriesId=${seriesId}&eventId=${eventId}`;
  const requestOptions = createAdminRequest("GET");

  const response = await fetch(requestUrl, requestOptions);
  if (!response.ok) {
    const error = `${
      response.status
    } error has occurred: ${await response.text()}`;

    LoggerService.logError("getShortNameExistence()", error, {
      url: requestUrl,
    });

    throw new Error(error);
  }

  const data = await response?.json();
  return data;
};
