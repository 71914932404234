import { Box, useTheme } from "@mui/material";
import { FilterPreferences } from "../../Interfaces/FilterPreferences";
import { useSeries, useSites } from "../../Services/LookupService";
import { useUserInfo } from "../../Services/UserInfoService";
import { DarkTheme, LightTheme } from "../App/Theme";
import { ListView } from "../ListView/ListView";

export type MainContentProps = {
  width: number;
  mobileBreakPoint: number;
  tabletBreakPoint: number;
  filterValues: FilterPreferences | undefined;
};

export const MainContent = ({
  width,
  mobileBreakPoint,
  tabletBreakPoint,
  filterValues,
}: MainContentProps) => {
  const theme = useTheme();
  const { data: userInfo } = useUserInfo();
  const { data: series } = useSeries();
  const { data: sites } = useSites();

  return (
    <>
      {series && sites && (
        <Box
          component="main"
          flexGrow="1"
          bgcolor={
            theme.palette.mode === "dark"
              ? DarkTheme.bgColor
              : LightTheme.bgColor
          }
        >
          <ListView
            isMobile={width < mobileBreakPoint}
            isTablet={width < tabletBreakPoint}
            userInfo={userInfo}
            series={series}
            sites={sites}
            filterValues={filterValues}
          />
        </Box>
      )}
    </>
  );
};
