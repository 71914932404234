import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import {
  StyledSelectLogo,
  StyledSelectMobileLogo,
  StyledSelect,
} from "../ListEvents/EventListStyles";
import { ISponsor } from "../../Interfaces/ISponsor";
import { MenuProps, getStyles } from "./MultiSelectStyles";

export type SponsorSelectProps = {
  sponsors: ISponsor[];
  eventSponsorIds: string[] | undefined;
  onSponsorChange: (sponsorNames: string[]) => void;
  isMobile: boolean;
};

export const SponsorSelect = ({
  sponsors,
  eventSponsorIds,
  onSponsorChange,
  isMobile,
}: SponsorSelectProps) => {
  const theme = useTheme();
  const [sponsorNames, setSponsorNames] = React.useState<string[]>(
    sponsors
      .filter((s) =>
        eventSponsorIds?.some(
          (eventSponsorId) => eventSponsorId === s.SponsorId,
        ),
      )
      .map((s) => s.Name),
  );

  const handleChange = (event: SelectChangeEvent<typeof sponsorNames>) => {
    const {
      target: { value },
    } = event;

    setSponsorNames(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );

    onSponsorChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <StyledSelect>
      <InputLabel id="sponsors-label">Sponsors</InputLabel>
      <Select
        labelId="sponsors-label"
        id="demo-multiple-chip"
        multiple
        fullWidth
        notched={false}
        value={sponsorNames}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
        renderValue={(selected) => (
          <Box display="flex" flexWrap="wrap" gap="0.5 ">
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {sponsors.map((sponsor) => (
          <MenuItem
            key={sponsor.SponsorId}
            value={sponsor.Name}
            style={getStyles(sponsor.Name, sponsorNames, theme)}
          >
            {!isMobile ? (
              <StyledSelectLogo
                className="sponsor"
                src={sponsor?.LogoBase64Encoded}
                alt={sponsor?.Name}
              />
            ) : (
              <StyledSelectMobileLogo
                className="sponsor"
                src={sponsor?.LogoBase64Encoded}
                alt={sponsor?.Name}
              />
            )}
            {sponsor.Name}
          </MenuItem>
        ))}
      </Select>
    </StyledSelect>
  );
};
