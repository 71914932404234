import { Typography, Grid, IconButton, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";

export type TitleBarProps = {
  onAddSession: () => void;
  disabled: boolean;
};

export const SessionTitleBar = ({ onAddSession, disabled }: TitleBarProps) => {
  return (
    <Grid container mb="0.1rem">
      <Grid item xs={11.07}>
        <Typography variant="h6">Session(s)*:</Typography>
      </Grid>
      <Grid item>
        <Tooltip
          title={disabled ? "Select a Site to add a Session." : "Add Session"}
        >
          <IconButton
            style={{
              // These are the MUI disabled and primary colors.
              cursor: disabled ? "not-allowed" : "default",
              color: disabled ? "rgba(0, 0, 0, 0.54)" : "#0288d1",
            }}
            onClick={!disabled ? onAddSession : undefined}
            aria-label="Add Session"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
